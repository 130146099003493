import { IStateConfig } from "../interfaces/IStateConfig";
import { GlobalExceptionHandler } from "../telemetry/GlobalExceptionHandler";
import { IFxpAppContext } from "../interfaces/IFxpAppContext";
import { CommonUtils } from "../utils/CommonUtils";
import { IAuthExceptionServiceEndPoints, IServiceEndPoints } from "../interfaces/IServiceEndPoints";
import { IRouteInfo } from "../interfaces/IRouteInfo";
import { ILazyloadBundle } from "../interfaces/ILazyLoadBundle";
import { IPartnerBundle } from "../interfaces/IPartnerBundle";
import { AddRouteConfiguration, UpdateRouteConfiguration } from "@fxp/staterouter";
import { GlobalStore } from 'redux-micro-frontend';
import { FxpLazyLoader } from "../../app/fxplazyloader";
import { ISharedComponents } from "../interfaces/ISharedComponents";
import { ISharedComponentConfig } from "../interfaces/ISharedComponentConfig";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { ErrorCodes } from "../constants/errorCodes";
import { FxpLoggerService } from "../telemetry/fxpLogger";
import { ComponentFramework } from "../common/enum/ComponentFramework";
import { ISharedComponentReference } from "../interfaces/ISharedComponentReference";
import { IStateInfo } from "@fxp/staterouter/src/common/interfaces/IStateInfo";


export class PartnerAppRegistrationService {
    private static registeredApps: any = {};
    private static registerPartnerEndpoints = {};
    private static registeredRoutes: Array<IStateConfig> = [];
    public static angularPartnerStateConfig: Array<IRouteInfo> = [];
    public static partnerRegisteredEndpoints = {};
    public static authExceptionEndpoints = [];

    private static lazyLoader = null;
    public static angularPartnerSharedComponents: Array<ISharedComponents> = [];
    private static applicationsWithStore: Array<string> = [];
    private static get FxpLoggerService(): FxpLoggerService {
        return FxpLoggerService.getInstance();
    }
    private static sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PartnerAppRegistrationService`;

    public static setLazyLoader(lazyLoaderInstance){
        if (!this.lazyLoader){
            this.lazyLoader = lazyLoaderInstance;
        }
    }


    public static registerEndpoints(endpoint: string, clientId: string) {
        console.warn("[Obsolete] - PartnerAppRegistrationService.registerEndpoints() has been marked as obsolete and will be removed in future. Please start using getServiceEndPoints() method of IAppService.");
        if (!this.registerPartnerEndpoints[endpoint]) {
            this.registerPartnerEndpoints[endpoint] = clientId;
        }
    }

    public static getRegisterEndpoints() {
        const partnerRegisteredEndpoints = this.getRegisteredEndPointsFromPartnerAppRegistrationClass();
        return CommonUtils.jsonConcat(partnerRegisteredEndpoints, this.registerPartnerEndpoints);
    }

    public static addAuthenticationExceptionEndpoints() {
        const partnerExceptionEndpoints = this.getAuthExcpetionFromPartnerAppRegistrationClass();
        return partnerExceptionEndpoints;
    }

    public static addApplicationWithStores(appName: string): void{
      if(this.applicationsWithStore.indexOf(appName) >= 0){
        return;
      }
      this.applicationsWithStore.push(appName);
    }

    public static isStoreRegistered(appName: string): boolean{
      return this.applicationsWithStore.indexOf(appName) >= 0;
    }

    public static registerPartnerApp(appName: string, appInstance): void {
        let properties = this.FxpLoggerService.createPropertyBag();
        properties.addToBag('PartnerAppName', appName );
        if (!this.registeredApps[appName]) {
            this.registeredApps[appName] = appInstance;
            //TO-DO (Discussion needed) - Where do we need to configure the routes with proper action?
            const routeInfo = (new appInstance()).getRoutes();
            const addRouteConfigurationAction = AddRouteConfiguration(routeInfo);
            GlobalStore.Get().DispatchGlobalAction("Fxp.PartnerAppRegistrationService", addRouteConfigurationAction);
            this.registerPartnerAppRoutes(routeInfo);
        }
        else {
            const error = new Error();
            error.message = 'Application name with ' + appName + ' is already registered.';
            console.error(error.message);
            this.FxpLoggerService.logException(`${this.sourceForTelemetry}.RegisterPartnerApp`, error, properties, null, null, ErrorSeverityLevel.High, ErrorCodes.PartnerAppRegistrationService_ErrorOnAppRegistration);
        }
    }

    public static updateStateObject(stateName: string, ngModule: any, component: any, componentFramework?: ComponentFramework){
        const $stateService:any = angular.element(document.body).injector().get("$state");
        const $state = $stateService.get(stateName);
        if($state != null) {
            $state.ngModule = ngModule;
            $state.childComponent = component;
            $state.componentFramework = componentFramework

            if ($state.componentFramework == ComponentFramework.React && $state.data.partnerAppName){
              const stateInfo: IStateInfo = {
                stateName: stateName,
                component: component,
                applicationName: $state.data.partnerAppName
              }
              const updateRouteConfiguration = UpdateRouteConfiguration(stateInfo);

              GlobalStore.Get().DispatchGlobalAction("Fxp.PartnerAppRegistrationService", updateRouteConfiguration);

            }
        }
    }

    public static getRegisteredRoutes(fxpAppContext: IFxpAppContext): Array<IStateConfig> {
        Object.keys(this.registeredApps).forEach((key) => {
            const appInstance = new this.registeredApps[key]();
            const routesInfo = appInstance.getRoutes(fxpAppContext);
            this.registeredRoutes.push.apply(this.registeredRoutes, routesInfo);
        });
        return this.registeredRoutes;
    }

  public static getRegisteredAppRoute(appName: string, stateName: string): IStateConfig {
    let routeInfo;
    if (this.registeredApps.hasOwnProperty(appName)) {
      const appInstance = new this.registeredApps[appName]();
      const routeCollection = appInstance.getRoutes().routes;
      routeInfo = routeCollection.find(route => { return route.name === stateName });
    }
    return routeInfo;
  }

    public static registerLazyLoadedApp(registryInstance: any, appName?: string) {
        const registry = new registryInstance();
        const routeInfo = this.updateAppName(registry.getRoutes(), appName);
        let bundles: Array<IPartnerBundle> = [];
        if (registry.getBundles){
            bundles = [...bundles, ...registry.getBundles()];
        }
        if (registry.getGeneratedBundles){
            bundles = [...bundles, ...registry.getGeneratedBundles()];
        }
        this.registerPartnerAppBundles(bundles);
        this.registerPartnerAppRoutes(routeInfo);
        this.registerPartnerAppEndpoints(registry.getServiceEndPoints(), routeInfo.applicationName);
        if(registry.getAuthExceptionEndpoints)
            this.registerAuthExceptionEndpoints(registry.getAuthExceptionEndpoints(), routeInfo.applicationName);
        if (registry.getSharedComponentsInfo) {
            this.registerAngularSharedComponents(this.updateAppName(registry.getSharedComponentsInfo(), appName));
        }
    }

    private static updateAppName(configObject: any, appName?: string){
        if(appName && appName.length>0){
            configObject.appName = appName
        }
        return configObject;
    }

    private static registerAngularSharedComponents(sharedComponentsInfo: ISharedComponents) {
        if (sharedComponentsInfo.appName && !sharedComponentsInfo.disableSharing) {
            this.angularPartnerSharedComponents.push(sharedComponentsInfo);
        }
        else if(!sharedComponentsInfo.appName)
        {
            const error = new Error();
            error.message = 'Application name is not provided for the shared components';
            console.error(error.message);
            let props = this.FxpLoggerService.createPropertyBag();
            props.addToBag("Details", error.message);

            this.FxpLoggerService.logException(`${this.sourceForTelemetry}.RegisterAngularSharedComponents`,
                                        error,
                                        props,
                                        null,
                                        null,
                                        ErrorSeverityLevel.High,
                                        ErrorCodes.PartnerAppRegistrationService_ErrorOnSharedComponentRegistration);
        }
    }

    private static registerPartnerAppRoutes(config: IRouteInfo): void {
        this.angularPartnerStateConfig.push(config);
    }

    private static registerPartnerAppBundles(bundles: Array<IPartnerBundle>): void {
        const ocBundles = Array<ILazyloadBundle>();
        bundles.forEach(bundle => {
            const ocBundle: ILazyloadBundle = {
                name: bundle.name,
                files: bundle.files,
                serie: bundle.sequentialLoading
            }
            ocBundles.push(ocBundle);
        });

        window["OcModules"] = [...window["OcModules"], ...ocBundles];
    }

    private static registerPartnerAppEndpoints(endpoints: Array<IServiceEndPoints>, key: string) {
        endpoints.forEach(endpoint => {
            if (!endpoint.serviceEndPoint) {
                console.error("[ValidationError-" + key + "] - Endpoint/Url is missing in the data provided in getServiceEndPoints() method.");
                return;
            }
            if (!endpoint.clientId) {
                console.error("[ValidationError-" + key + "] - ClientId is missing in the data provided in getServiceEndPoints() method.");
                return;
            }
            if (!this.partnerRegisteredEndpoints[endpoint.serviceEndPoint]) {
                this.partnerRegisteredEndpoints[endpoint.serviceEndPoint] = endpoint.clientId;
            }
        });
    }

    private static registerAuthExceptionEndpoints(endpoints: Array<IAuthExceptionServiceEndPoints>, key: string) {
        endpoints.forEach(endpoint => {
            if (!endpoint.serviceEndPoint) {
                console.error("[ValidationError-" + key + "] - Endpoint/Url is missing in the data provided in getAuthException() method.");
                return;
            }
            
            if (!this.authExceptionEndpoints[endpoint.serviceEndPoint]) {
                this.authExceptionEndpoints.push(endpoint.serviceEndPoint);
            }
        });
    }

    private static getRegisteredEndPointsFromPartnerAppRegistrationClass() {
        Object.keys(this.registeredApps).forEach((key) => {
            const appInstance = new this.registeredApps[key]();
            if (!appInstance || !appInstance.getServiceEndPoints) {
                return;
            }
            const endpoints = appInstance.getServiceEndPoints();
            if (!endpoints || endpoints.length === 0) {
                return;
            }
            this.registerPartnerAppEndpoints(endpoints, key);
        });

        return this.partnerRegisteredEndpoints;
    }

    private static getAuthExcpetionFromPartnerAppRegistrationClass() {
        Object.keys(this.registeredApps).forEach((key) => {
            const appInstance = new this.registeredApps[key]();
            if (!appInstance || !appInstance.getAuthExceptionEndpoints) {
                return;
            }
            
            const endpoints = appInstance.getAuthExceptionEndpoints();
            if (!endpoints || endpoints.length === 0) {
                return;
            }
            this.registerAuthExceptionEndpoints(endpoints, key);
        });

        return this.authExceptionEndpoints;
    }

    public static updateSharedComponentDetails(appName: string, compName: string, component: any, ngModule: any, componentFramework?: ComponentFramework){
        this.angularPartnerSharedComponents.forEach(item => {
            if (item.appName === appName){
                const sharedComponentDetails = item;
                if (sharedComponentDetails){
                    sharedComponentDetails.components.forEach(item=> {
                        if (item.componentName.toString().toLowerCase().trim() === compName.toString().toLowerCase().trim()){
                            item.component = component;
                            item.ngModule = ngModule;
                            item.componentFramework = componentFramework;
                        }
                    })
                }
            }
        });
    }

    public static getSharedComponentDetails(appName: string, compName: string): ISharedComponentConfig {
        const partnerApp: ISharedComponents = this.angularPartnerSharedComponents.find((partnerApp) => (partnerApp.appName.toLocaleLowerCase() === appName.toLocaleLowerCase()));
        if (partnerApp){
            const sharedComponents =  partnerApp.components.find((comp) => (comp.componentName.toLocaleLowerCase() === compName.toLocaleLowerCase()));
            if (sharedComponents){
                return sharedComponents;
            }
        }
    }

    public static getBundlesForSharedComponent(appName: string, compName:string): Array<string> {
        let bundles = [ ];
        const partnerApp:ISharedComponents = this.angularPartnerSharedComponents.find((partnerApp) => (partnerApp.appName.toLocaleLowerCase() === appName.toLocaleLowerCase()));
        if (partnerApp){
            if (partnerApp.sharedBundles){
                bundles = partnerApp.sharedBundles;
            }
            const sharedComponents =  partnerApp.components.find((comp) => (comp.componentName.toLocaleLowerCase() === compName.toLocaleLowerCase()));
            if (sharedComponents && !CommonUtils.isNullOrEmpty(sharedComponents.generatedBundle)){
                bundles.push(sharedComponents.generatedBundle);
            }
        }
        return bundles;
    }

    public static getSharedComponentReference(appName: string, compName: string): Promise<ISharedComponentReference> {
      const self = this;
      const telemetry_source = `${this.sourceForTelemetry}.GetSharedComponentFactory`;
      let properties = self.FxpLoggerService.createPropertyBag();
      properties.addToBag("AppName", appName);
      properties.addToBag("ComponentName", compName);
      return new Promise(function (resolve, reject) {
          //let partnerApp:ISharedComponents = self.angularPartnerSharedComponents.find((partnerApp) => (partnerApp.appName.toLocaleLowerCase() === appName.toLocaleLowerCase()));
          let sharedComponentConfig = self.getSharedComponentDetails(appName, compName);
          if (sharedComponentConfig) {
              let bundles = self.getBundlesForSharedComponent(appName, compName);
              self.lazyLoader.load(bundles, { serie: true }).then(() => {
                  let component, componentFramework, ngModule = null ;
                  let sharedComponentReference: ISharedComponentReference;
                  sharedComponentConfig = self.getSharedComponentDetails(appName, compName);
                  if (sharedComponentConfig.component){ // Non lazyloaded angular bundles.
                      component = sharedComponentConfig.component;
                      ngModule = sharedComponentConfig.ngModule;
                      componentFramework = sharedComponentConfig.componentFramework;
                  }
                  else {
                      const application = self.registeredApps[appName];
                      const instance = new application();
                      const componentInfo = instance.getSharedComponentsInfo();
                      const sharedComponent = componentInfo.components.find(item=>  item.componentName == compName);
                      if (sharedComponent){
                          component = sharedComponent.component;
                          ngModule = sharedComponent.ngModule;
                          componentFramework = sharedComponent.componentFramework;
                      }
                  }
                  // This is Angular block
                  if (ngModule){
                    if (ngModule._ngModuleDefFactory){
                      const moduleRef = FxpLazyLoader.instantiateModule(ngModule);
                      const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(component);
                      sharedComponentReference = {component: factory, componentFramework: ComponentFramework.Angular, ngModule: moduleRef};
                      resolve(sharedComponentReference);
                      return ;
                    }
                    FxpLazyLoader.compileAndLoadModule(ngModule).then(function (ngModuleRef) {
                      const factory = ngModuleRef.componentFactoryResolver.resolveComponentFactory(component);
                      sharedComponentReference = {component: factory, componentFramework: ComponentFramework.Angular, ngModule: ngModuleRef};
                        resolve(factory);
                    }).catch((error) => {
                        reject(error);
                    });
                    return;

                  }
                  // React
                  if (componentFramework && componentFramework === ComponentFramework.React) {
                    sharedComponentReference = {component: component, componentFramework: ComponentFramework.React, ngModule: null};
                    resolve(sharedComponentReference);
                    return;
                  }

                  const errorMessage = `Unable to identify the JS Framwork for Component: ${compName} of Application: ${appName}. Please specify componentFramework in getSharedComponentsInfo() of application.ts file.`;
                  console.error(errorMessage);
                  self.FxpLoggerService.logException(telemetry_source,
                    new Error(errorMessage),
                    properties,
                    null,
                    null,
                    ErrorSeverityLevel.High,
                    ErrorCodes.LazyLoadingFailedForSharedComponent);
              })
              .catch(function (e) {
                  self.FxpLoggerService.logException(telemetry_source,
                                              e,
                                              properties,
                                              null,
                                              null,
                                              ErrorSeverityLevel.High,
                                              ErrorCodes.LazyLoadingFailedForSharedComponent);
                  reject(e);
              });

          }
          else {
              let error = `${appName} app shared components not registered.`;
              reject(error);
              self.FxpLoggerService.logError(telemetry_source,
                                      error,
                                      ErrorCodes.SharedComponentNotRegistered,
                                      "",
                                      properties,
                                      null,
                                      null,
                                      ErrorSeverityLevel.High);
          }
      });
    }

    public static getSharedComponentFactory(appName: string, compName: string): Promise<any> {
      return this.getSharedComponentReference(appName, compName).then(function(componentRef: ISharedComponentReference){
        return componentRef.component;
      });
    }
}
