import { NgModule } from '@angular/core';
import { ReactPartnerContainerComponent } from '../components/reactContainer-component/reactpartner-component';





@NgModule({
    declarations: [ReactPartnerContainerComponent],
    bootstrap: [
        ReactPartnerContainerComponent
    ],
    entryComponents: [ReactPartnerContainerComponent],
    exports: [ReactPartnerContainerComponent]
})
export class ReactAppModule {
    constructor() {
        
    }
}
