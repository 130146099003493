export enum IOCVSource {
    Client = "Client",
  }
   
  export enum IOCVFeedbackType {
    Smile = "Smile",
    Frown = "Frown",
    Idea = "Idea",
    Survey = "Survey",
    Bug = "Bug",
  }
   
  export enum IOCVManifestType {
    Sas = "Sas",
    Nps = "Nps",
    Nlqs = "Nlqs",
    Psat = "Psat",
    Bps = "Bps",
    Fps = "Fps",
  }