import { showSystemDownOverlay } from "../utils/systemDownOverlay";
import { TelemetryConstants } from "./TelemetryConst";
import { CommonUtils } from "../utils/CommonUtils";
import { LogPropertyBag } from "./LogPropertyBag";
import { FxpLoggerService } from "./fxpLogger";

export class GlobalExceptionHandler {

	
	private static telemetrySource = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GlobalExceptionHandler`;
	
	private static get FxPLoggerService(): FxpLoggerService {
		return FxpLoggerService.getInstance();
	}

	
	
	/**
   * @method Fxp.GlobalExceptionHandler.logFxpBootFailure to log Global Event when Fxp App Boot Fails and redirect to System down page.
   * @param {any} properties - extra details which needs to be logged.
   * @param {string} source - source from where it is triggered.
   * @param {boolean} isDefaultPropertiesAvailable flag to check if default parameters are passed or not
   * @param {boolean} headerText for system down page.
   * @param {boolean} descriptionText for system down page.
   */
	static logFxpBootFailure(properties: LogPropertyBag, source: string, headerText: string, descriptionText: string, pageTitle: string, isCSABlockedUser: boolean = false) {
		const eventName = `${TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP}.FxPBootAppFailure`;
		const sourceName = CommonUtils.isNullOrEmpty(source)? this.telemetrySource: source; 
		
		if (!properties) {
			properties = this.FxPLoggerService.createPropertyBag();
		}
		if (!isCSABlockedUser){
			properties.addToBag('Reason', 'Failed to boot FxP App');
		}
		else {
			properties.addToBag('Reason', 'CSA Blocked Profile');
		}
		this.FxPLoggerService.logEvent(sourceName, eventName, properties); 
		showSystemDownOverlay(headerText, descriptionText, pageTitle, undefined, undefined, !isCSABlockedUser);
	}
}
