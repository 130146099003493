import { NgModule } from '@angular/core';
import { PowerBIReportContainerComponent } from '../components/powerbi-component/powerbi-container-component';

@NgModule({
    declarations: [PowerBIReportContainerComponent],
    entryComponents: [PowerBIReportContainerComponent],
    exports: [PowerBIReportContainerComponent],
    bootstrap: [
        PowerBIReportContainerComponent
    ],
})
export class PowerBiReportContainerModule {
    
}
