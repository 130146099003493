<div class="btn-group pull-left notificationflyout dropdown">
    <button
        type="button"
        class="icon-container withBorder"
        title="Page Tours"
        aria-label="Page Tours"
        (click)="togglePageTour();">
        <i class="icon-pageTour"></i>
        <div class="{{showCountClass}}">
            <sup class="notification-count ng-binding ng-scope">{{unViewedTourCount}}</sup>
        </div>
    </button>
</div>
<template #container >
</template>