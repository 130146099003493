import { IAction } from 'redux-micro-frontend';
import { LoaderActionTypes } from "./loader.actions.types";
import { ILoader } from "./loader.model";


export const ShowLoader = (payload: ILoader): IAction<ILoader> => {
    if(!payload.showLoader) payload.showLoader = true;
	if(payload.timeoutCallback) payload.timeoutCallback = payload.timeoutCallback.bind(payload);
    return {
        type: LoaderActionTypes.SHOW_LOADER,
        payload: payload
    }
}

export const HideLoader = (payload: Object): IAction<Object> => {
    return {
        type: LoaderActionTypes.HIDE_LOADER,
        payload: payload
    }
}

export const ShowSpecificLoader = (payload: ILoader) : IAction<ILoader> => {
    if(!payload.name) payload.name = 'default-fxp-loader';
    if(!payload.showLoader) payload.showLoader = true;
    return {
        type: LoaderActionTypes.SHOW_SPECIFIC_LOADER,
        payload: payload
    }
}

export const HideSpecificLoader = (payload: Object): IAction<Object> => {
    return {
        type: LoaderActionTypes.HIDE_SPECIFIC_LOADER,
        payload: payload
    }
}

