import * as core from '@angular/core';
import * as common from '@angular/common';
import * as forms from '@angular/forms';
import * as animations from "@angular/animations";
import * as router from '@uirouter/angular';
import * as rx from 'rxjs';
import * as plat from '@angular/platform-browser';
import * as dny from '@angular/platform-browser-dynamic';
import * as upgrade from '@angular/upgrade';
import * as ngbootstrap from '@ng-bootstrap/ng-bootstrap';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule, downgradeComponent } from '@angular/upgrade/static';

import { UrlService } from '@uirouter/core';
import '@uirouter/angularjs/release/stateEvents';
import { AppModule } from './app/modules/app.module';
import { CommonUtils } from './js/utils/CommonUtils';
import { GlobalExceptionHandler } from './js/telemetry/GlobalExceptionHandler';
import { enableProdMode, Compiler, NgZone } from '@angular/core';
import { FxpLazyLoader } from './app/fxplazyloader';
import { Resiliency } from './js/resiliency/FxpResiliency';
import { getUIRouter } from '@uirouter/angular-hybrid'
import { TelemetryConstants } from './js/telemetry/TelemetryConst';
import { SystemEvent } from './js/telemetry/SystemEvent';
import { ComponentType } from "@microsoftit/telemetry-extensions-npm";
import { FxpConstants } from './js/common/ApplicationConstants';
import { ErrorSeverityLevel } from './js/telemetry/ErrorSeverityLevel';
import { FxpLoggerService } from './js/telemetry/fxpLogger';
import { ErrorCodes } from './js/constants/errorCodes';

//Exposing _showPageDirtyPopup in browser console.
declare global {
	interface Window { _showPageDirtyPopup: boolean; }
}
declare const angular: any;
const fxpLoggerService: FxpLoggerService = FxpLoggerService.getInstance(); 

const fxpModule = angular.module('FxPApp');
fxpModule.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

const dependentModules = Resiliency.getResilientModules(fxpModule.requires);
// TODO: This needs to go to configuration.
dependentModules.push('ui.router.upgrade');
dependentModules.push('ui.router.state.events');
declare const FxpBaseConfiguration: any;

fxpModule.requires = dependentModules;

const source = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Main` ;

if (!CommonUtils.getQueryString("debug")) {
    enableProdMode();
    console.log('Disabling console.log in PROD mode'); 
    console.log = function(){}
}

try {

    
    const customProperties = fxpLoggerService.createPropertyBag();
        
    fxpLoggerService.logSystemEvent(source, 
                                    new SystemEvent(TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + ".ModuleBootstrapBegin", 
                                                            ComponentType.Web, "Bootstrapping angular modules"),
                                    customProperties);

    const preBootstrapTimeStamp = performance.now();
    platformBrowserDynamic().bootstrapModule(AppModule).then(platformReference => {
        fxpLoggerService.logSystemEvent(source, 
                                        new SystemEvent(TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP  + ".ModuleBootstrapEnd", 
                                                        ComponentType.Web, 
                                                        "Bootstrapping angular modules completed"), 
                                        customProperties); 
        
        customProperties.addToBag('SystemMessage', 'Time taken to Bootstrap FxP @angular and angularjs modules .');
        const duration: any = performance.now() - preBootstrapTimeStamp;
        fxpLoggerService.logMetric(source, FxpConstants.metricConstants.ModuleBootstrapping, duration, customProperties); 

        const upgradeModule = platformReference.injector.get(UpgradeModule);   
        upgradeModule.bootstrap(document.documentElement, ['FxPApp']);
        const url: UrlService = getUIRouter(platformReference.injector).urlService; //platformReference.injector.get(UrlService);
        function startUIRouter() {
            url.listen();
            url.sync();
        }
        const ngZone: NgZone = platformReference.injector.get(NgZone);
        ngZone.run(startUIRouter);
        FxpLazyLoader.setRootModuleReference(platformReference);
    });
} catch (err) {
    const error = new Error();
    let systemDownHeader, systemDownDescription, systemDownPageTitle;
    error.message = 'FxP App Boot Failure';
    if (FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.JavaScriptException) {
        systemDownHeader = FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.JavaScriptException.ErrorMessageTitle;
        systemDownDescription = FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.JavaScriptException.ErrorMessage;
        systemDownPageTitle = CommonUtils.getTenantURLSpecificPageTitle(FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.JavaScriptException.PageTitle);
    } else {
        console.error("Failed to load JavaScriptException error string from UIMessages");
    }
    // More specific error logging.
    console.error("FxP App Boot Failure: ", err.message);
    
    const errorProperites = fxpLoggerService.createPropertyBag();
    errorProperites.addToBag("Message", err.message);
    errorProperites.addToBag('ErrorCode', ErrorCodes.ModuleBoostrapFailure)

    fxpLoggerService.logException(source, err, errorProperites, undefined, undefined, ErrorSeverityLevel.Critical); 
    GlobalExceptionHandler.logFxpBootFailure(errorProperites, source,  systemDownHeader, systemDownDescription, systemDownPageTitle);
}
