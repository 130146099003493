import { IAction } from 'redux-micro-frontend';
import { SystemMessageActions } from "./systemMessage.action.types";
import { ISystemMessage } from "./systemMessage.model";

export const UpsertSystemMessage = (payload: Array<ISystemMessage>): IAction<Array<ISystemMessage>> => {
    return { 
        type: SystemMessageActions.UPSERT_SYSTEM_MESSAGE,
        payload: payload
    }    
}

export const RemoveSystemMessage = (payload: string): IAction<string> => {
    return {
        type: SystemMessageActions.REMOVE_SYSTEM_MESSAGE,
        payload: payload
    }
}