import { NgModule } from '@angular/core';
import { FxpBotLauncherComponent } from "./fxpbot.component";

@NgModule ({
    declarations: [FxpBotLauncherComponent],
    entryComponents: [FxpBotLauncherComponent],
    providers: []
})



export class FxpBotModule {}
