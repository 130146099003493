import { IRootScope } from "../interfaces/IRootScope";
import { ILogger } from "../interfaces/ILogger";
import { UserClaimsService } from "./UserClaimsService";
import { UserInfoService } from "./UserInfoService";
import { FxpConfigurationService } from "./FxpConfiguration";
import { StateService } from "@uirouter/core"
import { PartnerAppRegistrationService } from "./PartnerAppRegistrationService";
import { IUserPermission, IUserRole, IUserResourcePermissions, IResourcePermission } from "../interfaces/IUserPermission";
import { IStateConfig } from "../interfaces/IStateConfig";
import { IStateAuthorizationRule } from "../interfaces/IStateAuthorizationRule";
import { IFxPService } from "../interfaces/IFxpService";

/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */
/**
   * A service to check Authorization Rules
   * @class Fxp.Services.FxpAuthorizationService 
   * @classdesc A service to check Authorization Rules
   * @example <caption> Example to create an instance of Fxp Authorization Service</caption>
   *  //Initializing Fxp Route Service
   *  angular.module('FxPApp').controller('AppController', ['FxpAuthorizationService ', AppController]);
   */
export class FxpAuthorizationService implements IFxPService {
	private states;
	private stateGo: StateService;
	private rootScope: IRootScope;
	private fxplogger: ILogger;
	private userClaimsService: UserClaimsService;
	private userInfoService: UserInfoService;
	private fxpConfigurationService: FxpConfigurationService;

	constructor($state: StateService,
		$rootScope: IRootScope,
		loggerService: ILogger,
		userClaimsService: UserClaimsService,
		userInfoService: UserInfoService,
		fxpConfigurationService: FxpConfigurationService
	) {
		this.states = $state.get();
		this.stateGo = $state;
		this.rootScope = $rootScope;
		this.userClaimsService = userClaimsService;
		this.userInfoService = userInfoService;
		this.fxplogger = loggerService;
		this.fxpConfigurationService = fxpConfigurationService;

	}

	private isNullOrEmpty(object: any) {
		return (typeof object === 'undefined' || object === null || object.length < 1)
	}

	public checkStatePermission(event, state: IStateConfig): void {
		const self = this;
		if (self.isStateAuthorized(state) === false)
			self.redirectToUnauthorizedState(event, state.name);
	}

	public redirectToUnauthorizedState(event:any=null, currentState:string=""): void {
		const self = this;
		const propbag = self.fxplogger.createPropertyBag();
		if (currentState.trim().length === 0){
			currentState = self.states.current.name;
		}
		propbag.addToBag("currentUser", self.userInfoService.getCurrentUser());
		propbag.addToBag("currentState", currentState);
		var telemetrymsg = self.rootScope.fxpUIConstants.UIStrings.UnauthorizedUIString + currentState;
		self.fxplogger.logWarning("FxpAuthorizationService", telemetrymsg, propbag);
		if (event){
			event.preventDefault();
		}
		this.stateGo.go("unauthorized");
	}	

	/*check if given state for a given app is authorized*/
	public isAuthorized(stateName: string, appName?: string): boolean 
	{
		const self = this;
		let stateConfig = { name: stateName } as IStateConfig;

		if(!self.isNullOrEmpty(appName))
		{
			const appConfig = PartnerAppRegistrationService.angularPartnerStateConfig.find(s => s.applicationName && s.applicationName.toLowerCase() === appName.toLowerCase());
			if(!self.isNullOrEmpty(appConfig)) //if it is a registered partner app with its routes registered in Fxp
			{
				stateConfig = appConfig.routes.find(r => r.name.toLowerCase() === stateName.toLowerCase());			
			}
		}		

		return self.isStateAuthorized(stateConfig);
	}

	/*check if user is admin*/
	public isAppAdmin(appName: string): boolean
	{
		if(!appName)
		{
			return false;
		}
		const self = this;
		const adminPermissionsRules = self.getAppAdminRules(appName);

		if (self.isNullOrEmpty(adminPermissionsRules)) return false; //if no auth rules defined, the user is not an admin by default

		return self.hasUserPermissions(adminPermissionsRules);
	}

	/*check if user has the required role*/
	public hasUserRoles(userRoles: IUserRole) : boolean
	{	
		const self = this;
		let hasRoles = false;
		if(userRoles)
		{
			const allRolesMandatory = userRoles.AllRolesMandatory;
			const roles = userRoles.Value;
			
			//Role based authorization
			if(!self.isNullOrEmpty(roles)){
				const claimsList = self.getClaimsList(userRoles.Value, null);
				if (allRolesMandatory) {
					hasRoles = roles.every(r => self.checkRoleFunc(r, claimsList));
				}
				else {
					hasRoles = roles.some(r => self.checkRoleFunc(r, claimsList));
				}
			}
		}
		return hasRoles;
	}

	/*check if user has the required resource permission*/
	public hasResourcePermissions(userResourcePermissions: IUserResourcePermissions) : boolean
	{
		const self = this;
		let areResourcesAuthorized = false;
		if(userResourcePermissions)
		{
			const allResourcePermissionsMandatory = userResourcePermissions.AllResourcesMandatory;
			const resourcePermissions = userResourcePermissions.Value;
			
			// Resource Permission based authorization
			if (!self.isNullOrEmpty(resourcePermissions)){
				const tenantClaims = self.getClaimsList(null, userResourcePermissions.Value);
				if (allResourcePermissionsMandatory) {
					areResourcesAuthorized = resourcePermissions.every(p => self.checkResourcePermissionFunc(p, tenantClaims));
				}
				
				else {
					areResourcesAuthorized = resourcePermissions.some(p => self.checkResourcePermissionFunc(p, tenantClaims));
				}
			}
		}

		return areResourcesAuthorized;
	}	

	/*check if user has the required role/resource permissions*/
	public hasUserPermissions(allowedPermissions: IUserPermission) : boolean
	{
		const self = this;
		// if both roles and resource permissions are required
		const authorizeRolesAndResources = allowedPermissions.AuthorizeRolesAndResources;	
		
		let userRoles: IUserRole
		if(allowedPermissions.Roles && !self.isNullOrEmpty(allowedPermissions.Roles.Value)) 
		{
			userRoles = allowedPermissions.Roles;
		}
		
		let userResourcePermissions: IUserResourcePermissions		
		if(allowedPermissions.ResourcePermissions && !self.isNullOrEmpty(allowedPermissions.ResourcePermissions.Value)) 
		{
			userResourcePermissions = allowedPermissions.ResourcePermissions;
		}

		var areRolesAuthorized = () => {
			return  self.hasUserRoles(userRoles);		
		}
		var areResourcesAuthorized = () => {
			return self.hasResourcePermissions(userResourcePermissions);
		}

		if(authorizeRolesAndResources){
			return (areRolesAuthorized() && areResourcesAuthorized());
		}
		else{
			return (areRolesAuthorized() || areResourcesAuthorized());
		}
	}		
	
	private isStateAuthorized(state: IStateConfig): boolean 
	{
		const self = this;
		const authorizationRules = self.getStateAuthorizationRules(state)

		if (self.isNullOrEmpty(authorizationRules) || self.isNullOrEmpty(authorizationRules.AllowedPermissions)) return true; //if no auth rules defined, the state is authorized by default
		
		if (self.rootScope.actOnBehalfOfUserActive && authorizationRules.IsRestrictedInObo === true)
			return false;

		/*check if either admin or user state is authorized*/
		return self.hasUserPermissions(authorizationRules.AllowedPermissions) || (state.data && state.data["partnerAppName"] ? self.isAppAdmin(state.data["partnerAppName"]) : false);
	}	

	private getStateAuthorizationRules(state: IStateConfig) : IStateAuthorizationRule
	{
		const self = this;
		const stateName = state.name
		let authRules = self.fxpConfigurationService.FxpBaseConfiguration.AuthorizationRules.filter(function (item) {
			return (item.StateName === stateName);
		})[0];
		if(!self.isNullOrEmpty(authRules))
		{
			return authRules; // auth rules for the state in startup config takes precedence over partner app config
		}	
		
		return state.authorizationRules;
	}

	private getAppAdminRules(appName : string) : IUserPermission
	{
		const self = this;
		let adminRules;
		if(!self.isNullOrEmpty(appName))
		{
			const appConfig = PartnerAppRegistrationService.angularPartnerStateConfig.find(s => s.applicationName.toLowerCase() === appName.toLowerCase());
			if(appConfig)
			{
				adminRules = appConfig.adminRules
			}
		}
		return adminRules;
	}	

	private getClaimsList(roles: Array<string>, resourcePermissions: Array<IResourcePermission>)
	{
		const self = this;
		const tenantClaims = {};

		const apps = self.getAppList(roles, resourcePermissions);
		
		/*get claims as per app Id passed in auth rule*/
		apps.forEach(app => {
            try {
                tenantClaims[app] = self.userClaimsService.getUserTenantClaims(app);
            } catch (e) {
            }
		});
		return tenantClaims;
	}

	private getAppList(roles: Array<string>, resourcePermissions: Array<IResourcePermission> = null): Array<string>
	{
		let apps = [];
		if(roles)
		{
			apps = apps.concat(roles.map((role) => {
				return role.split(".")[0];
			}));
		}
		if(resourcePermissions)
		{
			apps = apps.concat(resourcePermissions.map((permission) => {				
				return permission.Resource.split(".")[0];
			}));
		}

		/*remove duplicates from app list to avoid calling claims service repeatedly for same app */
		apps = apps.filter((el, i, a) => i === a.indexOf(el))
		
		return apps;
	}	

	private checkRoleFunc(item: string, tenantClaims) : boolean
	{
		const self = this;
		let hasRoles = false;
		if(!self.isNullOrEmpty(item))
		{
			const[npd, role] = item.split(".");
			const NPDClaims = tenantClaims[npd];
			if (NPDClaims && NPDClaims.claims.roles.hasOwnProperty(role) === true) {
				hasRoles = true;
			}
		}
		return hasRoles;
	}	

	private checkResourcePermissionFunc(item: IResourcePermission, tenantClaims) : boolean
	{
		const self = this;
		let hasPermissions = false;
		if(item && !self.isNullOrEmpty(item.Resource))
		{
			const[npd, resource] = item.Resource.split(".");
			const NPDClaims = tenantClaims[npd];
			if (NPDClaims && NPDClaims.claims.resourcePermissions) {
				const resourcePermission = NPDClaims.claims.resourcePermissions.find((r) => (r.resource.toLowerCase() == resource.toLowerCase()));
				if (resourcePermission && resourcePermission.resource) {
					if(!item.Permissions || self.isNullOrEmpty(item.Permissions.Value)) //if no permissions mentioned only check on resource
					{
						hasPermissions = true;
						return hasPermissions;
					}
					const allPermissionsMandatory  = item.Permissions.AllPermissionsMandatory;
					if(allPermissionsMandatory)
					{
						hasPermissions = item.Permissions.Value.every(p=> resourcePermission.permissions.includes(p));
					}
					else
					{
						hasPermissions = item.Permissions.Value.some(p=> resourcePermission.permissions.includes(p));
					}
				}
			}
		}
		return hasPermissions;
	}		
}
