import { Store, Reducer, Middleware } from 'redux';
import { IFxPService } from "../interfaces/IFxpService";
import { FxpLoggerService } from '../telemetry/fxpLogger';
import { IPlatformState } from '../../app/store/platform.state.model';
import { PlatformStoreFactory } from '../../app/store/platform.store.factory';
import { IAction, IGlobalStore, GlobalStore, AbstractLogger as ILogger } from 'redux-micro-frontend';

/**
 * Summary: Anuglar 1 service to access the global store.
 * Description: Angular 1 wrapper on top of the Global Store.
 */
export class FxpGlobalStoreService implements IGlobalStore,IFxPService {
    private readonly _globalStore: IGlobalStore;

    constructor(private _logger: FxpLoggerService) {
        this._globalStore = GlobalStore.Get(<any>this._logger);
    }

    AddSelectors(source: string, selectors: Record<string, any>, mergeSelectors?: boolean): void {
        this._globalStore.AddSelectors(source, selectors, mergeSelectors);
    }

    SelectPartnerState(partner: string, selector: string, defaultReturn?: any) {
        this._globalStore.SelectPartnerState(partner, selector, defaultReturn);
    }

    CreateStore = (appName: string, appReducer: Reducer, middlewares?: Array<Middleware>, globalActions?: Array<string>, shouldReplace: boolean = false, shouldUpdate: boolean = false): Store<any, any> =>
        this._globalStore.CreateStore(appName, appReducer, middlewares, globalActions, shouldReplace, shouldUpdate);
    RegisterStore = (appName: string, store: Store, globalActions?: Array<string>, shouldReplace: boolean = false): void => 
        this._globalStore.RegisterStore(appName, store, globalActions, shouldReplace)
    RegisterGlobalActions = (appName: string, actions: Array<string>): void => 
        this._globalStore.RegisterGlobalActions(appName, actions);

    GetPlatformState = (): any => this._globalStore.GetPlatformState();
    GetPlatformStore = (): Store<IPlatformState, any> => PlatformStoreFactory.Get();
    GetPartnerState = (partnerName: string): any => this._globalStore.GetPartnerState(partnerName);
    GetGlobalState = (): any => this._globalStore.GetGlobalState();

    DispatchGlobalAction = (source: string, action: IAction<any>): void =>
        this._globalStore.DispatchGlobalAction(source, action);
    DispatchLocalAction = (source: string, action: IAction<any>): void => 
        this._globalStore.DispatchLocalAction(source, action);
    DispatchAction = (source: string, action: IAction<any>): void => 
        this._globalStore.DispatchAction(source, action)

    Subscribe = (source: string, callback: (state: any) => void): () => void => 
        this._globalStore.Subscribe(source, callback);
    SubscribeToPlatformState = (source: string, callback: (state: any) => void): () => void =>
        this._globalStore.SubscribeToPlatformState(source, callback);
    SubscribeToPartnerState = (source: string, partner: string, callback: (state: any) => void, eager: boolean = true): () => void =>
        this._globalStore.SubscribeToPartnerState(source, partner, callback, eager);
    SubscribeToGlobalState = (source: string, callback: (state: any) => void): () => void =>
        this._globalStore.SubscribeToGlobalState(source, callback);
    SetLogger = (logger: ILogger): void => this._globalStore.SetLogger(logger);
}