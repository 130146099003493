import { IRootScope } from "../../js/interfaces/IRootScope";
import { ILogger } from "../../js/interfaces/ILogger";
import { FxpConstants, PerfMarkers, ApplicationConstants } from "../../js/common/ApplicationConstants";
import { FxpMessageService } from "../banner/FxpMessageService";
import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { UserClaimsService } from "../../js/services/UserClaimsService"
import { UserInfoService } from "../../js/services/UserInfoService";
import { IFxPService } from "../../js/interfaces/IFxpService";
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { setAdminTiles } from "./adminTile.action";
import { TelemetryConstants } from "../../js/telemetry/TelemetryConst";
import { ErrorCodes } from "../../js/constants/errorCodes";
import { ErrorSeverityLevel } from "../../js/telemetry/ErrorSeverityLevel";
import { MsalAuthenticationService } from "../../js/services/MsalAuthenticationService";
declare var _: any; 
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */

export class AdminLandingService implements IFxPService {
	private http: angular.IHttpService;
	private q: angular.IQService;
	private rootScope: IRootScope;
	private timeout: angular.ITimeoutService;
	private sleepInterval: number;
	private userAlias: string;
	private fxpConfiguration: FxpConfigurationService;
	private fxplogger: ILogger;
	private fxpServiceEndPoint: string;
	private msalAuthenticationService: MsalAuthenticationService;
	private iCount: number = 0;
	private iReqCount: number = 0;
	private iUCount: number = 0;
	private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AdminLandingService`;
	private fxpMessageSvc: FxpMessageService;

	constructor(
		$http: angular.IHttpService, 
		$q: angular.IQService, 
		$rootScope: IRootScope, 
		$timeout: angular.ITimeoutService, 
		fxpConfiguration: FxpConfigurationService, 
		loggerService: ILogger, 
		msalAuthenticationService: MsalAuthenticationService, 
		fxpMessage: FxpMessageService, 
		private userClaimsService: UserClaimsService, 
		private userInfoService: UserInfoService, 
		private $base64: any,
		private globalStoreService: FxpGlobalStoreService) 
	{
		this.http = $http;
		this.q = $q;
		this.rootScope = $rootScope;
		this.timeout = $timeout;
		this.sleepInterval = 100;
		this.fxpConfiguration = fxpConfiguration;
		this.fxplogger = loggerService;
		this.fxpServiceEndPoint = this.fxpConfiguration.FxpAppSettings.FxpServiceEndPoint;
		this.msalAuthenticationService = msalAuthenticationService;
		this.fxpMessageSvc = fxpMessage;
	}

	GetAdminDataFromServer(): angular.IPromise<any> {
		let source = `${this.sourceForTelemetry}.GetAdminDataFromServer`;
		var deferred = this.q.defer();
		var self = this;
		self.userClaimsService.getUserRoles()
			.then(function (response) {
				let userResouces = self.userClaimsService.getUserAdminResources();
				
				if (userResouces.toString() == "") {
					return deferred.resolve([]);
				}

				let userRoles = self.$base64.encode(response).toString();
				let encodedUserResources = self.$base64.encode(userResouces).toString();
				var url = self.fxpServiceEndPoint + "/adminTiles/";
				self.fxplogger.startTrackPerformance(PerfMarkers.GetAdminDataFromServer);
				if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
					self.iReqCount++;
					if (self.iReqCount == 5) {
						self.fxplogger.logError(source, 
												self.rootScope.fxpUIConstants.UIMessages.GetAdminDataFailureError.ErrorMessageTitle, 
												ErrorCodes.OnGetAdminDataFromServerFailure,
												null,
												null,
												null,
												null,
												ErrorSeverityLevel.Medium);
						self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.GetAdminDataAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
					}
					self.timeout(function () {
						self.GetAdminDataFromServer();
					}, self.sleepInterval);
				}
				else {
					self.iReqCount = 0;
					self.fxplogger.logTrace(source, "getAdminDataFromServer");

					let requestHeaders = {};
					requestHeaders[ApplicationConstants.XUserClaimsToken] = userRoles;
					requestHeaders[ApplicationConstants.XTenantId] = window["tenantConfiguration"].TenantId;
					requestHeaders[ApplicationConstants.XResources] = encodedUserResources;
					self.http({
						method: "GET",
						url: url,
						headers: requestHeaders
					}).then(function (response) {
						var data = JSON.stringify(response.data);
						self.globalStoreService.GetPlatformStore().dispatch(setAdminTiles(JSON.parse(data)));
						var sessionStorageKey = ApplicationConstants.FxpAdminData + "_" + self.userInfoService.getCurrentUser();
						sessionStorage[sessionStorageKey] = data;
						deferred.resolve(response);
					}, function (error) {
						deferred.reject(error);
					});
				}
			});
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetAdminDataFromServer);

		return deferred.promise;
	}

	GetAdminTileDetails(isRefresh: boolean): angular.IPromise<any> {
		var deferred = this.q.defer();
		var self = this;
		var adminTilesData;
		if (!isRefresh) {
			adminTilesData = !Object.keys(self.globalStoreService.GetPlatformState().AdminTiles).length ? self.GetAdminDataFromCache() : self.globalStoreService.GetPlatformState().AdminTiles;
		}
		if (adminTilesData == null) {
			self.GetAdminDataFromServer().then(function (response) {
				deferred.resolve(response.data);
			}, function (error) {
				deferred.reject(error);
			});
		} else {
			deferred.resolve(adminTilesData);
		}

		return deferred.promise;
	}

	GetAdminDataFromCache(): any {
		var self = this;
		var sessionStorageKey = ApplicationConstants.FxpAdminData + "_" + self.userInfoService.getCurrentUser();
		var adminData = JSON.parse(window.sessionStorage.getItem(sessionStorageKey));
		self.globalStoreService.GetPlatformStore().dispatch(setAdminTiles(adminData));
		return adminData;
	}

}
