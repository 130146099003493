import { IAction } from 'redux-micro-frontend';
import { Reducer } from "redux";
import { SystemMessageActions } from "./systemMessage.action.types";
import { ISystemMessage } from "./systemMessage.model";


let defaultState: Array<ISystemMessage> = [];

export const SystemMessageReducer: Reducer<Array<ISystemMessage>, IAction<Array<ISystemMessage> | string>> = (
    state: Array<ISystemMessage> = defaultState,
    action: IAction<any>
): Array<ISystemMessage> => {

    switch(action.type) {
        case SystemMessageActions.UPSERT_SYSTEM_MESSAGE: {
            return (action.payload === undefined || action.payload === null) ? defaultState : action.payload; 
        }
        case SystemMessageActions.REMOVE_SYSTEM_MESSAGE: {
            return state.filter(message => message.id !== action.payload)
        }
    }
    return state;
}
