import { IRootScope } from "../../js/interfaces/IRootScope";
import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { ILogger } from "../../js/interfaces/ILogger";
import { FxpConstants } from "../../js/common/ApplicationConstants";
import { IFxPService } from "../../js/interfaces/IFxpService";
import { AddBanner, CloseBanner } from './banner.action';
import { IPlatformState } from '../store/platform.state.model';
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { PlatformStoreFactory } from '../store/platform.store.factory';
import { TelemetryConstants } from "../../js/telemetry/TelemetryConst";
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */
/**
   * A service to display all types of Messages like Error, Warning, Information
   * @class Fxp.Services.fxpMessageService
   * @classdesc A service to display all types of Messages like Error, Warning, Information
   * @example <caption> Example to create an instance of Fxp Message Service</caption>         
   *  //Initializing Fxp Message
   *  angular.module('FxPApp').controller('AppController', ['fxpMessageService', AppController]);
   *  function AppController(fxpMessageService, fxpConstants){ fxpMessageService.addMessage('message from FXP', Fxp.Common.Constants.FxpConstants.messageType.error); }
   */
export class FxpMessageService implements IFxPService {
	private $rootScope: IRootScope;
	private msgTimeout;
	private msgInterval;
	private $interval: angular.IIntervalService;
	private $timeout: any;
	private fxpConfigurationService: FxpConfigurationService;
	private trackIdLabel;
	private msgIDLabel;
	private controlIdToFocusOnClose: any;
	public showBanner: boolean;

	constructor($rootScope: IRootScope, $interval: angular.IIntervalService, $timeout, fxpConfigurationService: FxpConfigurationService,
		private fxpLoggerService: ILogger, private globalStoreService: FxpGlobalStoreService) {
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$rootScope.messages = [];
		this.$rootScope.messageClass = "modal-hide";
		this.$interval = $interval;
		this.msgTimeout = fxpConfigurationService.FxpAppSettings.FxpMessageTimeout;
		if (this.msgTimeout == "" || this.msgTimeout == null || isNaN(this.msgTimeout))
			this.msgTimeout = 2000;
		this.$rootScope.closeMessage = this.closeMessage.bind(this);
		this.$rootScope.clearAndReload = this.clearAndReload.bind(this);
		this.trackIdLabel = fxpConfigurationService.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.MessageToasterTrackId;
		this.msgIDLabel = fxpConfigurationService.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.MessageID;
		this.showBanner = true;

		PlatformStoreFactory.CreateIfNotExist(fxpLoggerService);

		this.globalStoreService.SubscribeToPlatformState("Platform", (platformState: IPlatformState) => {
			this.updateBanners(platformState);
		});
	}
	private updateBanners(platformState: IPlatformState) {
		if (platformState.Banners && platformState.Banners.length != this.$rootScope.messages.length) {
			var isNewMessageAdded = platformState.Banners.length > this.$rootScope.messages.length
			if (isNewMessageAdded) {
				var msg = platformState.Banners[platformState.Banners.length - 1];
				this.$rootScope.messages.push(msg);
			}
			else {
				for (var i = 0; i < this.$rootScope.messages.length; i++) {
					var index = platformState.Banners.indexOf(this.$rootScope.messages[i]);
					if (index == -1) {
						this.$rootScope.messages.splice(i, 1);
						break;
					}
				}
			}

		}
	}
	/**
	* Displays Error/Warning/Information messages on FXP and Focus
	* @method Fxp.Services.fxpMessageService.addMessage      
	* @param {string} a mandatory string value which contains Error/Warning/Information.          
	* @param {string} a mandatory string value determing type of messsage Error/Warning/Information.          
	* @example <caption> Example to invoke addMessage</caption>
	*  fxpMessageService.addMessage('Error from FXP', fxpConstants.messageType.error);
	*/
	addMessage = function (message: any, messageType: string, doNotAutoClose?: boolean, trackingId?: string, messageID?: string, focusControlIdOnClose?: any) {
		var self = this;
		this.controlIdToFocusOnClose = focusControlIdOnClose;
		if (!this.showBanner) {
			return;
		}
		if (!message || message.length === 0) {
			return;
		}
		var $rootScope = this.$rootScope;
		var msgTrackId = trackingId ? this.trackIdLabel + trackingId : "";
		var msgID = messageID ? this.msgIDLabel + messageID : "";

		var isMsgItemExist = $rootScope.messages.some(function (item) {

			return item.MessageType == messageType && item.Message == message && item.trackId == msgTrackId && item.uniqueTransactionId == msgID;
		});
		if (isMsgItemExist) {
			return;
		}
		var msg: any = {};
		msg.msgDate = new Date();
		msg.MessageType = messageType || FxpConstants.messageType.info;
		msg.Message = message;
		msg.show = true;
		msg.trackId = msgTrackId;
		msg.uniqueTransactionId = msgID;

		switch (msg.MessageType) {
			case FxpConstants.messageType.success:
				msg.doNotAutoClose = (doNotAutoClose !== undefined && doNotAutoClose !== null) ? doNotAutoClose : false;
				break;
			case FxpConstants.messageType.error:
				msg.doNotAutoClose = true; // Never auto close
				var propbag = this.fxpLoggerService.createPropertyBag();
				var errorMessageWithTrackingId = trackingId ? message + " " + trackingId : message;
				propbag.addToBag("ErrorMessage", errorMessageWithTrackingId);
				propbag.addToBag("Location", window.location.hash);
				this.fxpLoggerService.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpMessageService.addMessage`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ErrorDisplayed`, propbag);

				break;
			default:
				// Do not auotoclose unless it is asked explicitly
				msg.doNotAutoClose = (doNotAutoClose !== undefined && doNotAutoClose !== null) ? doNotAutoClose : true;
				break;
		}

		//Dispatch action to add banner
		self.globalStoreService.DispatchGlobalAction("Platform", AddBanner(msg));
		this.$rootScope.messageClass = this.$rootScope.messages.length > 0 ? "modal-show" : "modal-hide";

		if (this.isAnyBlockingMessageVisible())
			this.$rootScope.messageClass += " modal-blocker";

		if (this.$rootScope.messages.length == 1 && $(":focus").length > 0) {
			this.$rootScope.activeElement = $(":focus");
		}

		if (this.$rootScope.messages.length > 0) {
			let timeout = setTimeout(function () {
				var messagecontainers = $(".message-container");
				if (messagecontainers.length) {
					let msgContainer = messagecontainers[$rootScope.messages.length - 1];
					$("." + msgContainer.className + " > button").first().focus();
				}
				clearTimeout(timeout);
			}, 100);
		}
		if (!msg.doNotAutoClose) {
			this.monitorMessages();
		}
	}

	private monitorMessages() {
		if (this.msgInterval) {
			return;
		}
		let $timeout = this.$timeout;
		let timeout = this.msgTimeout;
		let $rootScope = this.$rootScope;
		let self = this;
		this.msgInterval = this.$interval(function () {
			let dt: any = new Date();
			let diff: number;
			let messageExists = false;
			for (var i = self.globalStoreService.GetPlatformState().Banners.length - 1; i >= 0; i--) {
				var message = self.globalStoreService.GetPlatformState().Banners[i];
				var messageType = message.MessageType.toLowerCase();

				if ((messageType == "success" || messageType == "warning" || messageType == "info") && (!$rootScope.messages[i].doNotAutoClose)) {
					messageExists = true;
					diff = dt - message.msgDate;
					if (diff >= timeout)
						self.globalStoreService.DispatchGlobalAction("Platform", CloseBanner(message));

				}
			}
			if (!messageExists) {
				self.$interval.cancel(self.msgInterval);
				self.msgInterval = null;
			}
			if ($rootScope.messages.length == 0) {
				$rootScope.messageClass = "modal-hide";
				if ($rootScope.activeElement) {
					let timeout = $timeout(function () {
						if ($rootScope.activeElement)
							$rootScope.activeElement.focus();
						$rootScope.activeElement = undefined;
						$timeout.cancel(timeout);
					}, 100);
				}
			}
		}, 1000);
	}

	/**
	* An event handler whenever message close button is clicked.
	* @method Fxp.Services.fxpMessageService.closeMessage
	* @param {onject} message An object which is passed from the view.
	* @example <caption> Example to use closeMessage</caption>
	*  <div ng-app="AppController"><div ng-click="closeMessage">Close Message</div></div>;
	*  <div ng-app="AppController as app"><div ng-click="app.closeMessage">closeMessage</div></div>;
	*/
	closeMessage = function (message) {
		var self = this;
		//Dispatch action to close banner
		self.globalStoreService.DispatchGlobalAction("Platform", CloseBanner(message));
		this.$rootScope.messageClass = this.$rootScope.messages.length > 0 ? "modal-show" : "modal-hide";
		if (this.isAnyBlockingMessageVisible())
			this.$rootScope.messageClass += " modal-blocker";
		if (this.$rootScope.messages.length == 0) {
			this.$interval.cancel(this.msgInterval);
			setTimeout(function () {
				if (!self.controlIdToFocusOnClose) {
					return;
				}
				if (self.controlIdToFocusOnClose.focus) {
					self.controlIdToFocusOnClose.focus();
				}
				else if (typeof (self.controlIdToFocusOnClose) === 'string') {
					$('#' + self.controlIdToFocusOnClose).focus();
				}
			}, 0);
		}
		else
			setTimeout(function () {
				var messagecontainers = $(".message-container");
				if (messagecontainers.length) {
					let msgContainer = messagecontainers[messagecontainers.length - 1];
					$("." + msgContainer.className + " > button").first().focus();
					return;
				}
			}, 100);

	}

	clearAndReload = function () {
		var propbag = this.fxpLoggerService.createPropertyBag();
		propbag.addToBag("Message", "User clicked reset application");
		propbag.addToBag("Location", window.location.hash);
		this.fxpLoggerService.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpMessageService.clearAndReload`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ResetApplication`, propbag);
		window["localStorage"].clear();
		window["sessionStorage"].clear();
		window.location.reload();
	}

	private isAnyBlockingMessageVisible() {

		return this.$rootScope.messages.some(e => e.MessageType == FxpConstants.messageType.error);
	}
}
