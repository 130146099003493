import { AbstractLogger } from 'redux-micro-frontend';
import { LogPropertyBag } from '../../js/telemetry/LogPropertyBag';
import { ILogger as IFxpLogger } from '../../js/interfaces/ILogger';
import { ErrorSeverityLevel } from '../../js/telemetry/ErrorSeverityLevel';
import { ErrorCodes } from '../../js/constants/errorCodes';
import { TelemetryConstants } from '../../js/telemetry/TelemetryConst';

export class ActionLoggerAdapter extends AbstractLogger {
    private _source: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Store.ActionLogger`;
    private _eventBase: string =`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Store`; 

    constructor(private _fxpLogger: IFxpLogger) {
        super("FXP_APP_INSIGHTS_LOGGER");
    }

    processEvent(source: string, eventName: string, properties: any): void {
        let propertyBag = this.CreatePropertyBag(properties);
        this._fxpLogger.logEvent(this._source, `${this._eventBase}.${eventName}`, propertyBag);
    }

    processException(source: string, error: Error, properties: any): void {
        let propertyBag = this.CreatePropertyBag(properties);
        this._fxpLogger.logException(this._source, error, propertyBag, null, null, ErrorSeverityLevel.Medium, ErrorCodes.ReduxStoreException);
    }

    private CreatePropertyBag(properties: any): LogPropertyBag {
        let propertyBag = this._fxpLogger.createPropertyBag();
        if (properties !== undefined && properties !== null && Object.keys(properties).length) {
            Object.keys(properties).forEach(key => {
                propertyBag.addToBag(key, properties[key]);
            });
        }
        return propertyBag;
    }
}