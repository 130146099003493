import { AppStatusReducer } from '../init/app.status.reducer';
import { AdminTileReducer } from '../adminTiles/adminTiles.reducer';
import {BannerReducer} from '../banner/banner.reducer'
import { Reducer, combineReducers } from 'redux';
import { LoaderReducer } from '../loader/loader.reducer';
import { LoggedInUserReducer,CurrentUserReducer } from '../user/user.reducer';
import { OBOReducer } from '../obo/obo.reducer';
import { LeftNavigationReducer } from '../leftNavigation/leftNavigation.reducer';
import { FeatureFlagReducer } from '../flighting/flighting.reducer';
import { SystemMessageReducer } from '../systemMessages/systemMessage.reducer';
import { NotificationReducer } from '../notifications/notifications.reducer';

export const PlatformReducer: Reducer = combineReducers({
    Status: AppStatusReducer,
    Banners: BannerReducer,
    LoggedInUser: LoggedInUserReducer,
    CurrentUser: CurrentUserReducer,
    OBO:OBOReducer,
    AdminTiles: AdminTileReducer,
    LeftNavigation: LeftNavigationReducer,
    SystemMessages: SystemMessageReducer,
    Loader: LoaderReducer,
    FeatureFlags: FeatureFlagReducer,
    Notifications: NotificationReducer
});