import { IAction } from 'redux-micro-frontend';
import { Claims } from '../claims/claims.model'
import { ClaimsActionTypes } from '../claims/claims.action.types';
export const AddLoggedInUserClaims = (userClaims: Claims): IAction<Claims> => ({
    type: ClaimsActionTypes.ADD_LOGGED_IN_USER_CLAIMS,
    payload: userClaims,
    logEnabled: false
});

export const AddCurrentUserClaims = (userClaims: Claims): IAction<Claims> => ({
    type: ClaimsActionTypes.ADD_CURRENT_USER_CLAIMS,
    payload: userClaims,
    logEnabled: false
});