import { ILogger } from "../interfaces/ILogger";
import { IFxPService } from "../interfaces/IFxpService";
import { ICorrelationProvider } from "../interfaces/ICorrelationProvider";
import { BusinessProcessEvent } from "./BusinessProcessEvent";
import { FeatureUsageEvent } from "./FeatureUsageEvent";
import { LogMetricBag } from "./LogMetricBag";
import { LogPropertyBag } from "./LogPropertyBag";
import { SystemEvent } from "./SystemEvent";
import {
  AppInsights,
  Correlator,
  EnvironmentValues,
  FeatureUsageEventV2,
} from "@microsoftit/telemetry-extensions-npm";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { TelemetryContext } from "./telemetrycontext";
import { FxpLogHelper } from "./FxpLogHelper";
import { ITelemetryContextListener } from "../interfaces/ITelemetryContextListener";
import { CommonUtils } from "../utils/CommonUtils";
import { ErrorSeverityLevel } from "./ErrorSeverityLevel";
import { RoleGroupInfo, TenantInfo } from "../common/ApplicationConstants";
import {
  RoleGroupDetails,
  TelemetryConstants,
  TenantDetails,
} from "./TelemetryConst";
import {
  IPageViewTelemetry,
  IMetricTelemetry,
} from "@microsoft/applicationinsights-common";
import { FxpLoggerServiceExtension } from "./FxpLoggerServiceExtension";
import { NetworkConnection } from "./network-connection";

declare type Envelope = any;
const PerfMarkerPrefix: string = "Perf";
/**
 * A service for telemetry logging
 * @class FxpLoggerService
 * @classdesc A service to instrument data in Telemetry
 */
export class FxpLoggerService
  implements ILogger, IFxPService, ITelemetryContextListener
{
  private _appInsight: AppInsights;
  private _environmentValues: EnvironmentValues;
  private _context: TelemetryContext;
  private _correlator: Correlator;
  private static _loggerServiceInstance: FxpLoggerService;
  private loggedInUserContext: any;
  private OBOUserContext: any;
  private isObo: boolean;
  private correlationProvider: ICorrelationProvider;
  private instrumentationKey: string;
  private pageLoadMetric: any;
  private _loggerServiceExtenstion: FxpLoggerServiceExtension;
  private _events: {
    [key: string]: number;
  };

  private constructor() {
    //this.init();
  }

  private init() {
    let FxpAppSettings: any = window["FxpAppSettings"];
    let FxpBaseConfiguration: any = window["FxpBaseConfiguration"];
    if (!this._appInsight) {
      this._events = {};
      this._appInsight = new AppInsights();
      this.instrumentationKey =
        FxpBaseConfiguration.Telemetry.InstrumentationKey;
      const clickPluginInstance = new ClickAnalyticsPlugin();
      // Click Analytics configuration
      const clickPluginConfig = {
        autoCapture: true,
        dataTags: {
          useDefaultContentNameOrId: true,
          captureAllMetaDataContent: true,
        },
      };
      // Application Insights Configuration
      const configObj = {
        instrumentationKey: FxpBaseConfiguration.Telemetry.InstrumentationKey,
        extensions: [clickPluginInstance],
        extensionConfig: {
          [clickPluginInstance.identifier]: clickPluginConfig,
        },
      };
      this._environmentValues = new EnvironmentValues(
        FxpAppSettings.EnvironmentName,
        FxpAppSettings.ServiceOffering,
        FxpAppSettings.ServiceLine,
        FxpAppSettings.Service,
        FxpAppSettings.ComponentName,
        FxpAppSettings.ComponentId,
        FxpAppSettings.IctoId
      );
      this._appInsight.InitializeTelemetryWithConfiguration(
        this._environmentValues,
        configObj
      );

      this._context = TelemetryContext.getInstance();
      this._context.initUserSession();
      this.setContextInfo();
      this._context.addContextChangeListener(this);
      this._correlator = new Correlator();
      $.correlator = this._correlator;
      const telemetryInitializer = {
        init: (envelope: Envelope) => {
          try {
            const telemetryItem = envelope.baseData;
            this.telemetryInitializerInit(
              telemetryItem,
              FxpBaseConfiguration.Telemetry.InstrumentationKey
            );
          } finally {
            envelope.tags["ai.cloud.role"] = "FxP-Client";
            return true;
          }
        },
      };
      this._appInsight.appInsights.addTelemetryInitializer(
        telemetryInitializer.init
      );
      this._appInsight.AddMsitTelemetryInitializers(this._correlator);
      this._loggerServiceExtenstion =
        FxpLoggerServiceExtension.getInstance().init(this._appInsight);
    }
  }

  /**
   * Static method to get instance of FxpLoggerService
   * @abstract
   * @method Fxp.telemetry.fxpLogger#getInstance
   * @returns {FxpLoggerService} - Returns instance of FxpLoggerService
   */
  static getInstance(): FxpLoggerService {
    if (!this._loggerServiceInstance) {
      this._loggerServiceInstance = new FxpLoggerService();
      this._loggerServiceInstance.init();
    }
    return this._loggerServiceInstance;
  }

  notify() {
    this.setContextInfo();
  }

  private setContextInfo() {
    this._appInsight.appInsights.context.session.id =
      this._context.getSessionID();
    this._appInsight.appInsights.context.application.ver =
      this._context.getAppVersion();
  }

  private telemetryInitializerInit(
    telemetryItem: any,
    instrumentationKey: string
  ): void {
    const telemetryAppName = this.getCurrentPartnerTelemetryName();
    const properties = FxpLogHelper.addEnvironmentDetails(
      new LogPropertyBag(),
      null,
      null,
      instrumentationKey,
      telemetryAppName
    );
    let finalProperties = {};

    if (telemetryItem.properties) {
      finalProperties = Object.assign(
        {},
        telemetryItem.properties,
        properties.getItems()
      );
    }

    if (finalProperties["AppName"] && finalProperties["AppName"] === "Fxp") {
      finalProperties["AppName"] = TelemetryConstants.FXP_TELEMETRY_BASE_NAME;
    }

    telemetryItem.properties = finalProperties;
  }

  private getCurrentPartnerTelemetryName(): string {
    const ngInjector = angular.element(document.body).injector();
    if (!ngInjector) return;

    const stateService: any = ngInjector.get("$state");
    if (!stateService) return;

    const currentState = stateService.current;
    return currentState.data ? currentState.data.partnerTelemetryName : null;
  }

  /**
   * Diagnostic method to log error in telemetry
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logError
   * @param {string} source - Source where this exception originated. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {string} message - Error Message.
   * @param {string} errorCode - Error Code or Error Constant.
   * @param {string} stackTrace - StackTrace of the Error.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements   - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   * @param {ErrorSeverityLevel} severityLevel - Severity Level of the error.
   */
  logError(
    source: string,
    message: string,
    errorCode: string,
    stackTrace?: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: string,
    severityLevel?: ErrorSeverityLevel
  ): void {
    try {
      let error = new Error();
      error.message = CommonUtils.removePII(message);
      error.stack = CommonUtils.removePII(stackTrace);
      this.logException(
        source,
        error,
        properties,
        measurements,
        transactionId,
        severityLevel,
        errorCode
      );
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to log exception object
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logError
   * @param {string} source - Source where this exception originated. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {Error} exception - Exception object.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements   - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   * @param {ErrorSeverityLevel} severityLevel - Severity Level of the error.
   * @param {string} errorCode - Error Code or Error Constant.
   */
  logException(
    source: string,
    exception: Error,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: string,
    severityLevel?: ErrorSeverityLevel,
    errorCode?: string
  ): void {
    try {
      exception.stack = CommonUtils.removePII(exception?.stack);
      exception.message = CommonUtils.removePII(exception?.message);
      exception.name = CommonUtils.removePII(exception?.name);
      errorCode = CommonUtils.removePII(errorCode);
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties,
        errorCode,
        exception.stack
      );
      this._appInsight.TrackException(
        exception,
        severityLevel,
        properties.getItems(),
        measurements == null ? null : measurements.getItems()
      );
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Creates and returns a new instance of LogPropertyBag object.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#createMetricBag
   * @returns {LogPropertyBag}
   */
  createPropertyBag(): LogPropertyBag {
    return FxpLogHelper.createPropertyBag();
  }

  /**
   * Creates and returns a new instance of LogmetricBag object.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#createMetricBag
   * @returns {LogMetricBag}
   */
  createMetricBag(): LogMetricBag {
    return FxpLogHelper.createMetricBag();
  }

  /**
   * Diagnostic method to log warnings which will be captured in customEvents of application insights with EventType as Warning
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logWarning
   * @param {string} source - Source from where the Warning is logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {string} message - Warning Message.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements   - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logWarning(
    source: string,
    message: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: string
  ): void {
    try {
      if (!properties) {
        properties = this.createPropertyBag();
      }
      properties.addToBag("EventType", "Warning");
      this.logEvent(source, message, properties, measurements, transactionId);
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to log Information which will be captured in customEvents of application insights with EventType as Information
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logInformation
   * @param {string} message - Information Message
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements   - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logInformation(
    source: string,
    message: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: string
  ): void {
    try {
      if (!properties) {
        properties = this.createPropertyBag();
      }
      properties.addToBag("EventType", "Information");
      this.logEvent(source, message, properties, measurements, transactionId);
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to log Events which will be captured in customEvents of application insights.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logCustomEvents
   * @param {string} source - Source from where the Event is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {string} eventName - Event Name to be logged.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements   - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logEvent(
    source: string,
    eventName: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: string
  ): void {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      this._appInsight.TrackEvent(
        eventName,
        properties.getItems(),
        measurements
      );
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to Track Page Views in telemetry
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logPageView
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param pageName - Name of the visited page as string
   * @param {string} url - Url of the visited page.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {number} duration - Time took to load the page. The value should be a positive integer.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logPageView(
    source: string,
    pageName: string,
    url?: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    duration?: number,
    transactionId?: string
  ): void {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      if (!measurements) {
        measurements = this.createMetricBag();
      }
      if (duration) {
        measurements.addToBag(`${pageName}-LoadDuration`, duration);
      }
      let pageViewTelemetry: IPageViewTelemetry = {
        name: pageName,
        uri: url,
        refUri: null,
        pageType: null,
        isLoggedIn: null,
        properties: properties.getItems(),
        measurements: measurements.getItems(),
      };
      this._appInsight.appInsights.trackPageView(pageViewTelemetry);
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * OBSOLETE Method. Diagnostic method to Track Page Views
   * @abstract
   * @method Fxp.telemetry.fxpLogger#trackPageView
   * @param {string} url - Url of the visited page.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements   - measurements to be logged in customDimensions.
   * @param duration - Time took to load the page. The value should be a number.
   * @deprecated - This method is deprecated and will be removed in future. Please use logPageView method instead.
   */
  trackPageView(
    url?: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    duration?: number,
    transactionId?: string
  ): void {
    try {
      this.logPageView(null, null, url, properties, measurements, duration);
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to Track Metrics in telemetry
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logMetric
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {string} metricName - Name of the metric.
   * @param {number} metricValue - The value of the metric as number.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logMetric(
    source: string,
    metricName: string,
    metricValue: number,
    properties?: LogPropertyBag,
    transactionId?: string
  ): void {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      let metricTelemetry: IMetricTelemetry = {
        name: metricName,
        average: metricValue,
        properties: properties.getItems(),
      };
      this._appInsight.appInsights.trackMetric(metricTelemetry);
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Method to start tracking FeatureUsageEvent.
   * @description Method to start tracking FeatureUsageEvent.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logSystemEvent
   * @param {FeatureUsageEvent} featureUsageEvent - Instance of FeatureUsageEvent type.
   */
  startFeatureUsageEvent(featureUsageEvent: FeatureUsageEvent) {
    this._loggerServiceExtenstion.startFeatureUsageEvent(featureUsageEvent);
  }

  /**
   * Method to complete tracking FeatureUsageEvent.
   * @description Method to complete tracking FeatureUsageEvent.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#endFeatureUsageEvent
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {FeatureUsageEvent} featureUsageEvent - Instance of SystemEvent type.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  endFeatureUsageEvent(
    source: string,
    featureUsageEvent: FeatureUsageEvent,
    properties?: LogPropertyBag,
    transactionId?: any
  ) {
    properties = this.setTransactionIdAndCommonProperties(
      source,
      transactionId,
      properties
    );
    this._loggerServiceExtenstion.endFeatureUsageEvent(
      featureUsageEvent,
      properties
    );
  }

  /**
   * Diagnostic method to log Feature Usage in telemetry. This method can be used for tracking single session workflows.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logFeatureUsageEvent
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {FeatureUsageEvent} eventData - Instance of FeatureUsageEvent.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logFeatureUsageEvent(
    source: string,
    eventData: FeatureUsageEvent,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: any
  ): void {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      this._loggerServiceExtenstion.logFeatureUsageEvent(
        eventData,
        properties,
        measurements
      );
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to log Business Process Event in telemetry.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logBusinessProcessEvent
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {BusinessProcessEvent} eventData - Instance of FeatureUsageEvent.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logBusinessProcessEvent(
    source: string,
    eventData: BusinessProcessEvent,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: any
  ): void {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      this._loggerServiceExtenstion.logBusinessProcessEvent(
        eventData,
        properties,
        measurements
      );
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Diagnostic method to log trace information in telemetry.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logTrace
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {string} message - Trace message.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logTrace(
    source: string,
    message: string,
    properties?: LogPropertyBag,
    transactionId?: string
  ): void {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      this._appInsight.TrackTrace(message, properties.getItems());
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Internal method to Set the correlationProvider object for FxpLoggerService. This method should only be invoked from Platform during initialization.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#setCorrelationProvider
   * @param {ICorrelationProvider} correlationProvider - Instance of ICorrelationProvider
   */
  setCorrelationProvider(correlationProvider: ICorrelationProvider) {
    this.correlationProvider = correlationProvider;
  }

  /**
   * Method to start performance tracking.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#startTrackPerformance
   * @param {string} eventName - Name of the event
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  startTrackPerformance(eventName: string, transactionId?: any): void {
    const metricName = PerfMarkerPrefix + eventName;
    try {
      if (!CommonUtils.isNullOrEmpty(this._events[metricName])) {
        console.warn(
          "Start was called before calling stop for event : " + metricName
        );
      } else {
        this._events[metricName] = performance.now();
      }
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Method to complete performance tracking.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#stopTrackPerformance
   * @param {string} eventName - Name of the event
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  stopTrackPerformance(
    eventName: string,
    source?: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: any
  ): void {
    const metricName = PerfMarkerPrefix + eventName;
    const start = this._events[metricName];

    if (isNaN(start)) {
      console.warn(
        "Stop was called before calling start for event : " + eventName
      );
    } else {
      const duration = performance.now() - start;
      delete this._events[eventName];
      this._events[eventName] = undefined;
      if (CommonUtils.isNullOrEmpty(measurements)) {
        measurements = new LogMetricBag();
      }

      try {
        measurements.addToBag(metricName, duration);
        properties = this.setTransactionIdAndCommonProperties(
          source,
          transactionId,
          properties
        );
        properties.addToBag("Unit", "Milliseconds");
        let metricTelemetry: IMetricTelemetry = {
          name: metricName,
          average: duration,
          properties: properties.getItems(),
        };

        this._appInsight.appInsights.trackMetric(metricTelemetry);
      } catch (exception) {
        this.internalCustomLog(exception);
      }
    }
  }

  getPageLoadMetrics() {
    return this.pageLoadMetric;
  }

  setPageLoadMetrics(value: any) {
    this.pageLoadMetric = value;
  }

  logPageLoadMetrics(pageLoadTime?: number): void {
    try {
      var pageLoadMetricsCalled = performance.now();
      var propBag = this.createPropertyBag();
      var pageLoadData = this.pageLoadMetric;

      pageLoadData.totalDuration = performance.now() - pageLoadData.startTime;

      if (
        !CommonUtils.isNullOrEmpty(pageLoadData.sourceRoute) &&
        !CommonUtils.isNullOrEmpty(pageLoadData.destinationRoute)
      ) {
        if (
          pageLoadTime != null &&
          pageLoadTime != undefined &&
          typeof pageLoadTime == "number"
        ) {
          pageLoadData.pageLoadDuration = pageLoadTime;
        } else pageLoadData.pageLoadDuration = 0;

        propBag.addToBag("pageDisplayName", pageLoadData.pageDisplayName);
        propBag.addToBag("sourceRoute", pageLoadData.sourceRoute);
        propBag.addToBag(
          "sourceRouteURL",
          CommonUtils.isNullOrEmpty(pageLoadData.sourceRouteURL)
            ? "No_SourceRouteURL_Found"
            : pageLoadData.sourceRouteURL
        );
        propBag.addToBag("destinationRoute", pageLoadData.destinationRoute);
        propBag.addToBag(
          "destinationRouteURL",
          CommonUtils.isNullOrEmpty(pageLoadData.destinationRouteURL)
            ? "No_DestinationRouteURL_Found"
            : pageLoadData.destinationRouteURL
        );
        propBag.addToBag(
          "pageTransitionStatus",
          pageLoadData.pageTransitionStatus
        );
        propBag.addToBag(
          "stateChangeDuration",
          pageLoadData.stateChangeDuration.toString()
        );
        propBag.addToBag(
          "preStateDuration",
          pageLoadData.preStateDuration.toString()
        );
        propBag.addToBag("error", pageLoadData.pageLoadError);
        propBag.addToBag(
          "partnerPageLoadDuration",
          pageLoadData.pageLoadDuration.toString()
        );
        propBag.addToBag(
          "totalDuration",
          pageLoadData.totalDuration.toString()
        );
        propBag.addToBag(
          "thresholdCrossed",
          pageLoadData.threshold.thresholdCrossed.toString()
        );
        propBag.addToBag(
          "thresholdValue",
          pageLoadData.threshold.thresholdValue.toString()
        );

        // Temp properties added for trouble shooting
        propBag.addToBag("startTime", pageLoadData.startTime.toString());
        propBag.addToBag(
          "$rootScope.startTime",
          CommonUtils.isNullOrEmpty(pageLoadData.rootscopestartTime)
            ? "0"
            : pageLoadData.rootscopestartTime.toString()
        );
        propBag.addToBag(
          "$rootScope.stateChangeStartTime",
          CommonUtils.isNullOrEmpty(pageLoadData.stateChangeStartTime)
            ? "0"
            : pageLoadData.stateChangeStartTime.toString()
        );
        propBag.addToBag(
          "pageLoadMetricsCalled",
          pageLoadMetricsCalled.toString()
        );
        this.logEvent(
          pageLoadData.sourceRoute,
          "FxpTelemetry.PageMetrics",
          propBag
        );
      }
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  setLoggedInUserContext(
    loggedInUserRoleId: string,
    loggedInUserRoleName: string,
    isOBO: boolean,
    loggedInUserTenantKey: string,
    loggedInUserTenantName: string
  ) {
    this.loggedInUserContext = {
      [RoleGroupInfo.RoleGroupId]: loggedInUserRoleId
        ? loggedInUserRoleId.toString()
        : loggedInUserRoleId,
      [RoleGroupInfo.RoleGroupName]: loggedInUserRoleName,
      [TenantInfo.TenantKey]: loggedInUserTenantKey,
      [TenantInfo.TenantName]: loggedInUserTenantName,
    };
    this.isObo = isOBO;
  }

  setOBOUserContext(
    OBOUserRoleId: string,
    OBOUserRoleName: string,
    isOBO: boolean,
    OBOUserTenantKey: string,
    OBOUserTenantName: string
  ) {
    this.OBOUserContext = {
      [RoleGroupInfo.RoleGroupId]: OBOUserRoleId
        ? OBOUserRoleId.toString()
        : OBOUserRoleId,
      [RoleGroupInfo.RoleGroupName]: OBOUserRoleName,
      [TenantInfo.TenantKey]: OBOUserTenantKey,
      [TenantInfo.TenantName]: OBOUserTenantName,
    };
    this.isObo = isOBO;
  }

  /**
   * Method to log user actions which will be logged in customEvents with EventType as UserAction.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logSystemEvent
   * @param {string} source - Source from where this is getting logged. It should be in format <AppName>.<PageName>.<MethodName>
   * @param {string} eventName - Name of the event. It should be in format <AppName>.<EventName>
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logUserAction(
    source,
    eventName: string,
    message: string,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: string
  ) {
    try {
      if (!properties) {
        properties = this.createPropertyBag();
      }
      properties.addToBag("EventType", "UserAction");
      properties.addToBag("message", message);
      this.logEvent(source, eventName, properties, measurements, transactionId);
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  /**
   * Method to log system events which will be logged in customEvents with EventType as SystemEvent.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#logSystemEvent
   * @param {string} source - Source from where this is getting logged. It should be in Format <AppName>.<PageName>.<MethodName>
   * @param {SystemEvent} eventData - Instance of SystemEvent type.
   * @param {LogPropertyBag} properties - Properties for the customDimensions.
   * @param {LogMetricBag} measurements  - measurements to be logged in customDimensions.
   * @param {string} transactionId - Transaction id or Operation id of the transaction.
   */
  logSystemEvent(
    source: string,
    eventData: SystemEvent,
    properties?: LogPropertyBag,
    measurements?: LogMetricBag,
    transactionId?: any
  ) {
    try {
      properties = this.setTransactionIdAndCommonProperties(
        source,
        transactionId,
        properties
      );
      this._appInsight.TrackSystemEvent(
        eventData,
        properties.getItems(),
        measurements
      );
    } catch (exception) {
      this.internalCustomLog(exception);
    }
  }

  private setTransactionId(transactionId?: any) {
    if (transactionId) {
      this._appInsight.appInsights.context.telemetryTrace.traceID =
        transactionId;
      return;
    }
    const subCorrelationId = this._correlator.getSubCorrelationId();
    const correlationId = this._correlator.getCorrelationId();
    if (subCorrelationId) {
      this._appInsight.appInsights.context.telemetryTrace.traceID =
        subCorrelationId;
      this._appInsight.appInsights.context.telemetryTrace.parentID =
        correlationId;
      return subCorrelationId;
    }
    this._appInsight.appInsights.context.telemetryTrace.traceID = correlationId;
    return correlationId;
  }

  private setTransactionIdAndCommonProperties(
    source?: string,
    transactionId?: any,
    properties?: LogPropertyBag,
    errorCode?: string,
    stackTrace?: string
  ) {
    this.setTransactionId(transactionId);
    properties = this.addPropertyBagValues(properties, transactionId, source);
    properties = this.buildCommonLogProperties(properties, source, null, null);
    return properties;
  }

  /**
   * To Builds log properties that are common to all methods
   * @param properties
   * @param source
   * @param errorCode
   * @param stackTrace
   */
  private buildCommonLogProperties(
    properties: LogPropertyBag,
    source: string,
    errorCode: string,
    stackTrace: string
  ) {
    const logProperties = properties || this.createPropertyBag();
    if (logProperties.getValue(TelemetryConstants.LOGGING_SOURCE)) {
      logProperties.addToBag(
        "Source",
        properties.getValue(TelemetryConstants.LOGGING_SOURCE)
      );
      logProperties.removeFromBag(TelemetryConstants.LOGGING_SOURCE);
    } else if (!CommonUtils.isNullOrEmpty(source)) {
      logProperties.addToBag("Source", source);
    }
    if (!CommonUtils.isNullOrEmpty(errorCode))
      logProperties.addToBag("ErrorCode", errorCode);

    if (!CommonUtils.isNullOrEmpty(stackTrace))
      logProperties.addToBag("StackTrace", stackTrace);

    return logProperties;
  }

  private addPropertyBagValues(
    eventProperties: LogPropertyBag,
    transactionId?: string,
    source?: string
  ) {
    eventProperties = eventProperties || new LogPropertyBag();
    const appNameForTelemetry = this.getCurrentPartnerTelemetryName();
    eventProperties = FxpLogHelper.addEnvironmentDetails(
      eventProperties,
      transactionId,
      source,
      this.instrumentationKey,
      appNameForTelemetry
    );
    eventProperties = this.addUserRoleGroupDetails(eventProperties);
    eventProperties = this.addUserTenantDetails(eventProperties);
    eventProperties.addToBag(
      "networkSpeed",
      NetworkConnection.getInstance().getCurrentNetworkSpeed()
    );
    return eventProperties;
  }

  private addUserRoleGroupDetails(eventProperties: LogPropertyBag) {
    if (this.loggedInUserContext) {
      eventProperties.addToBag(
        RoleGroupDetails.LOGGEDINUSERROLEGROUPID,
        this.loggedInUserContext.RoleGroupId == undefined
          ? ""
          : this.loggedInUserContext.RoleGroupId
      );
      eventProperties.addToBag(
        RoleGroupDetails.LOGGEDINUSERROLEGROUPNAME,
        this.loggedInUserContext.RoleGroupName == undefined
          ? ""
          : this.loggedInUserContext.RoleGroupName
      );
    }
    if (this.isObo && this.OBOUserContext) {
      eventProperties.addToBag(
        RoleGroupDetails.OBOROLEGROUPID,
        this.OBOUserContext.RoleGroupId == undefined
          ? ""
          : this.OBOUserContext.RoleGroupId
      );
      eventProperties.addToBag(
        RoleGroupDetails.OBOROLEGROUPNAME,
        this.OBOUserContext.RoleGroupName == undefined
          ? ""
          : this.OBOUserContext.RoleGroupName
      );
    }
    return eventProperties;
  }

  /**
   * To add loggedIn user tenant properties that are common to all methods
   * @param properties
   */
  private addUserTenantDetails(eventProperties: LogPropertyBag) {
    if (this.loggedInUserContext) {
      eventProperties.addToBag(
        TenantDetails.LOGGEDINUSERTENANTKEY,
        this.loggedInUserContext == undefined
          ? ""
          : this.loggedInUserContext.TenantKey
      );
      eventProperties.addToBag(
        TenantDetails.LOGGEDINUSERTENANTNAME,
        this.loggedInUserContext.TenantName == undefined
          ? ""
          : this.loggedInUserContext.TenantName
      );
    }
    if (this.isObo && this.OBOUserContext) {
      eventProperties.addToBag(
        TenantDetails.OBOTENANTKEY,
        this.OBOUserContext == undefined ? "" : this.OBOUserContext.TenantKey
      );
      eventProperties.addToBag(
        TenantDetails.OBOTENANTNAME,
        this.OBOUserContext.TenantName == undefined
          ? ""
          : this.OBOUserContext.TenantName
      );
    }
    return eventProperties;
  }

  public getCorrelationId(correlationId?: string): string {
    if (!CommonUtils.isNullOrEmpty(correlationId)) return;
    if (this.correlationProvider)
      return this.correlationProvider.getCorrelationId();
    if (this._correlator) return this._correlator.getCorrelationId();
    if ($.correlator) return $.correlator.getCorrelationId();
  }

  public getSubCorrelationId(): string {
    if (this._correlator) return this._correlator.getSubCorrelationId();
  }

  /**
   * Method to renew the correlation identity.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#renewCorrelationId
   */
  public renewCorrelationId() {
    if (this._correlator) return this._correlator.renewCorrelationId();
  }

  /**
   * Method to renew the sub correlation identity.
   * @abstract
   * @method Fxp.telemetry.fxpLogger#renewSubCorrelationId
   * @description Method to renew the sub correlation identity.
   */
  public renewSubCorrelationId() {
    if (this._correlator) return this._correlator.renewSubCorrelationId();
  }

  /**
   * logs error to console
   * @param exception
   */
  private internalCustomLog(exception) {
    console.error(TelemetryConstants.ERRORPREFIX + JSON.stringify(exception));
  }

  getDefaultPropertyBagValues(properties?: LogPropertyBag): LogPropertyBag {
    let propBag = properties || this.createPropertyBag();
    propBag = this.addPropertyBagValues(propBag);
    return propBag;
  }
}
