import { Reducer } from 'redux';
import { IAction } from 'redux-micro-frontend';
import { Profile } from './profile.model';
import { ProfileActionTypes } from './profile.action.types';

const defaultProfile: Profile = {} as Profile

export const ProfileReducer: Reducer<Profile, IAction<Profile>> = (
    state: Profile = defaultProfile,
    action: IAction<Profile>): Profile => {

    if (action.type == ProfileActionTypes.ADD_CURRENT_USER_PROFILE || action.type == ProfileActionTypes.ADD_LOGGED_IN_USER_PROFILE) {
        return action.payload

    }
    return state;
}