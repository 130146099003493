import { Reducer } from 'redux';
import { IAction } from 'redux-micro-frontend';
import { Claims } from './claims.model';
import { ClaimsActionTypes } from './claims.action.types';

const defaultClaims: Claims = {} as Claims

export const ClaimsReducer: Reducer<Claims, IAction<Claims>> = (
    state: Claims = defaultClaims,
    action: IAction<Claims>): Claims => {

    if (action.type==ClaimsActionTypes.ADD_CURRENT_USER_CLAIMS || action.type == ClaimsActionTypes.ADD_LOGGED_IN_USER_CLAIMS) {
        return action.payload;
    }

    return state;
}