import { Reducer } from 'redux';
import { IAction } from 'redux-micro-frontend';
import { Actions } from './adminTile.action.types';
import { IAdminTileGroup } from './adminTiles.model';

const defaultState: Array<IAdminTileGroup> = [];

export const AdminTileReducer: Reducer<Array<IAdminTileGroup>, IAction<any>> = (
    state: Array<IAdminTileGroup> = defaultState,
    action: IAction<any>
): Array<IAdminTileGroup> => {

    switch(action.type) {
        case Actions.SET_ADMIN_TILES : {
            return action.payload;
        }
        case Actions.REMOVE_OBO_CONTEXT : {
            return defaultState;
        }
    }
    
    return state;
}

