import { ISystemMessagesService } from "./ISystemMessagesService";
import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { ISystemMessage } from "./systemMessage.model";
import { UserInfoService } from "../../js/services/UserInfoService";
import { IHttpPromise, IPromise } from "angular";
import { IFxPService } from "../../js/interfaces/IFxpService";

export class SystemMessagesService implements ISystemMessagesService,IFxPService {
	private systemMessagesEndPoint: string;
	private allSystemMessagesEndpoint: string[];

	private allSystemAnnouncementEndpoints: string[];

	constructor(private http: angular.IHttpService,
		private fxpConfiguration: FxpConfigurationService, private $q: angular.IQService, private userInfoService: UserInfoService) {
		this.systemMessagesEndPoint = this.fxpConfiguration.FxpAppSettings.FxpServiceEndPoint + "/systemmessages";
		this.allSystemMessagesEndpoint = this.fxpConfiguration.FxpAppSettings.SystemMessageEndpoints.split(',');
		this.allSystemAnnouncementEndpoints = this.fxpConfiguration.FxpAppSettings.SystemAnnouncementEndpoints.split(',');
	}

	getSystemMessagesCollectionAsync = (messageCount: number, pageOffset: number, sortOrder: string): IHttpPromise<Array<ISystemMessage>> => {
		let params = {
			'pageSize': messageCount,
			'pageNo': pageOffset,
			'sortOrder': sortOrder
		};
		return this.http.get(this.systemMessagesEndPoint, { params: params });
	}

	saveSystemMessageAsync = (systemMessage: ISystemMessage): IPromise<any> => {
		return this.http.post(this.systemMessagesEndPoint, systemMessage, { headers: { 'Content-Type': 'application/json' } });
	}

	deleteSystemMessageAsync = (systemMessageId: string): IPromise<any> => {
		let params = { 'id': systemMessageId };
		return this.http.delete(this.systemMessagesEndPoint, { params: params });
	}

	getPlannedDownTimeCollection = (): IPromise<Array<ISystemMessage>> => {
		let promises : Array<IPromise<Array<ISystemMessage>>> = [];
		let self = this;
		self.allSystemMessagesEndpoint.forEach(endpoint => { promises.push(this.getSystemMessages(endpoint))})
		return this.$q.all(promises).then((results: Array<Array<ISystemMessage>>) => {
			return results.reduce((acc, val) => acc.concat(val), []);
		});
	}

	getSystemAnnoucementCollection = (): IPromise<Array<ISystemMessage>> => {
		let promises : Array<IPromise<Array<ISystemMessage>>> = [];
		let self = this;
		let userAlias = self.userInfoService.getLoggedInUser();
		let requestHeaders = {};
		requestHeaders["x-alias"] = userAlias;
		self.allSystemAnnouncementEndpoints.forEach(endpoint => { promises.push(this.getSystemMessages(endpoint, requestHeaders))})
		return this.$q.all(promises).then((results: Array<Array<ISystemMessage>>) => {
			// append all the api results in a single array
			return results.reduce((acc, val) => acc.concat(val), []);
		});
	}

	getSystemMessages(url: string, headers?: any) : IPromise<Array<ISystemMessage>> {
		return this.http.get(url, { headers: headers })
			.then(response => (<any>response.data).systemMessages);
	}
}