declare var navigator: any; 

export class NetworkConnection {
    _connection: any = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    _effectiveType: any;
    static _networkConnection: NetworkConnection; 

    constructor(){
        this._connection.addEventListener('change', this.broadcastNetworkChange.bind(this));
        this.getCurrentNetworkSpeed();
    }

    static getInstance(): NetworkConnection{
        if (!this._networkConnection)
            this._networkConnection = new NetworkConnection(); 

        return this._networkConnection;
    }

    getCurrentNetworkSpeed(){
        this._effectiveType = this._connection.effectiveType;
        return this._effectiveType; 
    }

    broadcastNetworkChange() {
        const newEffectiveType = this._connection.effectiveType;
        const networkChangeCustomEvent = new CustomEvent("network_speed_changed", {
            bubbles: true, // only bubbles and cancelable
            cancelable: true, // work in the Event constructor
            detail: {'from': this._effectiveType, 'to': newEffectiveType}
        });
        window.dispatchEvent(networkChangeCustomEvent);
        this._effectiveType = newEffectiveType; 
        
    }
}
