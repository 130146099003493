//action creators for Banner
import { IAction } from 'redux-micro-frontend';
import { Banner } from "./banner.model"
import { BannerActionTypes } from './banner.action.types';

export const AddBanner = (payload: Banner): IAction<Banner> => {
    return {
        type: BannerActionTypes.ADD_BANNER_MESSAGE,
        payload: payload
    }
}
export const CloseBanner = (payload: Banner): IAction<Banner> => {
    return {
        type: BannerActionTypes.CLOSE_BANNER_MESSAGE,
        payload: payload
    }
}