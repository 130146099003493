import { ProfileActionTypes } from './profile.action.types';
import {Profile} from './profile.model';
import { IAction } from 'redux-micro-frontend';
export const AddLoggedInUserProfile = (userProfile: Profile): IAction<Profile> => ({
    type: ProfileActionTypes.ADD_LOGGED_IN_USER_PROFILE,
    payload: userProfile,
    logEnabled: false
});
export const AddCurrentUserProfile = (userProfile: Profile): IAction<Profile> => ({
    type: ProfileActionTypes.ADD_CURRENT_USER_PROFILE,
    payload: userProfile,
    logEnabled: false
});