import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";

export class ErrorCodes {
    public static readonly ModuleBoostrapFailure = '3012'; 
    public static readonly ModuleBoostrapFailureRequireJS = '3013';
    public static readonly GlobalExceptionHandler = '3301';
    public static readonly GlobalExceptionHandler_AngularJs = '3300';
    public static readonly OnMsalLoginFailure = '3303';
    public static readonly OnMsalTokenFailure = '3305';
    public static readonly OnTelemetryContextError = '3306';
    public static readonly OnSettingsServiceError = '3307';
	public static readonly OnGetAdminTilesFailure =  '2702';
    public static readonly OnGetAdminDataFromServerFailure = '2701';
    public static readonly PowerBI_Invalid_Configuration = '50003';
    public static readonly PowerBI_OnError = '50001'; 
    public static readonly PowerBI_Token_Failure = '50002';
    public static readonly GetLeftNavData_Failure = '1801';
    public static readonly SaveLeftNavPinSetting_Error = '3502';
    public static readonly PlannedDownTime_UpdateFlash_Failure = '2931'; 
    public static readonly PlannedDownTime_UpdateSystemAccouncement_Failure = '2932'; 
    public static readonly SystemMessage_Read_Collection_Rejection = '2916';
    public static readonly SystemMessage_Fetch_Business_Workflow = '2917';
    public static readonly SystemMessage_Save_Failure = '2918';
    public static readonly SystemMessage_Update_Failure = '2918';
    public static readonly SystemMessage_Delete_Failure = '2920';
    public static readonly AcquireTokenFailure_UnKnown_Reason = '3304';
    public static readonly OBO_User_Already_Active = '2907';
    public static readonly Get_OBO_User_Configuration_Failure = '2906'; 
    public static readonly OBO_User_Search_Failure = '2602';
    public static readonly State_Not_Found = '2908';
    public static readonly State_Change_Failure = '2909';
    public static readonly Msal_ResourceTokenAcuqisition_Failure = '3302';
    public static readonly SkypeBotInit_Failure = '2911';
    public static readonly API_Threshold_Crossed = '2910';
    public static readonly InitializeFeedback_Failure = '3400';
    public static readonly Error_SubmittingFeedback = '3401';
    public static readonly Error_LoadingFeedback = '3402';
    public static readonly GetRoleGroupDetails_Failure = '3452';
    public static readonly PublishNotifications_Failure = '3451';
    public static readonly PublishNotificationsRolesRoleGroup_Failure = '3453';
    public static readonly GetRequestTypeData_Failure = '3501';
    public static readonly CloseActOnBehalofUser_Failure = '3502';
    public static readonly GetContextualHelpArticles_Failure = '4001'; 
    public static readonly GetArticleData_Failure = '4002';
    public static readonly SaveHelpFeedback_Failure = '4003';
    public static readonly SearchSuggestions_Failure = '4004';
    public static readonly SaveViewCount_Failure = '4005';
    public static readonly ShowErrorMsgNavigateToUserLookUp = '4006'; 
    public static readonly SavePersonalizedNavItems = '4007';
    public static readonly GetNotifications_Failure = '2940';
    public static readonly GetUnreadNotificationCount_Failure = '2941';
    public static readonly MarkAllNotificationsAsRead_Failure = '2943';
    public static readonly DismissAllNotifications_Failure = '2942';
    public static readonly ReadNotification_Failure = '2943';
    public static readonly DeleteNotification_Failure = '2942';
    public static readonly SaveNotificationDNDSetting = '3503';
    public static readonly ErrorLoadingDashboard = '2913'; 
    public static readonly ErrorFetchingTokenForPowerBI = '2914';
    public static readonly ErrorLoadingPowerBiDashboard = '2915';
    public static readonly SubmitRoleGroupNavPresonalization_Failure = '2921';
    public static readonly GetRoleGroupDetailsPersonalization_Failure = '2921';
    public static readonly GetGlobalNavMasterList_Failure = '2917';
    public static readonly GetRoleGroupNavList_Failure = '2916';
    public static readonly ReadToastNotification_Failure = '2943';
    public static readonly MarkNotificationAsRead_Failure = '2944';
    public static readonly UserLookUpSearchUser_Failure = '2945';
    public static readonly SetSelectedUser_Failure = '2946';
    public static readonly NavigateToPersonalizationView_Failure = '2947';
    public static readonly ErrorOnLeftNavClick = '2948';
    public static readonly HandleProfileServiceResponse = '2949';
    public static readonly AdalOnError = '0001';
    public static readonly AdalOnErrorWithErrorCode = '0002';
    public static readonly DefaultStateNotConfigured = '2905';
    public static readonly ErrorRegisteringRoute = '2904';
    public static readonly ErrorInLazyLoading = '2906';
    public static readonly ErrorUpdatingStateObject = '2907';
    public static readonly RouteResolveException = '2908';
    public static readonly HttpInterceptorResponseError = '2500';
    public static readonly DashboardService_GetLeftNavDataFailure = '2603';
    public static readonly FxPConfigurationFailure = '2604'
    public static readonly StartUpFlagRetrieveError = '2912';
    public static readonly DataService_TokenFailure = '5001';
    public static readonly FeedbackService_Error = '3401';
    public static readonly CheckToastNotifications_Failure = '2940';
    public static readonly SharedComponentNotRegistered = '2941';
    public static readonly LazyLoadingFailedForSharedComponent = '2942';
    public static readonly PersonalizationService_GetMasterLeftNav_Failure = '2605'; 
    public static readonly PersonalizationService_GetPersonalizedLeftNav_Failure = '2606';
    public static readonly PersonalizationSerivce_SavePersonalizedNavItems_Failure = '2607';
    public static readonly PersonalizationSerivce_GetRoleGroupDetails_Failure = '2608';
    public static readonly PersonalizationSerivce_GetRoleGroupDetailsForNotification_Failure = '2609';
    public static readonly PersonalizationSerivce_GetRolePersonalizedNavItems_Failure = '2610';
    public static readonly PersonalizationSerivce_GetRoleGroupPersonalizedList_Failure = '2611';
    public static readonly PersonalizationSerivce_SaveRoleGroupPersonalizedNavItems_Failure = '2612';
    public static readonly AuthSerive_UserClaimsEmptyInLocalStorage = "2804";
    public static readonly AuthSerive_MissingApplicationObjectInClaims = "2805";
    public static readonly AuthSerive_MissingRolesObjectInClaims = "2806";
    public static readonly AuthSerive_TenantClaimsEmptyInLocalStorage = "2801";
    public static readonly AuthSerive_TenantObjectMissingInClaims = "2802";
    public static readonly AuthSerive_ClaimsMissingInTenantObject = "2803";
    public static readonly AuthService_TokenAcquisitionInProgress = '2804';
    public static readonly AuthService_TokenAcquisitionInProgress_TenantClaims = '2805';
    public static readonly AuthService_TenantClaimsObjectMissingInClaims = '2806';
    public static readonly AuthService_APICallFailedWithUnknownReason = '2807';
    public static readonly AuthService_ErrorNotifyingCachedClaims = '2809';
    public static readonly AuthService_UnknownException = '2808';
    public static readonly AuthService_GetClaimsFailure = '2810';
    public static readonly GetUserThumbnail_TokenNotFound = '2901';
    public static readonly GetBasicProfile_TokenNotFound = '2902';
    public static readonly GetBasicProfile_BusinessRoleMissing = '2903';
    public static readonly GetBasicProfile_RoleGroupMissing = '2904';
    public static readonly GetBasicProfile_ProfileNotFound = '2905';
    public static readonly BasicProfile_UnknownError = '2906';
    public static readonly SearchProfile_TokenMissing = '2908'
    public static readonly ProfileServiceFailure = '2909';
    public static readonly ProfileService_ErrorNotifyingCachedProfile = '2910';
    public static readonly ErrorOnProfileSearch = '2911';
    public static readonly ModuleCompilationError = '5000';
    public static readonly ErrorInstantiatingComponent = '5001';
    public static readonly ErrorLoadingReactComponent = '5002';
    public static readonly ErrorUnloadingReactComponent = '5003';
    public static readonly Resiliency_ExternalResourceLoadingError = '1800';
    public static readonly Resiliency_ModuleLoadingError = '1800';
    public static readonly SignalR_ErrorOnDisconnect = '1900';
    public static readonly SignalR_ErrorOnMessageSend = '1901';
    public static readonly SignalR_ErrorOnJoinGroup = '1902';
    public static readonly SignalR_ErrorOnLeaveGroup = '1903';
    public static readonly SignalR_ErrorOnConnection = '1904';
    public static readonly PartnerAppRegistrationService_ErrorOnAppRegistration = '2001';
    public static readonly PartnerAppRegistrationService_ErrorOnSharedComponentRegistration = '2002';
    public static readonly ReduxStoreException = '6000';	

    public static readonly OnStateChangeStartException = '3701'; 

}
