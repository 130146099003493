import { FxpEventBroadCastService } from "./BroadCastingService";
import { FxpBroadcastedEvents } from './FxpBroadcastedEvents';


export class FxpIrisBotService {

    constructor(private fxpEventBroadCastService: FxpEventBroadCastService) {

    }

    launchIrisBot() {
        this.fxpEventBroadCastService.broadCast( FxpBroadcastedEvents.OnLaunchIrisBot , "Launch Bot");
    }

    hideIrisBot() {
        this.fxpEventBroadCastService.broadCast( FxpBroadcastedEvents.OnHideIrisBot, "Hide Bot");
    }
    
}