//action creators for OBO Entity
import { IAction } from 'redux-micro-frontend';
import { OBOEntity } from "./obo.model"
import { OBOActionTypes } from './obo.action.types';

export const AddOBOEntity = (payload: OBOEntity): IAction<OBOEntity> => {
    return {
        type: OBOActionTypes.ADD_OBO_ENTITY,
        payload: payload
    }
}
export const RemoveOBOEntity = (): IAction<OBOEntity> => {
    return {
        type: OBOActionTypes.REMOVE_OBO_ENTITY,
        payload: {} as OBOEntity
    }
}