import { ApplicationConstants } from "../common/ApplicationConstants";

export class CommonUtils {

  public static clone(input: object): any {
    return JSON.parse(JSON.stringify(input));
  }

  public static isNullOrEmpty(input: any) {
    return (input === null || input === undefined || jQuery.isEmptyObject(input) || input.toString().length === 0 || input === "null");
  }

  public static getNewGuId(): string {
    var d: number = new Date().getTime();
    var guid: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return guid;
  }

  public static isObject(obj: any): boolean {
    return (!!obj) && (obj.constructor === Object);
  }

  public static pullFocusToElement(elementId): void {
    $('#' + elementId).focus();
  }

  public static angularInherits(ctor, superCtor): void {
    ctor.super_ = superCtor;
    if (superCtor) {
      ctor.prototype = Object.create(superCtor.prototype, {
        constructor: {
          value: ctor,
          enumerable: false
        }
      });
    }
  }

  public static hashCode(value): string {
    var hash = 0;
    if (value.length == 0)
      return hash.toString();
    for (var i = 0; i < value.length; i++) {
      var char = value.toString().charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash.toString();
  }

  public static isGuid(value) {
    var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    var match = regex.exec(value);
    return match != null;
  }

  public static getSessionStorageUserInfoKey(userIdentifier: string): string {
    if (userIdentifier === undefined || userIdentifier === null)
      return null;

    if (this.isGuid(userIdentifier))
      return userIdentifier + "-userInfo";
    return userIdentifier.toLowerCase() + "-userInfo";
  }

  public static getSessionStorageUserClaimsKey(userIdentifier: string): string {
    if (userIdentifier === undefined || userIdentifier === null)
      return null;

    if (this.isGuid(userIdentifier))
      return "fxpUserClaims_" + userIdentifier;
    return "fxpUserClaims_" + userIdentifier.toLowerCase();
  }

  public static getArrayLength(arrayCollection): number {
    return CommonUtils.isNullOrEmpty(arrayCollection) ? 0 : arrayCollection.length;
  }

  public static getIndexOfObject(source, compareKey, value): number {
    var index = -1;
    var length = source.length;
    for (let i = 0; i < length; i++) {
      if (source[i][compareKey] === value) {
        index = i;
        break;
      }
    }
    return index;
  }

  public static generateUPN(userInfo: any, fxpAppSettings: any): string {
    // getting user alias and removing partners~ from it if it is there.
    let UPN = userInfo.Alias;
    if (userInfo.Domain.toLowerCase() == "corpnet")
      // appending domain.
      UPN += fxpAppSettings.UpnCorpnet;
    else
      // removing partners~ from UPN if it is there and appending partner domain.
      UPN = UPN.substring(UPN.indexOf('~') + 1) + fxpAppSettings.UpnPartner;
    return UPN.toLowerCase();
  }

  public static stringFormat(str: string, ...params: string[]) {
    return str.replace(/{(\d+)}/g, function (match, number) {
      return typeof params[number] != 'undefined'
        ? params[number]
        : match
        ;
    });
  }

  public static getTenantURLSpecificPageTitle(str: string) {
    if (window.location.href.indexOf(ApplicationConstants.SalesTenantUrlString) > -1) {
      return str.replace(ApplicationConstants.ESTenanatUrlString, ApplicationConstants.SalesTenantUrlString);
    }
    return str;
  }

  public static jsonConcat(obj1: any, obj2: any) {
    for (var key in obj2) {
      obj1[key] = obj2[key];
    }
    return obj1;
  }

  private static isObjectAnArray(val: any): boolean {
    return Object.prototype.toString.call(val) === '[object Array]';
  }

  private static isPlainObject(val: any): boolean {
    return Object.prototype.toString.call(val) === '[object Object]';
  }

  /**
  * This method reads the value of the property specified with key parameter from the given object in case insensitive way.
  * @abstract
  * @method CommonUtils#getPropertyValueCaseInsensitive
  * @param {object} object - Object on which property has to be read.
  * @param {string} key   - Name of the property in any case (Upper, Lower, Pascal, Camel)
  * @returns {string} - The value of the property on the given object.
  */
  public static getPropertyValueCaseInsensitive(object: object, key: string) {
    return object[Object.keys(object).filter(function (k) {
      return k.toLowerCase() === key.toLowerCase();
    })[0]];
  }

  public static sortJsonObj(inputJson: any, isArray: boolean = false) {
    isArray = isArray || false;
    let inputObject: any = {};
    if (CommonUtils.isObjectAnArray(inputJson)) {
      // Sort or don't sort arrays
      if (isArray) {
        inputObject = inputJson;
      } else {
        inputObject = inputJson.sort();
      }

      inputObject.forEach(function (v, i) {
        inputObject[i] = CommonUtils.sortJsonObj(v, isArray);
      });

      if (!isArray) {
        inputObject = inputObject.sort(function (a, b) {
          a = JSON.stringify(a);
          b = JSON.stringify(b);
          return a < b ? -1 : (a > b ? 1 : 0);
        });
      }
    } else if (CommonUtils.isPlainObject(inputJson)) {
      inputObject = {};
      Object.keys(inputJson).sort(function (a, b) {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      }).forEach(function (key) {
        inputObject[key] = CommonUtils.sortJsonObj(inputJson[key], isArray);
      });
    } else {
      inputObject = inputJson;
    }
    return inputObject;
  }

  public static objectToString(input: any): string {
    if (!input) return null;
    if (typeof input === "string") {
      return input;
    }
    if (!CommonUtils.isObject(input)) return null;

    try {
      return JSON.stringify(input);
    }
    catch (e) {
      return null;
    }

  }

  public static getQueryString(field: string, url?: string) {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
  };

  public static getScriptBaseUrl(){
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return baseUrl;
  }

  public static removePII(data: string):string{
    let FxpAppSettings: any = window["FxpAppSettings"];
    if (CommonUtils.isNullOrEmpty(data)) {
      return data;
    }
    else if(CommonUtils.isObject(data)){
			data = CommonUtils.objectToString(data);
			if(CommonUtils.isNullOrEmpty(data)){
				return data;
			}
		}
    else {
      data = String(data);
    }
    let PIIRegex;
    if (CommonUtils.isNullOrEmpty(FxpAppSettings?.PIIFilterRegex) 
      && !CommonUtils.isNullOrEmpty(ApplicationConstants?.PIIFilterRegex)) {
      PIIRegex = JSON.parse(ApplicationConstants?.PIIFilterRegex);
    }
    else {
      PIIRegex = JSON.parse(FxpAppSettings?.PIIFilterRegex);
    }
    if(!CommonUtils.isNullOrEmpty(PIIRegex)){
      PIIRegex.forEach((filter) => {
        const regexp = new RegExp(filter, "g");
        data = data?.replace(regexp, "REDACTED");
      });
    }
    return data;
  }
}
