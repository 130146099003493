import { IFxPService } from "../interfaces/IFxpService";

//import { Observable ,  from as fromPromise } from 'rxjs';

export class FxpHttpService implements IFxPService {
    constructor(private $http: angular.IHttpService) {
    }

    private toEs6Promise(qPromise: any) {
        return new Promise(function (resolve, reject) {
            qPromise.then(function (response) {
                resolve(response);
            }).catch(function (reason) {
                reject(reason);
            })
        });
    }
    get(url: string, headers?: any, responseType?: string): Promise<any> {
        var self = this;
        return this.toEs6Promise(self.$http.get(url, { headers: headers, responseType: responseType }));
    }
    put(url: string, data: any, headers?: any): Promise<any> {
        var self = this;
        return this.toEs6Promise(self.$http.put(url, data, { headers: headers }));
    }
    patch(url: string, data: any, headers?: any): Promise<any> {
        var self = this;
        return this.toEs6Promise(self.$http.patch(url, data, { headers: headers }));
    }
    post(url: string, data: any, headers?: any, responseType?: string): Promise<any> {
        var self = this;
        return this.toEs6Promise(self.$http.post(url, data, { headers: headers, responseType: responseType }));
    }
    jsonp(url: string, data: any, headers?: any): Promise<any> {
        var self = this;
        return this.toEs6Promise(self.$http.jsonp(url, { headers: headers }));
    }
    delete(url: string, headers?: any, data?: any): Promise<any> {
        var self = this;
        if (data) {
            return this.toEs6Promise(self.$http({ method: 'DELETE', url: url, data: data, headers: headers}));
        }

        return this.toEs6Promise(self.$http.delete(url, { headers: headers }));
    }
    head(url: string, headers?: any): Promise<any> {
        var self = this;
        return this.toEs6Promise(self.$http.head(url, { headers: headers }));
    }
}
