import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef, ComponentRef } from '@angular/core';
import { PartnerAppRegistrationService } from '../../../js/services/PartnerAppRegistrationService';
import { FxpLazyLoader } from '../../fxplazyloader';
import { LazyLoaderService } from "../../../js/services/lazyLoaderService";
import { PageLoaderService } from '../../loader/pageLoaderService';
import { FxpEventBroadCastService } from '../../../js/services/BroadCastingService';
import { FxpBroadcastedEvents } from '../../../js/services/FxpBroadcastedEvents';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';

@Component({
    selector: 'page-tour',
    templateUrl: './pageTour.component.html'
})
export class PageTourLauncherComponent implements OnInit {
    private pageTourComponent;
    private isPageTourFlyoutOpen: boolean;
    public unViewedTourCount: number;
    public showCountClass: string = 'hideCount';
    constructor(@Inject(forwardRef(() => PageLoaderService)) public pageLoaderService: PageLoaderService,
        @Inject(forwardRef(() => LazyLoaderService)) public lazyLoadingService: LazyLoaderService,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpEventBroadCastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpLoggerService)) private fxpLoggerService: FxpLoggerService
    ) {

    }

    @ViewChild('container', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
    ngOnInit() {
        this.isPageTourFlyoutOpen = false;
        this.initPageTourComponent();
        this.bindEventListeners();

    }

    initPageTourComponent = () =>{
        this.fxpLoggerService.renewCorrelationId();
        if (this.pageTourComponent == null) {
            let self = this;
            let pageTourBundle = PartnerAppRegistrationService.getBundlesForSharedComponent('pagetour', "pagetour-flyout");
            this.lazyLoadingService.getInstance().load(pageTourBundle, { serie: true }).then(function(){
                let route = PartnerAppRegistrationService.getSharedComponentDetails('pagetour', "pagetour-flyout");
                let ngComponent = route.component;
                let ngModule = route.ngModule;
                let ngModuelRef = FxpLazyLoader.instantiateModule(ngModule);
                let componentFactory = ngModuelRef.componentFactoryResolver.resolveComponentFactory(ngComponent);
                self.pageTourComponent = self.entry.createComponent(componentFactory);
            });
        }
    }

    bindEventListeners = () => {
        let self = this;
        self.fxpEventBroadCastService.On(FxpBroadcastedEvents.OnPageTourFlyoutOpened, (state)=>this.setPageTourFlyoutState(true));
        self.fxpEventBroadCastService.On(FxpBroadcastedEvents.OnPageTourFlyoutClosed, (state)=>this.setPageTourFlyoutState(false));
        self.fxpEventBroadCastService.On("ShowUnviewedTourCount", function (event, props) {
            self.unViewedTourCount = props.get("unviewedTourCount");
            if(self.unViewedTourCount > 0)
                self.showCountClass = 'showCount';
        });
    }

    setPageTourFlyoutState = (isOpen: boolean) =>{
        this.isPageTourFlyoutOpen = isOpen;
    }

    togglePageTour = () => {
        if (!this.isPageTourFlyoutOpen)
            this.showPageTourFlyout();
        else
            this.hidePageTourFlyout();
    }

    showPageTourFlyout = function () {
        if(this.isPageTourFlyoutOpen) return;
        this.fxpEventBroadCastService.broadCast(FxpBroadcastedEvents.ShowPageTour, true);
    }

    hidePageTourFlyout = () => {
        if(!this.isPageTourFlyoutOpen) return;
        if (this.pageTourComponent != null) {
            this.fxpEventBroadCastService.broadCast(FxpBroadcastedEvents.HidePageTour, true);
        }
    }
}
