import { Reducer } from 'redux';
import { IAction } from 'redux-micro-frontend';
import { AppStatus } from './app.status.model';
import { AppStatusActionTypes } from './app.status.action.types';

const defaultState: AppStatus = {
    Initialization: "Not started",
    Authentication: "Unauhtenticated",
    HitCounter: 0
};

export const AppStatusReducer: Reducer<AppStatus, IAction<any>> = (
    state: AppStatus = defaultState,
    action: IAction<any>
): AppStatus => {
    switch(action.type) {
        case AppStatusActionTypes.START_APP_INITIALIZATION: {
            return { ...state, Initialization: "STARTED" }
        }
        case AppStatusActionTypes.COMPLETE_APP_INITIALIZATION: {
            return { ...state, Initialization: "COMPLETED" }
        }
        case AppStatusActionTypes.AUTHENTICATE_USER: {
            return { ...state, Authentication: "Authenticated" }
        }
        case AppStatusActionTypes.FAIL_AUTHENTICATION: {
            return { ...state, Authentication: "Failed" }
        }
        // Test
        case AppStatusActionTypes.UPDATE_COUNTER: {
            return { ...state, HitCounter: state.HitCounter + action.payload }
        }
    }

    return state;
}