import { NgModule, COMPILER_OPTIONS, CompilerFactory } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactAppModule } from './reactapp.module';
import { PowerBiReportContainerModule } from './powerbi-module';


import {
    FxpConfigurationServiceProvider,
    AdalLoginHelperServiceProvider,
    DashboardServiceProvider,
    FxpMessageServiceProvider,
    HelpCentralServiceProvider,
    OBOUserServiceProvider,
    pageTourEventServiceProvider,
    SystemMessagesServiceProvider,
    UserInfoServiceProvider,
    UserProfileServiceProvider,
    UserClaimsServiceProvider,
    FxpBreadcrumbServiceProvider,
    AdminLandingServiceProvider,
    DataServiceProvider,
    NotificationActionCenterProvider,
    FxpStorageServiceProvider,
    PersonalizationServiceProvider,
    FxpFeedbackServiceProvider,
    FxpRouteServiceProvider,
    NotificationStoreProvider,
    PageLoaderServiceProvider,
    PlannedDownTimeServiceProvider,
    TimeZoneHelperProvider,
    FxpAuthorizationServiceProvider,
    NotificationServiceProvider,
    FxpEventBroadCastServiceProvider,
    FxpHttpServiceProvider,
    FxpLoggerServiceProvider,
    FxpFeatureFlagServiceProvider,
    FxpConfitServiceProvider,
    FxpStateTransitionServiceProvider,
    FxpUIDataProvider,
    SettingsServiceProvider,
    DeviceFactoryProvider,
    FxpContextServiceProvider,
    FxpHttpInterceptorHooksProvider,
    LazyLoaderServiceProvider,
    FxpIrisBotServiceProvider,
    FxpGlobalStoreServiceProvider,
    FxpOCVFeedbackServiceProvider,
    FxpSignalRServiceProvider,
    MsalAuthenticationServiceProvider,
    // MsalAuthenticationServiceProvider

} from '../services/FxpServices';
import { AppComponent } from '../components/app-component/app.component';
import { UIRouterUpgradeModule  } from '@uirouter/angular-hybrid';
import { PartnerContainerComponent } from '../components/partnerContainer-component/partnerContainer-component';
import { PartnerStateService } from '../components/partnerContainer-component/partnerStateService';
import { UIRouterModule } from '@uirouter/angular';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { FxpBotModule } from '../components/fxpbot/fxpbot.module';
import { AngularMaterialModule } from './angular.material.module';
import { FxpSharedComponentLoader, FxpsharedcomponentloaderModule } from '@fxp/fxpsharedcomponentloader';
import { FxpPageTourModule } from '../components/pageTour-Component/pageTour.module';

import { SharedComponentsModule, SharedComponentLoader} from '@fxp/angularcontrols';

let importModules = [
    BrowserModule,
    FormsModule,
    UpgradeModule,
    HttpClientModule,
    UIRouterUpgradeModule,
    UIRouterModule,
    BrowserAnimationsModule,
    NgbModule,
    FxpBotModule,
    AngularMaterialModule,
    ReactAppModule,
    FxpsharedcomponentloaderModule,
    PowerBiReportContainerModule,
    FxpPageTourModule,
    SharedComponentsModule
];

const fxpServices = [
    HttpClientModule,
    FxpConfigurationServiceProvider,
    MsalAuthenticationServiceProvider,
    AdalLoginHelperServiceProvider,
    AdminLandingServiceProvider,
    DashboardServiceProvider,
    DataServiceProvider,
    FxpAuthorizationServiceProvider,
    FxpBreadcrumbServiceProvider,
    FxpFeedbackServiceProvider,
    FxpMessageServiceProvider,
    FxpRouteServiceProvider,
    FxpStorageServiceProvider,
    HelpCentralServiceProvider,
    NotificationActionCenterProvider,
    NotificationServiceProvider,
    NotificationStoreProvider,
    OBOUserServiceProvider,
    PageLoaderServiceProvider,
    pageTourEventServiceProvider,
    PersonalizationServiceProvider,
    PlannedDownTimeServiceProvider,
    SystemMessagesServiceProvider,
    TimeZoneHelperProvider,
    UserInfoServiceProvider,
    UserProfileServiceProvider,
    UserClaimsServiceProvider,
    FxpEventBroadCastServiceProvider,
    FxpHttpServiceProvider,
    FxpLoggerServiceProvider,
    FxpFeatureFlagServiceProvider,
    FxpConfitServiceProvider,
    FxpStateTransitionServiceProvider,
    FxpUIDataProvider,
    SettingsServiceProvider,
    DeviceFactoryProvider,
    PartnerStateService,
    FxpContextServiceProvider,
    FxpHttpInterceptorHooksProvider,
    LazyLoaderServiceProvider,
    FxpIrisBotServiceProvider,
    FxpOCVFeedbackServiceProvider,
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true},
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS]},
    FxpGlobalStoreServiceProvider,
    FxpSignalRServiceProvider
];

@NgModule({
    imports: importModules,
    declarations: [AppComponent, PartnerContainerComponent ],
    providers: fxpServices,
    bootstrap: [
       AppComponent
    ],
entryComponents: [PartnerContainerComponent, FxpSharedComponentLoader, SharedComponentLoader],
exports: [PartnerContainerComponent]
})
export class AppModule {

}




