import { ISystemMessage } from "./systemMessage.model";
import { FxpConstants } from "../../js/common/ApplicationConstants";
import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { SYSTEM_MESSAGE_UI } from "../../js/constants/systemMessages.constants";
import { ILogger } from "../../js/interfaces/ILogger";
import { TimeZoneHelper } from "../../js/services/TimeZoneHelper";
import { SystemMessagesService } from "./SystemMessagesService";
import { IRootScope } from "../../js/interfaces/IRootScope";
import { IFxPService } from "../../js/interfaces/IFxpService";
import { FxpSignalRService } from "../../js/services/FxpSignalRClient";
import { DashboardService } from "../../js/services/dashboardService";
import { FxpRouteService } from "../../js/services/FxpRouteService";
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { IPlatformState } from "../store/platform.state.model";
import { TelemetryConstants } from "../../js/telemetry/TelemetryConst";
import { RemoveSystemMessage, UpsertSystemMessage } from "./systemMessage.action";
import { ErrorSeverityLevel } from "../../js/telemetry/ErrorSeverityLevel";
import { ErrorCodes } from "../../js/constants/errorCodes";

declare var _: any;

export class PlannedDownTimeService implements IFxPService {
	private intervalTimeMinutes: number;
	private displayFlashBeforeMinutes: number;
	private errorCount = 0;
	private loadPromise: angular.IPromise<any>;
	private plannedDownTimeCollection: Array<ISystemMessage> = [];

	private systemAnnouncementCollection: Array<ISystemMessage> = [];
	private isFlashVisible: boolean = false;
	private flashMessages: Array<ISystemMessage> = [];
	private dismissedSysMessageIds: Array<string> = [];

	public isSystemAnnouncementVisible: boolean = false;
	private systemAnnouncementMesage: string;
	private currentLeftNavItem: any;
	private systemDownMessage: string;
	private timeFormat = "MM/DD/YYYY h:mm a z";
	private unsubscribeFunction: any;
	private readonly signalREventName = "onSystemMessageChange";
	private readonly signalRTenant = "FXP";
	sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PlannedDownTimeService`;

	constructor(private $timeout: any,
		private fxpConfiguration: FxpConfigurationService,
		private SystemMessagesService: SystemMessagesService,
		private TimeZoneHelper: TimeZoneHelper,
		private moment: any,
		private UiString: SYSTEM_MESSAGE_UI,
		private logger: ILogger,
		private $window: angular.IWindowService,
		private fxpSignalRService: FxpSignalRService,
		private dashboardService: DashboardService,
		private $rootScope: IRootScope,
		private fxpRouteService: FxpRouteService,
		private fxpGlobalStore: FxpGlobalStoreService) {

		this.intervalTimeMinutes = fxpConfiguration.FxpAppSettings.FlashPollingIntervalInMinutes;
		this.displayFlashBeforeMinutes = fxpConfiguration.FxpAppSettings.DisplayFlashBeforeMinutes;
		
		this.dismissedSysMessageIds = localStorage['DismissedSystemMessages'] ? JSON.parse(localStorage['DismissedSystemMessages']) : [];

		// subscribe to platform state
		this.fxpGlobalStore.SubscribeToPlatformState("Platform", (platformState : IPlatformState) => {
			let systemMessagesInState = platformState.SystemMessages;
			if(systemMessagesInState.length > 0) {		
				this.flashMessages = systemMessagesInState;
				this.isFlashVisible = true;
			} else {
				this.isFlashVisible = false;
			}
		});
	}


	public subscribeToSignalREvent() {
		if (!this.unsubscribeFunction) {
			this.unsubscribeFunction = this.fxpSignalRService.subscribe(this.signalREventName, this.signalRTenant, this.onMessageFromSignalR.bind(this));
		}
	}

	public unsubscribeSignalREvent() {
		if (this.unsubscribeFunction) {
			this.unsubscribeFunction();
			this.unsubscribeFunction = null;
		}
	}

	private onMessageFromSignalR = function (response: any) {
		if (!response)
			return;

		if (response.operation === "edit" || response.operation === "delete")
			this.plannedDownTimeCollection = this.plannedDownTimeCollection.filter(x => x.id !== response.payLoadObject.id);

		this.cleanSysMessageIdsInLocalStorage();

		if (response.operation === "add" || response.operation === "edit")
			this.plannedDownTimeCollection = this.plannedDownTimeCollection.concat(response.payLoadObject);

		_.each(this.plannedDownTimeCollection, (item: ISystemMessage) => {
			item.startTime = this.TimeZoneHelper.convertUtcToLocal(this.moment.utc(item.startTime));
			item.endTime = this.TimeZoneHelper.convertUtcToLocal(this.moment.utc(item.endTime));
		});

		this.updateFlash();
	}

	GetSystemAnnouncementMessageandUpdateFlash() {
		let self = this;
		const tenSeconds = 10000;
		self.$timeout(function () {
			self.SystemMessagesService.getSystemAnnoucementCollection()
				.then(onSystemAnnouncementSuccess.bind(self))
				.then(self.updateSystemAnnouncementFlash.bind(self))
				.catch(onRejected.bind(self));
		}, tenSeconds);

		function onSystemAnnouncementSuccess(response) {
			let self = this;

			if (!response)
				return;

			self.systemAnnouncementCollection = response;

			_.each(self.systemAnnouncementCollection, (item: ISystemMessage) => {
				item.startTime = self.TimeZoneHelper.convertUtcToLocal(self.moment.utc(item.startTime));
				item.endTime = self.TimeZoneHelper.convertUtcToLocal(self.moment.utc(item.endTime));
			});
		}

		function onRejected(error) {
		}
	}

	pollForPlannedDownTimesandUpdateFlash() {
		let self = this;
		const tenSeconds = 10000;
		self.$timeout(function () {
			self.SystemMessagesService.getPlannedDownTimeCollection()
				.then(onSuccess.bind(self))
				.then(self.updateFlash.bind(self))
				.catch(onRejected.bind(self))
				.finally(self.nextLoad.bind(self));
		}, tenSeconds);

		function onSuccess(response) {
			let self = this;

			if (!response)
				return;

			self.plannedDownTimeCollection = response;

			_.each(self.plannedDownTimeCollection, (item: ISystemMessage) => {
				item.startTime = self.TimeZoneHelper.convertUtcToLocal(self.moment.utc(item.startTime));
				item.endTime = self.TimeZoneHelper.convertUtcToLocal(self.moment.utc(item.endTime));
			});
		}

		function onRejected(error) {
		}
	};

	private pollForUpdateFlash() {
		let self = this;
		self.updateFlash();
		self.nextLoad();
	}

	cancelNextLoad() {
		let self = this;
		self.$timeout.cancel(self.loadPromise);
	};

	nextLoad() {
		let self = this;
		self.cancelNextLoad();
		self.loadPromise = self.$timeout(self.pollForUpdateFlash.bind(self), self.intervalTimeMinutes * 60 * 1000);
	};

	pausePlannedDownTimesPoll() {
		var self = this;
		if (self.loadPromise) {
			self.cancelNextLoad();
		}
	};

	resumePlannedDownTimesPoll() {
		var self = this;
		self.pollForPlannedDownTimesandUpdateFlash();
	};

	updateFlash() {
		let self = this;
		if (!self.plannedDownTimeCollection || !self.currentLeftNavItem)
			return;

		var item = self.currentLeftNavItem;
		this.isFlashVisible = false;

		try {
			let messagesToFlash: Array<ISystemMessage> = [];
			_.each(self.plannedDownTimeCollection, (downTime: ISystemMessage) => {
				var now = this.moment();

				if (self.isDownTimeConfigured(item, downTime)) {
					//Intermittent Message or Flash
					var tempTime = downTime.startTime.clone();
					if (self.isSystemDown(downTime)) {
						this.isFlashVisible = false;
						var end = this.moment.tz(downTime.endTime, this.moment.tz.guess()).format(this.timeFormat);
						this.systemDownMessage = downTime.message;
						//If any of the system down time message is active then break the loop.
						return;
					}
					else if (now.isSameOrAfter(tempTime.subtract(self.displayFlashBeforeMinutes, "minutes"))
						&& now.isSameOrBefore(downTime.endTime)) {
						// check for the messageId in the localStorage, push if the id is not found.
						if(!this.dismissedSysMessageIds.includes(downTime.id)) {
							messagesToFlash.push(downTime);
						}
					}
				};
			});
			this.fxpGlobalStore.DispatchGlobalAction("Platform", UpsertSystemMessage(messagesToFlash));
		}
		catch (e) {
      		self.logger.logException(`${this.sourceForTelemetry}.UpdateFlash`, e, null, null, null, ErrorSeverityLevel.Medium, ErrorCodes.PlannedDownTime_UpdateFlash_Failure);
		}

	};

	updateSystemAnnouncementFlash() {
		let self = this;
		if (!self.systemAnnouncementCollection || !self.currentLeftNavItem)
			return;

		var item = self.currentLeftNavItem;
		this.systemAnnouncementMesage = '';
		this.isSystemAnnouncementVisible = false;

		try {
			_.each(self.systemAnnouncementCollection, (downTime: ISystemMessage) => {
				var now = this.moment();

				if (self.isDownTimeConfigured(item, downTime)) {
					//Intermittent Message or Flash
					var tempTime = downTime.startTime.clone();
					if (now.isSameOrAfter(tempTime.subtract(self.displayFlashBeforeMinutes, "minutes"))
						&& now.isSameOrBefore(downTime.endTime)) {

						this.systemAnnouncementMesage = self.concatenateFlashMessage(this.systemAnnouncementMesage, downTime);
						this.isSystemAnnouncementVisible = true;
					}

				}
			});
		}
		catch (e) {
			self.logger.logException(`${this.sourceForTelemetry}.UpdateSystemAnnouncementFlash`, e, null, null, null, ErrorSeverityLevel.Medium, ErrorCodes.PlannedDownTime_UpdateSystemAccouncement_Failure);
		}
	};

	isDownTimeConfigured(item: any, downTime: ISystemMessage) {

		//Return true when capability is All. Id for All is 0.
		if (downTime.businessCapability[0].id == 0) {
			return true;
		} else if (this.isMessageConfiguredOnLeftNav(item, downTime)) {
			return true;
		} else {
			var leftNavLinks = this.dashboardService.getCachedLeftNavForCurrentUser();
			if (leftNavLinks === undefined || leftNavLinks === null || leftNavLinks.length <= 0)
				return false;
			var dashboardItem = leftNavLinks.find(item => item.id == 100);
			if(dashboardItem)
				dashboardItem.targetUIStateName = this.fxpRouteService.getDefaultStateName();
			
			var leftNavItemsWithCurrentState = leftNavLinks.filter(function (leftNav) {
				return (leftNav.targetUIStateName && leftNav.targetUIStateName.toLowerCase() === item.targetUIStateName.toLowerCase());
			});
			if (leftNavItemsWithCurrentState === undefined || leftNavItemsWithCurrentState === null || leftNavItemsWithCurrentState.length <= 0)
				return false;
			let isSystemMessageVisible = false;
			leftNavItemsWithCurrentState.forEach(selectedItem => {
				if (this.isMessageConfiguredOnLeftNav(selectedItem, downTime))
					isSystemMessageVisible = true;
			});
			return isSystemMessageVisible;
		}
	}

	private isMessageConfiguredOnLeftNav(item: any, downTime: ISystemMessage) {
		return (item.id == downTime.businessCapability[0].id
			|| (downTime.businessFunction.length > 0
				&& item.id == downTime.businessFunction[0].id)
			|| (item.parentId == downTime.businessCapability[0].id
				&& downTime.businessFunction[0].id == 0));
	}

	isSystemDown(downTime: ISystemMessage) {
		var now = this.moment();

		if (downTime.messageType == "SystemDown"
			&& now.isAfter(downTime.startTime)
			&& now.isSameOrBefore(downTime.endTime))
			return true;

		return false;
	}

	concatenateFlashMessage(message: string, downTime: ISystemMessage) {
		let tempMessage = message;

		if (tempMessage != '')
			tempMessage = tempMessage + '<br/>';

		tempMessage = tempMessage + downTime.message;

		return tempMessage;
	}

	isStateDown(stateName) {
		var self = this;

		if (!self.plannedDownTimeCollection || !self.currentLeftNavItem)
			return false;

		var item = self.currentLeftNavItem;
		var systemDown = false;

		_.each(self.plannedDownTimeCollection, (downTime: ISystemMessage) => {

			if (self.isDownTimeConfigured(item, downTime)) {
				if (self.isSystemDown(downTime)) {

					var downStateName = item.targetUIStateName || item.targetURL || item.targetEventName;

					if (stateName == downStateName) {
						systemDown = true;
						return true;
					}
				}
			}
		});
		return systemDown;
	}

	closeSystemAnnouncement() {
		var self = this;
		self.isSystemAnnouncementVisible = false;
		self.$rootScope.isSystemAnnouncementVisible = false;
	}

	/**
	 * Closes the specific SytemMessage when dismissed. 
	 * @param messageId - id of the System Message to be removed
	 */
	private closeSystemMessage(messageId) {
		var self = this;
		// push the messageId to the dismissedSystemMessages in local storage.
		this.dismissedSysMessageIds.push(messageId)
		localStorage['DismissedSystemMessages'] = JSON.stringify(this.dismissedSysMessageIds);
		self.fxpGlobalStore.DispatchGlobalAction("Platform", RemoveSystemMessage(messageId));
	}

	/**
	 * Cleans the dismissedSysMessageId in local storage by comparing with plannedDownTimeCollection. 
	 */
	private cleanSysMessageIdsInLocalStorage() {
		this.dismissedSysMessageIds = this.dismissedSysMessageIds.filter(id => this.plannedDownTimeCollection.some(sysMessage => sysMessage.id === id));
		localStorage['DismissedSystemMessages'] = JSON.stringify(this.dismissedSysMessageIds);
	}
}
