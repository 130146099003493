import { IAction } from 'redux-micro-frontend';
import { Reducer } from "redux";
import { Actions } from './leftNavigation.action.types';
import { ILeftNavigationGroup } from './leftNavigation.model';

const defaultState: ILeftNavigationGroup = {} as ILeftNavigationGroup;

export const LeftNavigationReducer: Reducer<ILeftNavigationGroup, IAction<ILeftNavigationGroup>> = (
    state: ILeftNavigationGroup = defaultState,
    action: IAction<ILeftNavigationGroup>
): ILeftNavigationGroup => {

    switch (action.type) {
        case Actions.SET_LEFTNAV: {
            return action.payload;
        }
    }

    return state;
}