import { TelemetryConstants } from "../telemetry/TelemetryConst";

export class CustomEvents {
	public static UserSessionCreated: string = 'UserSessionCreated';
	public static FxpAppLaunched: string = 'FxpAppLaunched';
	public static StartUpFlagRetrieved = 'StartUpFlagRetrieved';
	public static PageTourFlagRetrieved = 'PageTourFlagRetrieved';
	public static SkypeBotInit = 'SkypeBotInit';
	public static ProfileNotFound = 'ProfileNotFound';
	public static RedirectedToSales = 'RedirectedToSales';
	public static OnProfileFromGraphAPI = 'OnProfileFromGraphAPI';
}

export class ApplicationConstants {
	public static Application = "Application";
	public static Global = "Global";
	public static GlobalContextDBName: string = 'globalV3';
	public static BootApiUrl = "/Home/GetClientSideConfiguration";
	public static WorkerFilepath = "../js/webworker/fxpwebworker.js";
	public static BaseConfigDBName = "BaseConfigDB";
	public static PersonaConfigByRoleGroupId = "/Home/GetConfiguration";
	/*Added for partner Login :  OPS is expecting "Partners~user" for getBasicprofile api */
	public static PartnerDomain = "Partners~";
	public static PartnerDomainIss = "https://sts.windows.net/a5f51bc5-4d47-4954-a546-bafe55e8db16/";
	public static UserProfile = "UserInfo";
	public static UIConfigDB = "UIConfigDB";
	public static UserInfo = "userInfo";
	public static UserClaims = "userClaims";
	public static BaseUrl = "BaseUrl";
	public static FxpBaseUrl = "https://" + window.location.host;
	public static AssetsUrl = "AssetsUrl";
	public static ProfileStateName = "profile";
	public static ErrorStateNames = ["oneprofile.customErrorPage", "unauthorized"];
	public static RequiredMessage = "{0} is required";
	public static CouchBaseLoginContract = "api/Login?dbName=";
	public static FxpSessionKey = "FxpSessionId";
	public static FxpApplaunchedKey = "FxpAppLaunched";
	public static RequestStateName = "REQSTATE";
	public static FxpBreadcrumbStorageKey = "FxpBreadCrumb";
	public static PageTourFeature = "PageTour";
	public static UserPreferencesSettings = "UserPreferences";
	public static FxPAppName = "FxP";
	public static FxPDbName = "FxPV2";
	public static UserPreferencesStorageKey = "{0}-preferences";
	public static PageTourLoggerService = "FxpLoggerService";
	public static FxpAdminData = "AdminTilesData";
	public static SnowRequestTypeSettings = "SnowRequestType";
	public static ESTenanatUrlString = "ESXP";
	public static SalesTenantUrlString = "li360";
	public static XUserClaimsToken = "X-UserClaimsToken";
	public static XTenantId = "X-TenantId";
	public static XResources = "X-Resources";
	public static GraphApiProfileUrl = "myorganization/me?api-version=1.6";
	public static DefaultHelpLink = "Default";
	public static AdalLoginError = "AdalLoginError";
	public static AdalLoginRequest = "AdalLoginRequest";
	public static XUserProfileToken ="X-UserProfileToken";
	public static FlightingStatusHeader ="X-Flighting-Call-Status";
	public static signalRTenant = "FXP";
	public static PIIFilterRegex = `["Bearer\\\\sey[^\\"'\\\\s,]+"]`
	public static ProfileFlyoutConfig = "[{\"experience\":\"r360wbExperience\",\"featureFlag\":\"VERGE__R360wbflag\",\"link\":\"#/myprofile\",\"displayName\":\"Manage Profile\",\"tooltip\":\"Click to manage profile\",\"isEnabled\":false,\"target\":\"_self\",\"popoutIcon\":false},{\"experience\":\"basicProfileExperience\",\"featureFlag\":\"VERGE__R360wbflag\",\"link\":\"#/profile/{userAlias}/basic\",\"displayName\":\"View Full Profile\",\"tooltip\":\"Click to view profile\",\"isEnabled\":false,\"target\":\"_self\",\"popoutIcon\":false}]";
}

export class FxpConstants {
	public static messageType = {
		error: "error",
		warning: "warning",
		info: "info",
		success: "success",
		reload: "reload"
	};

	public static CONST = {
		fxpUserClaimsSession: "fxpUserClaims",
		String: "string"  
	};

	public static StateEvents = {
		OnStart: 1,
		onSuccess: 2,
		onError: 3,
		onInvalid: 4
	}

	public static metricConstants = {
		GetBasicProfileSvcName: "Get Basic Profile Service",
		GetUserClaimsSvcName: "Get User Claims Service",
		FXPApplicationLaunchMetric: "FXP Application Launch Metric",
		FXPApplicationLaunchTimeMetric: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FXPApplicationLaunchMetric`,
		UserClaimsResponseTime: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserClaimsResponseTime`,
		UserClaimsResponseTimeError: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserClaimsResponseTimeError`,
		Status: "Status",
		CurrentTime: "CurrentTime",
		StatusText: "StatusText",
		StartTime: "StartTime",
		EndTime: "EndTime",
		ServiceName: "ServiceName",
		ServiceURL: "ServiceURL",
		UserAgent: "UserAgent",
		UserProfileService: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserProfileService`,
		FxpAppLaunch: "Fxp.AppLaunch",
		SessionId: "SessionId",
		UserUPN: "UserUPN",
		UserBusinessRole: "UserBusinessRole",
		Geography: "Geography",
		BrowserAgent: "BrowserAgent",
		SearchProfileServiceTime: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SearchProfileServiceResponseTime`,
		SearchProfileServiceErrorTime: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SearchProfileServiceResponseTime-Error`,
		AdminPersonalizationUserNavLinksCount: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AdminPersonalizationUserNavLinksCount`,
		FxpLoadDuration: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpLoadDuration`,
		searchProfileSvcName: "Search Profile Service",
		TimeStamp: "TimeStamp",
		L0BusinessProcessName: "L0BusinessProcessName",
		L0Name: "L0Name",
		L0Name_L1Name: "L0Name_L1Name",
		UserRoleGroup: "UserRoleGroup",
		LeftNavigationClickCountbyRoleGroup: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftNavigationClickCountbyRoleGroup`,
		LeftNavigationClickCountbyRole: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftNavigationClickCountbyRole`,
		RequestedUserAlias: "RequestedUserAlias",
		RequestedUserRoleGroupId: "RequestedUserRoleGroupId",
		ScreenRoute: "ScreenRoute",
		FeedbackType: "FeedbackType",
		Action: "Action",
		UserFeedback: "UserFeedback",
		UserFeedbackErrorMessage: "UserFeedbackErrorMessage",
		OBOUserRoleGroupName: "OBOUserRoleGroupName",
		OBOUserBusinessRole: "OBOUserBusinessRole",
		OBOUserUPN: "OBOUserUPN",
		BreadcrumbString: "BreadcrumbString",
		BrowserPageTitle: "BrowserPageTitle",
		LoggedinUserName: "UserName",
		OBOUserName: "OBOUserName",
		OperatingSystem: "OperatingSystem",
		BrowserType: "BrowserType",
		BrowserVersion: "BrowserVersion",
		DeviceType: "DeviceType",
		TotalDuration: "TotalDuration",
		UIRenderDuration: "UIRenderDuration",
		LeftNavPinStatus: "LeftNavPinStatus",
		GetSettingsAPIResponseDuration: "GetSettingsAPIResponseDuration",
		SaveSettingsAPIResponseDuration: "SaveSettingsAPIResponseDuration",
		ModifiedRoleGroupName: "ModifiedRoleGroupName",
		ModifiedRoleGroupID: "ModifiedRoleGroupID",
		ModifiedBusinessRoleName: "ModifiedBusinessRoleName",
		ModifiedBusinessRoleID: "ModifiedBusinessRoleID",
		OBOUserAlias: "OBOUserAlias",
		LeftNavLinksValueBeforeModification: "LeftNavLinksValueBeforeModification",
		LeftNavLinksValueAfterModification: "LeftNavLinksValueAfterModification",
		ErrorUrl: "ErrorUrl",
		GlobalErrorDetail: "ErrorDetails",
		FooterLinkUrl: "FooterLinkUrl",
		FooterLinkName: "FooterLinkName",
		HeaderClickNavigatedStateName: "NavigatedStateName",
		HeaderClickNavigatedStateTemplateURL: "NavigatedStateTemplateURL",
		MiniProfileIconClick: "MiniProfileIconClick",
		ProfileLinkClick: "ProfileLinkClick",
		ChatIconClick: "ChatIconClick",
		MailIconClick: "MailIconClick",
		HelpLinkParent: "HelpLinkParent",
		HelpLinkChild: "HelpLinkChild",
		HelpIconClicked: "HelpIconClicked",
		HelpView: "HelpView",
		ErrorText: "ErrorText",
		UserPorfileServiceEndPoint:"User Porfile Service EndPoint",
		IsLoginRequired: "IsLoginRequired",
		Error: "Error",
		IsCSABlockedUser: "IsCSABlockedUser",
		ModuleBootstrapping: `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ModuleBootstrapping`
	};

	public static OBOConstants =
		{
			OnBehalfOfUserName: "OnBehalfOfUserName",
			OnBehalfOfUserAlias: "OnBehalfOfUserAlias",
			OnBehalfOfUserUpn: "OnBehalfOfUserUpn",
			ActonBehalfMode: "ActonBehalfMode",
			OnBehalfOfUserBusinessRoleId: "OnBehalfOfUserBusinessRoleId",
			OnBehalfOfUserBusinessRole: "OnBehalfOfUserBusinessRole",
			OnBehalfOfUserRoleGroup: "OnBehalfOfUserRoleGroup",
			AdminActOnBehaflOfDuratoin: "AdminActOnBehaflOfDuratoin",
			ActonBehalfofAdminStartTime: "ActonBehalfofAdminStartTime",
			ActonBehalfofAdminEndTime: "ActonBehalfofAdminEndTime"
		};
	public static ActionTypes =
		{
			Add: "AddedItems",
			Save: "Save",
			Submit: "Submit",
			Remove: "RemovedItems",
			Cancel: "Cancel",
			Click: "Click"
		};
	public static keyCodes =
		{
			escapeKey: 27,
			arrowRightKey: 39,
			arrowLeftKey: 37,
			arrowDownKey: 40,
			arrowUpKey: 38,
			enterKey: 13,
			tabKey: 9,
			spaceBar: 32
		};
	public static applicableDevice = {
		desktop: "Desktop",
		mobile: "Mobile",
		all: "All"
	};
	public static BreadcrumbEventType = {
		BreadcrumbLoad: "BreadcrumbLoad",
		BreadcrumbClick: "BreadcrumbClick"
	};
	public static askOps = {
		createLink: "createAskOpsRequest",
		viewLink: "https://microsoft.service-now.com/it/my_items.do?view=open_items"
	};
}

export class PerfMarkers {
	public static FxpLoad = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpLoad`;
	public static GetUserClaims = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetUserClaims`;
	public static GetUserThumbnail = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetUserThumbnail`;
	public static GetBasicProfileWithAdal = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetBasicProfileWithAdal`;
	public static AdalTimeGetBasicProfile = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AdalTimeGetBasicProfile`;
	public static DashboardLoad = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.DashboardLoad`;
	public static LoadRoutes = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LoadRoutes`;
	public static PreDashboardLoad = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PreDashboardLoad`;
	public static SavePersonalizedNavItems = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SavePersonalizedNavItems`;
	public static SaveRoleGroupPersonalizedNavItems = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SaveRoleGroupPersonalizedNavItems`;
	public static GetPersonalizedNavItems = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetPersonalizedNavItems`;
	public static GetMasterLeftNavItems = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetMasterLeftNavItems`;
	public static SendUserFeedback = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SendUserFeedback`;
	public static GetRoleGroupDetails = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetRoleGroupDetails`;

	public static GetLeftNavData = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetLeftNavData`;
	public static GetAdminDataFromServer = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetAdminDataFromServer` ;
	public static GetTenantClaims = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetTenantClaims`;
}

export class RoleGroupInfo {
	public static RoleGroupId = "RoleGroupId";
	public static RoleGroupName = "RoleGroupName";
}

export class TenantInfo {
	public static TenantKey = "TenantKey";
	public static TenantName = "TenantName";
}