import { Injectable } from "@angular/core";

@Injectable()
export class PartnerStateService {

    private _currentStateName: Array<string> = [];

    public get RunningStates(){
        return this._currentStateName;
    }

    public addState(stateName:string){
        this._currentStateName.push(stateName);
    }

    public clear(){
        this._currentStateName = [];
    }

    public contains(stateName:string){
        return this._currentStateName.indexOf(stateName) != -1; 
    }

    public remove(stateName:string){
        this._currentStateName = this._currentStateName.filter(function(item){
            return item !== stateName
        });
    }
}
