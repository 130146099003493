import { ProfileReducer } from '../profile/profile.reducer';
import { Reducer, combineReducers } from 'redux';
import { ClaimsReducer } from '../claims/claims.reducer';
import { User } from './user.state.model';
import { IAction } from 'redux-micro-frontend';
import { Claims } from '../claims/claims.model';
import { ClaimsActionTypes } from '../claims/claims.action.types';
import { ProfileActionTypes } from '../profile/profile.action.types';
import { Profile } from '../profile/profile.model';
var defaultUser: User = {
    Profile: {} as Profile,
    Claims: {} as Claims
}
export const LoggedInUserReducer: Reducer<User, IAction<any>> = (
    state: User = defaultUser,
    action: IAction<any>): User => {
    if (action.type == ClaimsActionTypes.ADD_LOGGED_IN_USER_CLAIMS || action.type == ProfileActionTypes.ADD_LOGGED_IN_USER_PROFILE) {

        return {
            Profile: ProfileReducer(state.Profile, action),
            Claims: ClaimsReducer(state.Claims, action)
        }
    }
    return state;
}
export const CurrentUserReducer: Reducer<User, IAction<any>> = (
    state: User = defaultUser,
    action: IAction<any>): User => {
    if (action.type == ClaimsActionTypes.ADD_CURRENT_USER_CLAIMS || action.type ==ProfileActionTypes.ADD_CURRENT_USER_PROFILE) {

        return {
            Profile: ProfileReducer(state.Profile, action),
            Claims: ClaimsReducer(state.Claims, action)
        }
    }
    return state;
}