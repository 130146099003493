import { Store } from 'redux';
import { GlobalStore } from 'redux-micro-frontend';
import { PlatformReducer } from './platform.reducer';
import { IPlatformState }  from './platform.state.model'
import { ActionLoggerAdapter } from './action.logger.adaptor';
import { ILogger as IFxpLogger } from '../../js/interfaces/ILogger';
export class PlatformStoreFactory {
    
    public static FxpStore: Store<IPlatformState, any>;
    static CreateIfNotExist(fxpLogger: IFxpLogger): Store<IPlatformState, any> {
        if (PlatformStoreFactory.FxpStore !== undefined && PlatformStoreFactory.FxpStore !== null) {
            return PlatformStoreFactory.FxpStore;
        }
        return this.Create(fxpLogger);
    }
    static Create(fxpLogger: IFxpLogger): Store<IPlatformState, any> {
        let actionLoggerAdaptor = fxpLogger !== undefined && fxpLogger !== null 
            ? new ActionLoggerAdapter(fxpLogger)
            : null;
            
        let environment = window["FxpAppSettings"].EnvironmentName;
        let debugMode = environment !== "Production";
        let globalStore = GlobalStore.Get(debugMode, actionLoggerAdaptor);
        if (actionLoggerAdaptor)
            globalStore.SetLogger(actionLoggerAdaptor);
        PlatformStoreFactory.FxpStore = globalStore.CreateStore(GlobalStore.Platform, PlatformReducer, [], [GlobalStore.AllowAll], false, true);
        return PlatformStoreFactory.FxpStore;
    }

    static Get(): Store<IPlatformState, any> {
        if (PlatformStoreFactory.FxpStore !== undefined && PlatformStoreFactory.FxpStore !== null) {
            return PlatformStoreFactory.FxpStore;
        }
        throw new Error("FXP Store hasn't been created");
    }
}