import { StateService } from "@uirouter/core";
import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { FxpEventBroadCastService } from "../../js/services/BroadCastingService";
import { FxpStateTransitionService } from "../../js/services/FxpStateTransitionService";
import { FxpHttpService } from "../../js/services/FxpHttpService";
// import { MsalAuthenticationService } from "../../js/services/MsalAuthenticationService";
import { AdminLandingService } from "../adminTiles/AdminLandingService";
import { DashboardService } from "../../js/services/dashboardService";
import { DataService } from "../../js/services/DataService";
import { FxpAuthorizationService } from "../../js/services/FxpAuthorizationService";
import { FxpBreadcrumbService } from "../../js/services/FxpBreadcrumbService";
import { FxpFeedbackService } from "../../js/services/FxpFeedbackService";
import { FxpMessageService } from "../banner/FxpMessageService";
import { FxpRouteService } from "../../js/services/FxpRouteService";
import { FxpStorageService } from "../../js/services/FxpStorageService";
import { HelpCentralService } from "../../js/services/HelpCentralService";
import { NotificationActionCenter } from "../../js/services/NotificationActionCenter";
import { NotificationService } from "../../js/services/NotificationService";
import { NotificationStore } from "../../js/services/NotificationStore";
import { OBOUserService } from "../../js/services/OBOUserService";
import { PageLoaderService } from "../loader/pageLoaderService";
import { pageTourEventService } from "../../js/services/pageTourEventService";
import { PersonalizationService } from "../../js/services/PersonalizationService";
import { PlannedDownTimeService } from "../systemMessages/PlannedDownTimeService";
import { SystemMessagesService } from "../systemMessages/SystemMessagesService";
import { TimeZoneHelper } from "../../js/services/TimeZoneHelper";
import { UserInfoService } from "../../js/services/UserInfoService";
import { UserClaimsService } from "../../js/services/UserClaimsService";
import { UserProfileService } from "../../js/services/userProfileService";
import { ConFitService } from "@fxp/confitsdk/service/ConfigService";
import { FeatureFlagService } from "@fxp/flightingsdk/service/FeatureFlagServiceProvider";
import { FxpUIData } from "../../js/factory/FxpUIDataFactory";
import { DeviceFactoryProvider as deviceFactoryProvider } from './../../js/utils/devicefactoryService';
import { SettingsServiceProvider as settingsServiceProvider } from './../../js/provider/SettingsServiceProvider';
import { FxpContext as fxpContextService, FxpContext } from './../../js/context/FxpContext'; 
import { FxpPartnerHttpInterceptorHooks } from "./../../js/services/FxpPartnerHttpInterceptorHooks";
import { LazyLoaderService } from "../../js/services/lazyLoaderService";
import { FxpIrisBotService } from "../../js/services/FxpIrisBotService";
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { FxpOCVFeedbackService } from "../../js/services/FxpOCVFeedbackService";
import { FxpSignalRService } from './../../js/services/FxpSignalRClient'; 
import { FxpLoggerService } from "../../js/telemetry/fxpLogger";
import { AdalLoginHelperService } from "../../js/services/AdalLoginHelperService";
import { MsalAuthenticationService } from "../../js/services/MsalAuthenticationService";

let fxpComponentRegistrationService = null;
let partnerAppRegistrationService = null;
let resiliency = null;


// Inject FxpConfigurationService to be used by Angular Component inside Fxp

export function FxpConfigurationServiceFactory(i: any) {
    return i.get('FxpConfigurationService');
}

export let FxpConfigurationServiceProvider = {
    provide: FxpConfigurationService,
    useFactory: FxpConfigurationServiceFactory,
    deps: ['$injector']
};

// export function MsalAuthenticationServiceFactory(i: any) {
//     return i.get('MsalAuthenticationService');
// }

// export let MsalAuthenticationServiceProvider = {
//     provide: MsalAuthenticationService,
//     useFactory: MsalAuthenticationServiceFactory,
//     deps: ['$injector']
// };

export function FxpEventBroadCastServiceFactory(i: any) {
    return i.get('FxpEventBroadCastService');
}
export let FxpEventBroadCastServiceProvider = {
    provide: FxpEventBroadCastService,
    useFactory: FxpEventBroadCastServiceFactory,
    deps: ['$injector']
}

export function FxpStateTransitionServiceFactory(i: any) {
    return i.get('FxpStateTransitionService');
}
export let FxpStateTransitionServiceProvider = {
    provide: FxpStateTransitionService,
    useFactory: FxpStateTransitionServiceFactory,
    deps: ['$injector']
}

export function FxpHttptServiceFactory(i: any) {
    return i.get('FxpHttpService');
}
export let FxpHttpServiceProvider = {
    provide: FxpHttpService,
    useFactory: FxpHttptServiceFactory,
    deps: ['$injector']
}

export function FxpSignalRServiceFactory(i: any) {
    return i.get('FxpSignalRService');
}
export let FxpSignalRServiceProvider = {
    provide: FxpSignalRService,
    useFactory: FxpSignalRServiceFactory,
    deps: ['$injector']
}

export function MsalAuthenticationServiceFactory(i: any) {
    return i.get('MsalAuthenticationService');
}

export let MsalAuthenticationServiceProvider = {
    provide: MsalAuthenticationService,
    useFactory: MsalAuthenticationServiceFactory,
    deps: ['$injector']
};

// Inject AdalLoginHelperService to be used by Angular Component inside Fxp

export function AdalLoginHelperServiceFactory(i: any) {
    return i.get('AdalLoginHelperService');
}

export let AdalLoginHelperServiceProvider = {
    provide: AdalLoginHelperService,
    useFactory: AdalLoginHelperServiceFactory,
    deps: ['$injector', 'MsalAuthenticationService']
};

export function AdminLandingServiceFactory(i: any) {
    return i.get('AdminLandingService');
}

export let AdminLandingServiceProvider = {
    provide: AdminLandingService,
    useFactory: AdminLandingServiceFactory,
    deps: ['$injector']
};

// Inject DashboardService to be used by Angular Component inside Fxp

export function DashboardServiceFactory(i: any) {
    return i.get('DashboardService');
}

export let DashboardServiceProvider = {
    provide: DashboardService,
    useFactory: DashboardServiceFactory,
    deps: ['$injector']
};

// Inject DataService to be used by Angular Component inside Fxp

export function DataServiceFactory(i: any) {
    return i.get('DataService');
}

export let DataServiceProvider = {
    provide: DataService,
    useFactory: DataServiceFactory,
    deps: ['$injector']
};

// Inject FxpAuthorizationService to be used by Angular Component inside Fxp

export function FxpAuthorizationServiceFactory(i: any) {
    return i.get('FxpAuthorizationService');
}

export let FxpAuthorizationServiceProvider = {
    provide: FxpAuthorizationService,
    useFactory: FxpAuthorizationServiceFactory,
    deps: ['$injector']
};

// Inject FxpBreadcrumbService to be used by Angular Component inside Fxp

export function FxpBreadcrumbServiceFactory(i: any) {
    return i.get('FxpBreadcrumbService');
}

export let FxpBreadcrumbServiceProvider = {
    provide: FxpBreadcrumbService,
    useFactory: FxpBreadcrumbServiceFactory,
    deps: ['$injector']
};

// Inject FxpFeedbackService to be used by Angular Component inside Fxp

export function FxpFeedbackServiceFactory(i: any) {
    return i.get('FxpFeedbackService');
}

export let FxpFeedbackServiceProvider = {
    provide: FxpFeedbackService,
    useFactory: FxpFeedbackServiceFactory,
    deps: ['$injector']
};

// Inject FxpMessageService to be used by Angular Component inside Fxp

export function FxpMessageServiceFactory(i: any) {
    return i.get('FxpMessageService');
}

export let FxpMessageServiceProvider = {
    provide: FxpMessageService,
    useFactory: FxpMessageServiceFactory,
    deps: ['$injector']
};

// Inject FxpRouteService to be used by Angular Component inside Fxp

export function FxpRouteServiceFactory(i: any) {
    return i.get('FxpRouteService');
}

export let FxpRouteServiceProvider = {
    provide: FxpRouteService,
    useFactory: FxpRouteServiceFactory,
    deps: ['$injector']
};

// Inject FxpStorageService to be used by Angular Component inside Fxp

export function FxpStorageServiceFactory(i: any) {
    return i.get('FxpStorageService');
}

export let FxpStorageServiceProvider = {
    provide: FxpStorageService,
    useFactory: FxpStorageServiceFactory,
    deps: ['$injector']
};

// Inject FxpConfigurationService to be used by Angular Component inside Fxp


// Inject HelpCentralService to be used by Angular Component inside Fxp

export function HelpCentralServiceFactory(i: any) {
    return i.get('HelpCentralService');
}

export let HelpCentralServiceProvider = {
    provide: HelpCentralService,
    useFactory: HelpCentralServiceFactory,
    deps: ['$injector']
};

// Inject NotificationActionCenter to be used by Angular Component inside Fxp

export function NotificationActionCenterFactory(i: any) {
    return i.get('NotificationActionCenter');
}

export let NotificationActionCenterProvider = {
    provide: NotificationActionCenter,
    useFactory: NotificationActionCenterFactory,
    deps: ['$injector']
};

// Inject NotificationService to be used by Angular Component inside Fxp

export function NotificationServiceFactory(i: any) {
    return i.get('NotificationService');
}

export let NotificationServiceProvider = {
    provide: NotificationService,
    useFactory: NotificationServiceFactory,
    deps: ['$injector']
};


// Inject NotificationStore to be used by Angular Component inside Fxp

export function NotificationStoreFactory(i: any) {
    return i.get('NotificationStore');
}

export let NotificationStoreProvider = {
    provide: NotificationStore,
    useFactory: NotificationStoreFactory,
    deps: ['$injector']
};

// Inject OBOUserService to be used by Angular Component inside Fxp

export function OBOUserServiceFactory(i: any) {
    return i.get('OBOUserService');
}

export let OBOUserServiceProvider = {
    provide: OBOUserService,
    useFactory: OBOUserServiceFactory,
    deps: ['$injector']
};

// Inject PageLoaderService to be used by Angular Component inside Fxp

export function PageLoaderServiceFactory(i: any) {
    return i.get('PageLoaderService');
}

export let PageLoaderServiceProvider = {
    provide: PageLoaderService,
    useFactory: PageLoaderServiceFactory,
    deps: ['$injector']
};

// Inject pageTourEventService to be used by Angular Component inside Fxp

export function pageTourEventServiceFactory(i: any) {
    return i.get('pageTourEventService');
}

export let pageTourEventServiceProvider = {
    provide: pageTourEventService,
    useFactory: pageTourEventServiceFactory,
    deps: ['$injector']
};

// Inject PersonalizationService to be used by Angular Component inside Fxp

export function PersonalizationServiceFactory(i: any) {
    return i.get('PersonalizationService');
}

export let PersonalizationServiceProvider = {
    provide: PersonalizationService,
    useFactory: PersonalizationServiceFactory,
    deps: ['$injector']
};

// Inject PlannedDownTimeService to be used by Angular Component inside Fxp

export function PlannedDownTimeServiceFactory(i: any) {
    return i.get('PlannedDownTimeService');
}

export let PlannedDownTimeServiceProvider = {
    provide: PlannedDownTimeService,
    useFactory: PlannedDownTimeServiceFactory,
    deps: ['$injector']
};

// Inject SystemMessagesService to be used by Angular Component inside Fxp

export function SystemMessagesServiceFactory(i: any) {
    return i.get('SystemMessagesService');
}

export let SystemMessagesServiceProvider = {
    provide: SystemMessagesService,
    useFactory: SystemMessagesServiceFactory,
    deps: ['$injector']
};

// Inject FxpConfigurationService to be used by Angular Component inside Fxp

export function TimeZoneHelperFactory(i: any) {
    return i.get('TimeZoneHelper');
}

export let TimeZoneHelperProvider = {
    provide: TimeZoneHelper,
    useFactory: TimeZoneHelperFactory,
    deps: ['$injector']
};

// Inject UserInfoService to be used by Angular Component inside Fxp

export function UserInfoServiceFactory(i: any) {
    return i.get('UserInfoService');
}

export let UserInfoServiceProvider = {
    provide: UserInfoService,
    useFactory: UserInfoServiceFactory,
    deps: ['$injector']
};

// Inject UserClaimsService to be used by Angular Component inside Fxp

export function UserClaimsServiceFactory(i: any) {
    return i.get('UserClaimsService');
}

export let UserClaimsServiceProvider = {
    provide: UserClaimsService,
    useFactory: UserClaimsServiceFactory,
    deps: ['$injector']
};

// Inject UserProfileService to be used by Angular Component inside Fxp

export function UserProfileServiceFactory(i: any) {
    return i.get('UserProfileService');
}

export let UserProfileServiceProvider = {
    provide: UserProfileService,
    useFactory: UserProfileServiceFactory,
    deps: ['$injector']
};

export function FxpLoggerServiceFactory(i: any) {
    return i.get('FxpLoggerService');
}

export let FxpLoggerServiceProvider = {
    provide: FxpLoggerService,
    useFactory: FxpLoggerServiceFactory,
    deps: ['$injector']
};


export function FxpConfitServiceFactory(i: any) {
    return i.get('ConFitService');
}

export let FxpConfitServiceProvider = {
    provide: ConFitService,
    useFactory: FxpConfitServiceFactory,
    deps: ['$injector']
};


export function FxpFeatureFlagServiceFactory(i: any) {
    return i.get('FeatureFlagService');
}

export let FxpFeatureFlagServiceProvider = {
    provide: FeatureFlagService,
    useFactory: FxpFeatureFlagServiceFactory,
    deps: ['$injector']
};

export function FxpUIDataFactory(i: any) {
    return i.get('FxpUIData');
}

export let FxpUIDataProvider = {
    provide: FxpUIData,
    useFactory: FxpUIDataFactory,
    deps: ['$injector']
};

export function DeviceFactoryFactory(i: any) {
    return i.get('DeviceFactory');
}

export let DeviceFactoryProvider = {
    provide: deviceFactoryProvider,
    useFactory: DeviceFactoryFactory,
    deps: ['$injector']
};

export function SettingsServiceFactory(i: any) {
    return i.get('SettingsService');
}

export let SettingsServiceProvider = {
    provide: settingsServiceProvider,
    useFactory: SettingsServiceFactory,
    deps: ['$injector']
};


export function FxpStateServiceFactory(i: any) {
    return i.get('$state');
}

export let FxpStateServiceProvider = {
    provide: StateService,
    useFactory: FxpStateServiceFactory,
    deps: ['$injector']
};

export function FxpPartnerHttpInterceptorHooksFactory(i: any) {
    return i.get('FxpPartnerHttpInterceptorHooks');
}

export let FxpHttpInterceptorHooksProvider = {
    provide: FxpPartnerHttpInterceptorHooks,
    useFactory: FxpPartnerHttpInterceptorHooksFactory,
    deps: ['$injector']
};

export function FxpContextServiceFactory(i: any) {
    return i.get('FxpContextService');
}

export let FxpContextServiceProvider = {
    provide: FxpContext,
    useFactory: FxpContextServiceFactory,
    deps: ['$injector']
};


export function LazyLoaderServiceFactory(i: any) {
    return i.get('LazyLoaderService');
}

export let LazyLoaderServiceProvider = {
    provide: LazyLoaderService,
    useFactory: LazyLoaderServiceFactory,
    deps: ['$injector']
};

export function FxpIrisBotServiceFactory(i: any) {
    return i.get('FxpIrisBotService');
}

export let FxpIrisBotServiceProvider = {
    provide: FxpIrisBotService,
    useFactory: FxpIrisBotServiceFactory,
    deps: ['$injector']
};

export function FxpGlobalStoreServiceFactory(i: any) {
    return i.get('FxpGlobalStoreService');
}

export let FxpGlobalStoreServiceProvider = {
    provide: FxpGlobalStoreService,
    useFactory: FxpGlobalStoreServiceFactory,
    deps: ['$injector']
};


export function FxpOCVFeedbackServiceFactory(i: any) {
    return i.get('FxpOCVFeedbackService');
}

export let FxpOCVFeedbackServiceProvider = {
    provide: FxpOCVFeedbackService,
    useFactory: FxpOCVFeedbackServiceFactory,
    deps: ['$injector']
};