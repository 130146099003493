import { CommonUtils } from "../utils/CommonUtils";
import { IUserContext } from "../interfaces/IUserContext";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { Profile } from "../../app/profile/profile.model";
import { Claims } from "../../app/claims/claims.model";
/**
   * A service to connect to user profile service to fetch the detaisl of user info and user claims related to partner apps
   * @class Fxp.Services.UserInfoService
   * @classdesc A service to connect to user profile service to fetch the user profile details and claims 
   * @example <caption> Example to create an instance of user profile service</caption>         
   *  //Initializing User Profile Service
   *  angular.module('FxPApp').service('UserInfoService', [UserInfoService]);    
   */
export class UserInfoService implements IFxPService {
	private currentUser: string;
	private loggedInUser: string;
	private loggedInUserOID: string;
	private currentUserOID: string;
	private currentUserUpn: string;
	private loggedInUserUpn: string;
	private OBOUserSession: any;
	private isOboActive: boolean;
	private globalStoreService: FxpGlobalStoreService;
	constructor(globalStoreService: FxpGlobalStoreService) {
		this.currentUser = "";
		this.loggedInUser = "";
		this.currentUserUpn = "";
		this.loggedInUserUpn = "";
		this.loggedInUserOID="";
		this.currentUserOID="";
		this.globalStoreService = globalStoreService;
	}
	getCurrentUserProfile():Profile{
		return this.globalStoreService.GetPlatformState().CurrentUser.Profile;
	}
	getCurrentUserClaims():Claims{
		return this.globalStoreService.GetPlatformState().CurrentUser.Claims;
	}
	getLoggedInUserProfile():Profile{
		return this.globalStoreService.GetPlatformState().LoggedInUser.Profile;
	}
	public 	getLoggedInUserOID():string{
		return this.loggedInUserOID;
	}
	public 	getCurrentUserOID():string{
		return this.currentUserOID;
	}
	public 	setLoggedInUserOID(aadOID:string){
		 this.loggedInUserOID = aadOID;
	}
	public 	setCurrentUserOID(aadOID:string){
		this.currentUserOID = aadOID;
	}
	public getCurrentUser(): string {
		return this.currentUser;
	}

	public setCurrentUser(alias: string) {
		this.currentUser = alias ? alias.toLowerCase() : alias;
	}

	public getLoggedInUser(): string {
		return this.loggedInUser;
	}

	public setLoggedInUser(alias: string) {
		this.loggedInUser = alias;
	}

	public getCurrentUserUpn(): string {
		let userClaims = this.getCurrentUserContext().userClaims;
		if (userClaims) {
			let upn = userClaims.upn;
			return upn;
		}
		else return this.getCurrentUser();
	}

	public setCurrentUserUpn(upn: string) {
		this.currentUserUpn = upn;
		this.setUpAjaxCallsHeaders();

	}

	public getAADObjectIDFromAlias(alias: string): string {
		return alias == this.getCurrentUser() ? this.currentUserOID : this.loggedInUserOID
	}

	public setLoggedInUserUpn(upn: string) {
		this.loggedInUserUpn = upn;
	}

	public getLoggedInUserUpn() {
		return this.loggedInUserUpn;
	}

	public setOBOUserSessionInfo(value: string) {
		this.OBOUserSession = value;
	}

	public getCurrentUserData() {
		var userInfo;
		var self = this;

		//remove sessionStorage and keep the data only in state for loggedinUser
		if (!self.isActingOnBehalfOf()) {

			var profile = self.getLoggedInUserProfile();
			if (profile && !CommonUtils.isNullOrEmpty(profile))
				userInfo = JSON.stringify(profile);


		}
		else {
			userInfo = self.OBOUserSession;
		}
		if (userInfo) {
			userInfo = JSON.parse(userInfo);
			return userInfo;
		}
	}

	public isActingOnBehalfOf() {
		return this.isOboActive;
	}

	public setActingOnBehalfOfFlag(value: boolean) {
		this.isOboActive = value;
	}

	private setUpAjaxCallsHeaders() {
		if (this.isActingOnBehalfOf()) {
			let userClaims = this.getCurrentUserContext().userClaims;
			let upn;
			let aadObjectID;
			if (!userClaims) {
				upn = this.getCurrentUser();
				aadObjectID = this.getAADObjectIDFromAlias(this.getCurrentUser());
			}
			else {
				upn = userClaims.upn;
				aadObjectID = userClaims.aadObjectId;
			}
			$.ajaxSetup({
				headers: { "X-ActonBehalfMode": 'true', "X-OnBehalfOfUser": upn, "X-OnBehalfOfUserObjectId": aadObjectID }
			});
		}
		else {
			$.ajaxSetup({
				headers: { "X-ActonBehalfMode": 'false', "X-OnBehalfOfUser": '', "X-OnBehalfOfUserObjectId": '' }
			});
		}
	}

	public getCurrentUserContext(): IUserContext {
		var self = this;
		let userData = null;
		let userClaimsData = null;
		var profile = self.globalStoreService.GetPlatformState().CurrentUser.Profile;

		if (!CommonUtils.isNullOrEmpty(profile)) {
			userData = profile
		}
		var claims = self.globalStoreService.GetPlatformState().CurrentUser.Claims;
		if (!CommonUtils.isNullOrEmpty(claims)) {
			userClaimsData = claims;
		}

		let userContext: IUserContext = {
			userInfo: userData,
			userClaims: userClaimsData
		};
		return userContext;
	}
}