import { CommonUtils } from "../utils/CommonUtils";

export class LogPropertyBag {
	private propBagInternal: any = {};
	constructor() {
	}

	addToBag(key: string, value: string): void {
		if(CommonUtils.isNullOrEmpty(value)){
			this.propBagInternal[key] = value;
		}
		else if(CommonUtils.isObject(value)){
			let stringVal = CommonUtils.objectToString(value);
			if(!CommonUtils.isNullOrEmpty(stringVal)){
				this.propBagInternal[key] = CommonUtils.removePII(stringVal);
			}
		}
		else {
			this.propBagInternal[key] = CommonUtils.removePII(String(value));
		}
	}
	getItems(): any {
		return this.propBagInternal;
	}
	addRange(propertyBag: LogPropertyBag) {
		var properties = propertyBag.getItems();

		for (var property in properties) {
			this.addToBag(property, properties[property]);
		}
	}
	removeFromBag(key: string) {
		delete this.propBagInternal[key];
	}
	getValue(key: string){
		return this.propBagInternal[key];
	}

}
