import { Compiler, NgModuleRef, CompilerFactory, NgModuleFactory } from "@angular/core";
import { AppModule } from "./modules/app.module";
import { PartnerContainerComponent } from "./components/partnerContainer-component/partnerContainer-component";

export class FxpLazyLoader {
    static _partnerModules:{};
    static _compiler: Compiler;
    static _fxprootModule: NgModuleRef<AppModule>;
    static _compilerFactory: CompilerFactory;

    static setRootModuleReference(parentModuleRef: NgModuleRef<AppModule>){
        
        this._fxprootModule = parentModuleRef;
    }
    static setCompilerFactory(compilerFactory: CompilerFactory){
        this._compilerFactory = compilerFactory;
        if (!this._compiler) {
            this._compiler = this._compilerFactory.createCompiler([{useJit:true}]);
        }
    }

    static compileAndLoadModule(jitModuleRef):Promise<any>{
        let self = this;
       
        return new Promise(function(resolve, reject){
            self._compiler.compileModuleAndAllComponentsAsync(jitModuleRef).then((factory) => {
                let partnerModule = factory.ngModuleFactory.create(self._fxprootModule.injector);
                
                resolve(partnerModule);
            })
            .catch(function(e){
                reject(e);
            });
            
        });
    }

    static instantiateModule(aotModuleFactoryRef: any):any{
        return aotModuleFactoryRef.create(this._fxprootModule.injector);
    }
    
    
}
