import { NgModule } from '@angular/core';
import { PageTourLauncherComponent } from "./pageTour.component";

@NgModule ({
    declarations: [PageTourLauncherComponent],
    entryComponents: [PageTourLauncherComponent],
    providers: []
})



export class FxpPageTourModule {}
