import { ComponentType, ActionType, FeatureUsageEventV2, EventName, ActionStatus, TaskStatus, ItEventType } from '@microsoftit/telemetry-extensions-npm'
export class FeatureUsageEvent extends FeatureUsageEventV2 {
    constructor(featureName: string, actionType: ActionType, actionName: string, eventName: EventName, componentType: ComponentType) {
        super(featureName, actionType, actionName, eventName, componentType);
    }
   
    public getTaskName(): string {
        return this.TaskName;
    }
    public setTaskName(value: string) {
        this.TaskName = value;
    }
    public getTaskStatus(): TaskStatus {
        return this.TaskStatus;
    }
    public setTaskStatus(value: TaskStatus) {
        this.TaskStatus = value;
    }
    public getCapabilityType(): string {
        return this.CapabilityType;
    }
    public setCapabilityType(value: string) {
        this.CapabilityType = value;
    }
    public getEmbeddedExpereinceName(): string {
        return this.EmbeddedExpereinceName;
    }
    public setEmbeddedExpereinceName(value: string) {
        this.EmbeddedExpereinceName = value;
    }
    public setIsEmbeddedExperience(value: boolean) {
        this.IsEmbeddedExperience = value;
    }
    public getIsEmbeddedExperience(): boolean {
        return this.IsEmbeddedExperience;
    }
    public getEventName(): EventName {
        return this.EventName;
    }
    public getEventType(): ItEventType {
        return this.EventType;
    }
    public setEventType(value: ItEventType) {
        this.EventType = value;
    }
    public getDuration(): number {
        return this.Duration;
    }
    public setDuration(value: number) {
        this.Duration = value;
    }
    public getFeatureName(): string {
        return this.FeatureName;
    }
    public getSubFeatureName(): string {
        return this.SubFeatureName;
    }
    public setSubFeatureName(value: string) {
        this.SubFeatureName = value;
    }
    public getActionName(): string {
        return this.ActionName;
    }
    public getActionType(): ActionType {
        return this.ActionType;
    }
    public setExperimentationConfigId(value: string) {
        this.ExperimentationConfigId = value;
    }
    public getExperimentationConfigId(): string {
        return this.ExperimentationConfigId;
    }
    public setExperimentationConfigName(value: string) {
        this.ExperimentationConfigName = value;
    }
    public getExperimentationConfigName(): string {
        return this.ExperimentationConfigName;
    }
    public setExperimentationConfigResult(value: boolean) {
        this.ExperimentationConfigResult = value;
    }
    public getExperimentationConfigResult(): boolean {
        return this.ExperimentationConfigResult;
    }
    public getTemplateType(): string {
        return this.TemplateType;
    }
    public getComponentType(): ComponentType {
        return this.ComponentType;
    }
    public getUserRoleName(): string {
        return this.UserRoleName;
    }
    public setUserRoleName(value: string) {
        this.UserRoleName = value;
    }
    public getStartDateTime(): Date {
        return this.StartDateTime;
    }
    public setStartDateTime(value: Date) {
        this.StartDateTime = value;
    }
    public getEndDateTime(): Date {
        return this.EndDateTime;
    }
    public setEndDateTime(value: Date) {
        this.EndDateTime = value;
    }
    public getComponentUri(): string {
        return this.ComponentUri;
    }
    public setComponentUri(value: string) {
        this.ComponentUri = value;
    }
    public getEntityId(): string {
        return this.EntityId;
    }
    public setEntityId(value: string) {
        this.EntityId = value;
    }
    public getActionStatus() {
        return this.ActionStatus;
    }
    public setActionStatus(status: ActionStatus) {
        this.ActionStatus = status;
    }
}