import { AccountInfo, AuthenticationResult, BrowserAuthError, Configuration, EventType, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest, ServerError, SilentRequest } from "@azure/msal-browser";
import { IRootScope } from "../interfaces/IRootScope";
import { CommonUtils } from "../utils/CommonUtils";
import { PartnerAppRegistrationService } from "./PartnerAppRegistrationService";
import { BrowserConstants } from "@azure/msal-browser/dist/utils/BrowserConstants";

/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */

/**
   * A service keeping the generic methods used in the fxp application and for multiple adal requests
   * @class Fxp.Services.MsalAuthenticationService
   * @classdesc A service used for generic methods in the fxp application and adal simultaneous cors requests
   * @example <caption> Example to create an instance of MsalAuthenticationService</caption>
   *  //Initializing MsalAuthenticationService
   *  angular.module('FxPApp').controller('AppController', ['MsalAuthenticationService', AppController]);
   *  function AppController(MsalAuthenticationService){ MsalAuthenticationService.getJsonData(path, callbackfunc); }
   */
export class MsalAuthenticationService {

    private publicClientApplication: PublicClientApplication = null;
    private anonymousEndpointList = [];
    constructor(config: Configuration) {
        if (!this.publicClientApplication) {
            this.publicClientApplication = new PublicClientApplication(config);
        }

        var FxpAppSettings = FxpAppSettings || window["FxpAppSettings"] || {};
        var anonEndpoints = FxpAppSettings.AuthExcludedDomains;
        if (anonEndpoints) {
            this.anonymousEndpointList = anonEndpoints.split(';');
        }
    }

    registerEndPoint(endpoint: string, applicationId: string) {
        PartnerAppRegistrationService.registerEndpoints(endpoint, applicationId);
    }


    initializeAuthentication(configuration: Configuration) {
        if (!this.publicClientApplication)
            this.publicClientApplication = new PublicClientApplication(configuration);
    }

    /**
   * Checks for whether the adal service request is in progress or not and returns boolean
   * @method Fxp.Utils.Services.MsalAuthenticationService.accessTokenRequestInProgress
   * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
   * @example <caption> Example to invoke accessTokenRequestInProgress</caption>
   *  MsalAuthenticationService.accessTokenRequestInProgress('http://oneprofiledevapi.azurewebsites.net');
   */
    accessTokenRequestInProgress(endpoint): boolean {
        return false;
    }

    async login($rootScope: IRootScope) {

        const accounts = this.publicClientApplication.getAllAccounts();
        if (accounts.length > 0) {
            this.publicClientApplication.setActiveAccount(accounts[0]);
        }

        const callbackId = this.publicClientApplication.addEventCallback((message: any) => {
            // Update UI or interact with EventMessage here
            if (message.eventType === EventType.LOGIN_SUCCESS) {
                const payload: any = message.payload;
                this.publicClientApplication.setActiveAccount(payload?.account as AccountInfo);
            } else if (message.eventType === EventType.LOGIN_FAILURE) {
                console.log("MsalError: " + message.error);
                $rootScope.$broadcast('msal:loginFailure', { errorDesc: message.error, err: message.eventType});
            }
            else if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                console.log("MsalError: " + message.error);
                $rootScope.$broadcast('msal:acquireTokenFailure', message.error, message.eventType, "", message.error.errorDesc.errorCode);
            }
        });

        var localPublicClientApplication = this.publicClientApplication;
        if (accounts.length === 0) {
            await this.publicClientApplication
                .handleRedirectPromise()
                .then(async (loginResponse: AuthenticationResult | null) => {
                    if (loginResponse !== null) {
                        const account = localPublicClientApplication.getActiveAccount();
                        if (!account) {
                            // redirect anonymous user to login page
                            await localPublicClientApplication.loginRedirect();
                        }
                        $rootScope.$broadcast('msal:loginSuccess', loginResponse.idToken);
                    }
                    else {
                        await localPublicClientApplication.loginRedirect();
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                    throw (error);
                });
        }
        else {
            var silentRequest = {
                scopes: ["User.Read"],
                account: accounts[0],
                forceRefresh: false
            };

            var request = {
                scopes: ["User.Read"],
                loginHint: accounts[0].username // For v1 endpoints, use upn from idToken claims
            };

            const tokenResponse = await this.publicClientApplication.acquireTokenSilent(silentRequest).catch(error => {
                console.log("MsalError: Login Error");
                $rootScope.$broadcast('msal:acquireTokenFailure', { errorDesc: "Login Error msal:acquireTokenFailure", err: error });
                $rootScope.$broadcast('msal:loginFailure', { errorDesc: "Login Error due to Refresh Token msal:loginFailure", err: "Login Error due to Refresh Token msal:loginFailure" });
                if (error.errorCode === BrowserConstants.INVALID_GRANT_ERROR) {
                    // Refresh token expired, prompt for re-authentication
                    localStorage.clear();
                    $rootScope.$broadcast('msal:loginFailure', { errorDesc: "Session expired, please re-authenticate", err: error });
                    $rootScope.$broadcast('msal:acquireTokenFailure', { errorDesc: "Session expired, please re-authenticate 1", err: error });
                    return this.publicClientApplication.loginRedirect(); // Redirect for re-authentication
                }
                const isServerError = error instanceof ServerError;
                const isInteractionRequiredError = error instanceof InteractionRequiredAuthError;
                const isRefreshTokenError = error instanceof BrowserAuthError;


                if (isInteractionRequiredError) {
                    // fallback to interaction when silent call fails
                    localStorage.clear();
                    return this.publicClientApplication.acquireTokenRedirect(request)
                }
                else {
                    if (isRefreshTokenError) {
                        localStorage.clear();
                        window.location.reload();
                    }
                    else {
                        const isInvalidGrantError = (error.errorCode === BrowserConstants.INVALID_GRANT_ERROR);
                        if (isServerError && isInvalidGrantError && !isInteractionRequiredError) {
                            console.log("MsalError: Login Error due to Refresh Token");
                            $rootScope.$broadcast('msal:loginFailure', { errorDesc: "Login Error due to Refresh Token", err: "Login Error due to Refresh Token" });
                            localStorage.clear();
                            window.location.reload();
                        }
                        else {
                            const isMonitorTimeoutError = (error.errorCode === "monitor_window_timeout");
                            if (isMonitorTimeoutError) {
                                console.log("MsalError: Login Error due to Refresh token on Iframe Timeout");
                                $rootScope.$broadcast('msal:loginFailure', { errorDesc: "Token acquisition in iframe failed due to timeout", err: "Token acquisition in iframe failed due to timeout" });
                                localStorage.clear();
                                window.location.reload();
                            }
                        }
                    }
                }
            })
            if (tokenResponse) {
                $rootScope.$broadcast('msal:loginSuccess', tokenResponse.idToken);
            }
            // const idToken = (await this.getTokenRedirect(null, this.publicClientApplication.getActiveAccount()));
            // if (idToken) {
            //     $rootScope.$broadcast('msal:loginSuccess', idToken);
            // }
            // else {

            // }

        }
    }

    /**
    * Returns cached token for a given endpoint
    * @method Fxp.Utils.Services.MsalAuthenticationService.getCachedToken
    * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
    * @example <caption> Example to invoke getCachedToken</caption>
    *  MsalAuthenticationService.getCachedToken('http://oneprofiledevapi.azurewebsites.net');
    */
    async getCachedToken(endpoint) {
        return await this.getTokenRedirect(endpoint);
    }

    /**
    * To kick off token acquisition manually on special cases
    * @method Fxp.Utils.Services.MsalAuthenticationService.acquireToken
    * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
    * @example <caption> Example to invoke acquireToken</caption>
    *  MsalAuthenticationService.acquireToken('http://oneprofiledevapi.azurewebsites.net');
    */
    async acquireToken(endpoint) {
        return await (await this.getTokenRedirectAsync(endpoint)).accessToken;
    }

    acquireTokenAsPromise(endpoint) {
        return this.getTokenRedirectAsync(endpoint);
    }

    /**
    * Checks for whether the token retrieval is started for the endpoint provided
    * @method Fxp.Utils.Services.MsalAuthenticationService.isTokenRetrievalStarted
    * @param {Resource} resource an endpoint which is passed to check for it in the logs entry.
    * @example <caption> Example to invoke isTokenRetrievalStarted</caption>
    *  MsalAuthenticationService.isTokenRetrievalStarted('https://microsoft.onmicrosoft.com/FXPCouchBaseAPI');
    */
    isTokenRetrievalStarted(resource): boolean {
        return false;
    }

    getAccount(): AccountInfo {
        return this.publicClientApplication.getActiveAccount();
    }

    private async getTokenRedirect(
        endpoint: string, account: AccountInfo = null
    ): Promise<string | null> {
        try {
            var response = await this.getTokenRedirectAsync(endpoint, account);
            return response.accessToken;
        } catch (error) {
            console.error(error);
            console.log("silent token acquisition fails.");
            if (error instanceof InteractionRequiredAuthError) {
                console.log("acquiring token using redirect");
                this.publicClientApplication.loginRedirect();
            } else {
                console.error(error);
            }
        }
    }

    async getTokenForAppIdAsync(appId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            var silentRequest = { scopes: [appId + "/.default"] };
            var redirectRequest: RedirectRequest = { scopes: ["User.Read"] };
            redirectRequest.account = this.publicClientApplication.getActiveAccount();
            try {
                this.publicClientApplication.acquireTokenSilent(
                    silentRequest
                ).then((response: AuthenticationResult) => resolve(response.accessToken));
            } catch (e) {
                console.log("silent token acquisition fails.");
                if (e instanceof InteractionRequiredAuthError) {
                    console.log("acquiring token using redirect");
                    this.publicClientApplication.acquireTokenRedirect(redirectRequest);
                } else {
                    console.error(e);
                    reject("error getting token: " + e);
                }
            }
        })
    }

    private getTokenRedirectAsync(
        endpoint: string, account: AccountInfo = null
    ): Promise<AuthenticationResult> {
        var silentRequest: SilentRequest = { scopes: ["User.Read"] };
        var redirectRequest: RedirectRequest = { scopes: ["User.Read"] };
        if (account) {
            silentRequest = { scopes: ["User.Read"] };
            redirectRequest = { scopes: ["User.Read"] };
            redirectRequest.account = account;
        }
        else if (endpoint) {
            var authExceptionEndpoints = PartnerAppRegistrationService.addAuthenticationExceptionEndpoints();
            this.anonymousEndpointList = this.anonymousEndpointList.concat(authExceptionEndpoints);
            if (this.anonymousEndpointList) {
                for (var i = 0; i < this.anonymousEndpointList.length; i++) {
                    if (endpoint.indexOf(this.anonymousEndpointList[i]) > -1) {
                        return Promise.resolve({ accessToken: '' } as AuthenticationResult);

                    }
                }
            }

            var resource = this.getResourceForEndpoint(endpoint);
            if (resource) {
                silentRequest = { scopes: [resource + "/.default"] };
                redirectRequest = { scopes: ["User.Read"], redirectStartPage: window.location.href };
                redirectRequest.account = this.publicClientApplication.getActiveAccount();
            }
        }
        try {
            return this.publicClientApplication.acquireTokenSilent(
                silentRequest
            );
        } catch (e) {
            console.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                console.log("acquiring token using redirect");
                this.publicClientApplication.acquireTokenRedirect(redirectRequest);
            } else {
                console.error(e);
            }
        }

        return new Promise((resolve, reject) => reject('No resource found for the Request'));
    }

    private getResourceForEndpoint(endpoint) {

        var config = window["ModelConfiguration"];
        var FxpAppSettings = FxpAppSettings || window["FxpAppSettings"] || {};
        var partnerEnpoints = PartnerAppRegistrationService.getRegisterEndpoints();
        var endpoints = JSON.parse(JSON.stringify(eval("(" + "{" + config.Endpoints + "}" + ")"))); // CodeQL [SM04509] Intent is to set a config value

        endpoints = CommonUtils.jsonConcat(endpoints, partnerEnpoints);
        window._msalEndpoints = endpoints;
        var clientId = FxpAppSettings.FxpAppClientId;
        // var clientId = "853531f8-add1-44d2-824b-b4a2790b08ac";
        endpoints.loginResource = this.publicClientApplication ? clientId /*FxpAppSettings.FxpAppClientId */ : null;
        endpoints.redirectUri = this.publicClientApplication ? window.location.host : null;

        if (endpoints) {
            var bestMatchedConfigEndpoint = "";
            endpoint = endpoint.trimStart();
            for (var configEndpoint in endpoints) {
                if (endpoint.startsWith(configEndpoint) && configEndpoint.length > bestMatchedConfigEndpoint.length) {
                    bestMatchedConfigEndpoint = configEndpoint;
                }
            }
            if (bestMatchedConfigEndpoint !== "") {
                return endpoints[bestMatchedConfigEndpoint];
            }
            // for (var configEndpoint in endpoints) {
            //     // configEndpoint is like /api/Todo requested endpoint can be /api/Todo/1
            //     myArray.filter(element => element.includes("substring"));
            //     if (endpoint.indexOf(configEndpoint) > -1) {
            //         return endpoints[configEndpoint];
            //     }
            // }
        }

        // default resource will be clientid if nothing specified
        // App will use idtoken for calls to itself
        // check if it's staring from http or https, needs to match with app host
        if (endpoint.indexOf('http://') > -1 || endpoint.indexOf('https://') > -1) {
            if (this._getHostFromUri(endpoint) === this._getHostFromUri(endpoints.redirectUri)) {
                return endpoints.loginResource;
            }
            else {
                if (endpoint.indexOf('azureedge.net') > -1) {
                    return '';
                }
            }
        }
        else {
            // in angular level, the url for $http interceptor call could be relative url,
            // if it's relative call, we'll treat it as app backend call.
            return endpoints.loginResource;
        }

        // if not the app's own backend or not a domain listed in the endpoints structure
        return null;
    };

    /**
     * Strips the protocol part of the URL and returns it.
     * @ignore
     */
    private _getHostFromUri(uri) {
        // remove http:// or https:// from uri
        var extractedUri = String(uri).replace(/^(https?:)\/\//, '');
        extractedUri = extractedUri.split('/')[0];
        return extractedUri;
    };

}

export class MsalAuthenticationServiceProvider implements angular.IServiceProvider {

    _config: any;
    _msalService: MsalAuthenticationService;
    public configure(config: Configuration): void {
        this._config = config;
        if (!this._msalService) {
            this._msalService = new MsalAuthenticationService(this._config);
        }
    }

    $get(): any {
        return this._msalService;
    }

    init() {
        // throw new Error("Method not implemented.");
    }
    addState(stateName: any, config: any): void {
        // throw new Error("Method not implemented.");
    }
    otherwise(input: any): void {
        // throw new Error("Method not implemented.");
    }
}

