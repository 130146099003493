import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { FxpConfigurationService } from "./FxpConfiguration";
import { SystemEvent } from "./../telemetry/SystemEvent";
import { ComponentType } from "@microsoftit/telemetry-extensions-npm";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ILogger } from "../interfaces/ILogger";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { ErrorCodes } from "../constants/errorCodes";
import { MsalAuthenticationService } from "./MsalAuthenticationService";


export class FxpSignalRService {
  private connection: HubConnection;
  private signalRServiceEndPoint: string;
  private listeners = {};
  private connectionPromise: Promise<any> = null;
  private pingTimeOutRef = null;
  private pingInterval = 10; //default value 10 mins
  private processName: string;
  private signalREventName: string;
  private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpSignalRService`;

  constructor(
    protected logger: ILogger,
    private fxpConfigurationService: FxpConfigurationService,
    private msalAuthenticationService: MsalAuthenticationService
  ) {
    this.signalRServiceEndPoint = fxpConfigurationService.FxpAppSettings.SignalRPlatformEndPoint;
    this.processName = TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".SignalRClient";
    this.signalREventName = "SignalREventName";

    if (Number.isInteger(fxpConfigurationService.FxpAppSettings.SignalRPlatformPingIntervalInMinutes)){
      this.pingInterval = fxpConfigurationService.FxpAppSettings.SignalRPlatformPingIntervalInMinutes;
    }
    this.pingInterval = this.pingInterval * 60 * 1000;

  }


  private setupPingForSignalServer() {
    const self = this;
    self.ping();
    self.nextLoad();
  }

  cancelNextLoad() {
    const self = this;
    if (self.pingTimeOutRef) {
      clearTimeout(self.pingTimeOutRef);
      self.pingTimeOutRef = null;
    }
  };

  nextLoad() {
    const self = this;
    self.cancelNextLoad();
    self.pingTimeOutRef = setTimeout(self.setupPingForSignalServer.bind(self), self.pingInterval);
  };

  private ping(): void {
    this.sendMessage("ping", "ping server");
  }

  private connect(): Promise<void> {
    const self = this;

    return new Promise((resolve, reject) => {
      if (self.connection && self.connection.state === HubConnectionState.Connected) {
        resolve();
        let currentProcessName = this.processName + '.SignalServerConnected';
        let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'SignalServer Connection Created');
        let customProperties = this.logger.createPropertyBag();
        customProperties.addToBag(TelemetryConstants.CLASSNAME, "FxpSignalRClient");
        customProperties.addToBag(TelemetryConstants.METHODNAME, "connect");
        this.logger.logSystemEvent(this.sourceForTelemetry, eventData, customProperties);

        return;
      }
      self.setupConnection().then(() => {
        resolve();
      });
    });
  }

  private disconnect(): void {
    if (this.connection && this.connection.state === HubConnectionState.Connected) {
      this.connection.stop()
        .then(() => {
          let currentProcessName = this.processName + '.SignalServerDisconnected';
          let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'SignalServer disconnected');
          let customProperties = this.logger.createPropertyBag();
          customProperties.addToBag(TelemetryConstants.CLASSNAME, "FxpSignalRClient");
          customProperties.addToBag(TelemetryConstants.METHODNAME, "disconnect");
          this.logger.logSystemEvent(this.sourceForTelemetry, eventData, customProperties);
        })
        .catch(error => {
          this.logger.logException(this.sourceForTelemetry, error, null, null, null, ErrorSeverityLevel.Low, ErrorCodes.SignalR_ErrorOnDisconnect);
        });
    };

  }

  private sendMessage(methodName: string, ...args): Promise<void> {
    return this.connection.send(methodName, args)
      .catch(error => {
        this.logger.logException(this.sourceForTelemetry, error, null, null, null, ErrorSeverityLevel.Medium, ErrorCodes.SignalR_ErrorOnMessageSend);
      });
  }

  private joinGroup(groupName: string, tenantName: string, environment: string): Promise<void> {
    try {
      if(environment)
        return this.sendMessage("joinGroup", groupName, tenantName, environment);
      else
        return this.sendMessage("joinGroup", groupName, tenantName);
    }
    catch (error) {
      this.logger.logException(this.sourceForTelemetry, error, null, null, null, ErrorSeverityLevel.High, ErrorCodes.SignalR_ErrorOnJoinGroup);
    }
  }

  private leaveGroup(groupName: string, tenantName: string, environment: string): Promise<void> {
    try {
      if(environment)
        return this.sendMessage("leaveGroup", groupName, tenantName, environment);
      else
        return this.sendMessage("leaveGroup", groupName, tenantName);

    }
    catch (error) {
      this.logger.logException(this.sourceForTelemetry, error, null, null, null, ErrorSeverityLevel.Medium, ErrorCodes.SignalR_ErrorOnLeaveGroup);
    }
  }

  subscribe(eventName, tenantName: string, callBack: any) {
    const self = this;
    const environment = this.fxpConfigurationService.FxpAppSettings.EnvironmentName;
    this.connect().then(() => {
      self.joinGroup(eventName, tenantName, environment);
      if (!self.listeners.hasOwnProperty(eventName)) {
        self.listeners[eventName] = [];
      }
      self.listeners[eventName].push(callBack);
      let currentProcessName = this.processName + '.SubscribeEvent.' + eventName;
      let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, `Subscribed to event: ${eventName}`);
      let customProperties = this.logger.createPropertyBag();
      customProperties.addToBag(TelemetryConstants.CLASSNAME, "FxpSignalRClient");
      customProperties.addToBag(TelemetryConstants.METHODNAME, "subscribe");
      customProperties.addToBag(this.signalREventName, eventName);
      this.logger.logSystemEvent(this.sourceForTelemetry, eventData, customProperties);

    });

    return function () {
      self.unsubscribe(eventName, tenantName, callBack, environment);

    }
  }

  private unsubscribe(eventName, tenantName: string, callBack: any, environment: string = null) {
    this.leaveGroup(eventName, tenantName, environment);

    let currentProcessName = this.processName + '.UnSubscribeEvent.' + eventName;
    let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, `UnSubscribed to event: ${eventName}`);
    let customProperties = this.logger.createPropertyBag();
    customProperties.addToBag(TelemetryConstants.CLASSNAME, "FxpSignalRClient");
    customProperties.addToBag(TelemetryConstants.METHODNAME, "unsubscribe");
    customProperties.addToBag(this.signalREventName, eventName);
    this.logger.logSystemEvent(this.sourceForTelemetry, eventData, customProperties);

    if (!this.listeners.hasOwnProperty(eventName)) {
      return;
    }
    this.listeners[eventName] = this.listeners[eventName].filter(x => x != callBack);
    if (this.listeners[eventName].length === 0) {
      delete this.listeners[eventName];
    }
    if (Object.keys(this.listeners).length === 0) {
      this.disconnect();
    }
  }

  unsubscribeAll() {
    this.listeners = {};
    this.disconnect();
  }

  private onMessageFromServer(eventName: string, messageData: any) {
    if (eventName === "pingResponse") {
      return;
    }

    let currentProcessName = this.processName + '.MessageFromServer.' + eventName;
    let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, `Message from SignalR Server for event: ${eventName}`);
    let customProperties = this.logger.createPropertyBag();
    customProperties.addToBag(TelemetryConstants.CLASSNAME, "FxpSignalRClient");
    customProperties.addToBag(TelemetryConstants.METHODNAME, "onMessageFromServer");
    customProperties.addToBag(this.signalREventName, eventName);
    this.logger.logSystemEvent(this.sourceForTelemetry, eventData, customProperties);

    if (this.listeners.hasOwnProperty(eventName)) {
      const callBackFunctions = this.listeners[eventName];
      if (callBackFunctions.length > 0) {
        callBackFunctions.forEach(callbackFunc => {
          callbackFunc(messageData);
        });
      }
    }
  }

  private setupConnection(): Promise<void> {
    const self = this;
    const resource = self.fxpConfigurationService.FxpAppSettings.SignalRPlatformClientId;

    if (self.connectionPromise) {
      return self.connectionPromise;
    }
    self.connection = new HubConnectionBuilder()
        .withUrl(self.signalRServiceEndPoint, { accessTokenFactory: async ()=>{
          let token;
          await this.msalAuthenticationService.getTokenForAppIdAsync(resource).then(function (accesstoken) {
            token = accesstoken;
          })
          return token;
        } })
        .withAutomaticReconnect()
        .build();

      self.connection.onreconnecting(error => {
          if(self.connection.state === HubConnectionState.Reconnecting){
            self.logger.logEvent(this.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SignalRReconnecting`)
          }
      });

      self.connection.onreconnected(connectionId => {
        if(self.connection.state === HubConnectionState.Connected){
          self.logger.logEvent(this.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SignalRReconnected`)
        }
      });
      self.connection.onclose(error => {
        if (self.connection.state === HubConnectionState.Disconnected) {
          self.logger.logEvent(this.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SignalRDisconnected`)
        }
      });

      self.connectionPromise = self.connection.start().then(() => {
        self.connection.on('eventFromServer', self.onMessageFromServer.bind(self));
        self.connectionPromise = null;
        self.setupPingForSignalServer();
        self.logger.logEvent(this.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SignalRConnected`)

      })
      .catch(error => {
        // Logging disabled to reduce noise
        // self.logger.logException(this.sourceForTelemetry, error, null, null, null, ErrorSeverityLevel.High, ErrorCodes.SignalR_ErrorOnConnection);
      })
      return self.connectionPromise;

  }

}
