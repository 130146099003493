import { IRootScope } from "../interfaces/IRootScope";
import { ILogger } from "../interfaces/ILogger";
import { FxpContext } from "../context/FxpContext";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpConstants, ApplicationConstants, PerfMarkers } from "../common/ApplicationConstants";
import { UserInfoService } from "./UserInfoService";
import { CommonUtils } from "../utils/CommonUtils";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";
import { FxpConfigurationService } from "./FxpConfiguration";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { AddLoggedInUserClaims, AddCurrentUserClaims } from "../../app/claims/claims.action";

declare var _: any;
import { TelemetryConstants } from './../telemetry/TelemetryConst';
import { SystemEvent } from './../telemetry/SystemEvent';
import { ComponentType } from '@microsoftit/telemetry-extensions-npm';
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { UserProfileService } from "./userProfileService";
import { MsalAuthenticationService } from "./MsalAuthenticationService";

declare var _: any;
/**
 * A service to connect to user cliams service to fetch the detaisl of user claims related to partner apps
 * @class Fxp.Services.UserClaimsService
 * @classdesc A service to connect to user cliams service to fetch the detaisl of user claims related to partner apps
 * @example <caption> Example to create an instance of user cliams service</caption>
 *  //Initializing User Cliams Service
 *  angular.module('FxPApp').controller('AppController', ['UserClaimsService', AppController]);
 *  function AppController(userClaimsService){ userClaimsService.getUserThumbnail(upn); }
 */
export class UserClaimsService implements IFxPService {
  private http: angular.IHttpService;
  private q: angular.IQService;
  private rootScope: IRootScope;
  private timeout: angular.ITimeoutService;
  private sleepInterval: number;
  private msalAuthenticationService: MsalAuthenticationService;
  private claimsApiEndpoint: string;
  private tenantClaimsApiEndpoint: string;
  private loggedInUserAlias: string;
  private fxplogger: ILogger;
  private fxpcontext: FxpContext;
  private userAlias: string;
  private fxpMessageSvc: FxpMessageService;
  private iReqCount: number = 0;
  private iTenantReqCount: number = 0;
  private userInfoService: UserInfoService;
  public currentUserAlias;
  private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserClaimsService`;

  constructor($http: angular.IHttpService, $q: angular.IQService, $rootScope: IRootScope, $timeout: angular.ITimeoutService, loggerService: ILogger,
    msalAuthenticationService: MsalAuthenticationService, fxpMessage: FxpMessageService, fxpContext: FxpContext,
    userInfoService: UserInfoService, private fxpConfiguration: FxpConfigurationService, private globalStoreService: FxpGlobalStoreService, private userProfileService: UserProfileService) {
    this.http = $http;
    this.q = $q;
    this.rootScope = $rootScope;
    this.timeout = $timeout;
    this.sleepInterval = 100;
    this.msalAuthenticationService = msalAuthenticationService;
    this.fxplogger = loggerService;
    this.fxpMessageSvc = fxpMessage;
    this.fxpcontext = fxpContext;
    this.userAlias = "";
    this.loggedInUserAlias = "";
    this.currentUserAlias = "";
    this.userInfoService = userInfoService;
    let authStore = window["tenantConfiguration"].AuthStore || {};
    this.claimsApiEndpoint = authStore.UserClaimsEndPoint;
    this.tenantClaimsApiEndpoint = authStore.TenantClaimsEndPoint;
  }


  /**
   * Gets userclaims of the logged in user from the fxp context for an application.
   * @method Fxp.Services.UserClaimsService.getLoggedInUserClaims
   * @param {ApplcationId } appId application name for which the roles defined.
   * @example <caption> Example to invoke getLoggedInUserClaims</caption>
   * UserClaimsService.getLoggedInUserClaims('oneProfile');
   */
  getLoggedInUserClaims(appId: string): any {
    return this.getUserClaimsForAlias(appId, this.userInfoService.getLoggedInUserOID());
  }

  /**
   * Gets tenant claims of the logged in user from the fxp context for an application.
   * @method Fxp.Services.UserProfileService.getUserClaims
   * @param {string } tenantId tenant id for which the roles defined.
   * @example <caption> Example to invoke getUserClaims</caption>
   * UserClaimsService.getUserClaims('oneProfile');
   */
  getLoggedInUserTenantClaims(tenantId: string): any {
    return this.getUserClaimsForAlias(tenantId, this.userInfoService.getLoggedInUserOID());
  }

  /**
   * Gets userclaims of the logged in user from the fxp context for an application.
   * @method Fxp.Services.UserClaimsService.getUserClaims
   * @param {string} appId application name for which the roles defined.
   * @example <caption> Example to invoke getUserClaims</caption>
   *  UserClaimsService.getUserClaims('oneProfile');
   */
  getUserClaims(appId: string): any {
    return this.getUserClaimsForAlias(appId, this.userInfoService.getCurrentUserOID());
  }

  /**
   * Gets userclaims of the logged in user from the fxp context for an application.
   * @method Fxp.Services.UserClaimsService.getUserClaimsForScopes
   * @param {string} userAlias alias of the user
   * @param {string[]} appId application / tenant names for which the roles defined.
   * @param {string[]} scopes scopes of role, for which the claims has to be returned.
   * @param {string} scopeDirection direction on which you want to search the scopes for, this inclues "Exact", "ScopeAndAbove", "ScopeAndBelow". By default it is "ScopeAndBelow"
   * @example <caption> Example to invoke getUserClaimsForScopes</caption>
   *  UserClaimsService.getUserClaimsForScopes('your-alias', ['ps','mdm'], ['/contracts/role/abc','/contracts/role/abc'], 'ScopeAndBelow');
   */
   async getUserClaimsForScopes(userAlias: string, appId: string[], scopes: string[] = [], scopeDirection: string = "ScopeAndBelow") {
    const self = this;
    if(CommonUtils.isNullOrEmpty(userAlias)){
      throw new Error('User Alias cannot be null or Empty');
    }
    if(CommonUtils.isNullOrEmpty(appId)){
      throw new Error('Tenant ID cannot be null or empty');
    }
    let userClaims = await this.getClaimsForUserAlias(userAlias, appId);
    if(!CommonUtils.isNullOrEmpty(userClaims.tenantClaims)){
      let userClaimsCopy = JSON.parse(JSON.stringify(userClaims));
      let tenantClaims = Object.keys(userClaimsCopy.tenantClaims).reduce((accumulator, key)=> {
        accumulator[key.toLowerCase()] = userClaimsCopy.tenantClaims[key]; 
        return accumulator;
      },{});
      appId = appId.map(app => app.toLowerCase());
      let filteredClaims = self.filterRolesBasedOnScopes(tenantClaims, appId, scopes, scopeDirection);
      userClaimsCopy.tenantClaims = {};
      for(let app of appId){
        if(!CommonUtils.isNullOrEmpty(filteredClaims[app])) {
          userClaimsCopy.tenantClaims[app] = filteredClaims[app]
        }
      }
      return userClaimsCopy;
    } else {
      throw new Error('Tenant claims is empty');
    }
  }

  filterRolesBasedOnScopes(inputClaims, tenants, scopes, scopeDirection) {
    if (CommonUtils.isNullOrEmpty(inputClaims)) {
      throw new Error('Tenant claims cannot be null.');
    }
  
    let tenantScopesMap = this.formTenantScopeMapping(scopes, tenants);
  
    for (const tenant of tenants) {
      if (CommonUtils.isNullOrEmpty(tenantScopesMap[tenant])) {
        continue;
      }

      let tenantScopes = tenantScopesMap[tenant];
  
      const properties = Object.keys(inputClaims[tenant]);
      for (let i = 0; i < properties.length; i++) {
        const propertyName = properties[i];
  
        if (propertyName !== 'claims' && propertyName !== 'delegatedClaims') {
          break;
        }
  
        let roles = {};
        if (propertyName === 'claims') {
          roles = inputClaims[tenant].claims.roles;
          inputClaims[tenant].claims.resourcePermissions = [];
        } else if (propertyName === 'delegatedClaims') {
          roles = inputClaims[tenant].delegatedClaims.roles;
          inputClaims[tenant].delegatedClaims.resourcePermissions = [];
        }
        let filteredScopes = [];
  
        for (const roleName in roles) {
          if (!roles.hasOwnProperty(roleName)) {
            continue;
          }
  
          const roleObj = roles[roleName];
  
          if (!roleObj || !roleObj.Scopes) {
            continue;
          }
  
          const roleScopes = roleObj.Scopes;
  
          switch (scopeDirection) {
            case 'None':
              filteredScopes = [];
              break;
            case 'Exact':
              filteredScopes = roleScopes.filter((scope) =>tenantScopes.includes(scope));
              break;
            case 'ScopeAndBelow':
              filteredScopes = roleScopes.filter((roleScope) => {
                return tenantScopes.some((tenantScope) =>
                  this.filterScope(roleScope, tenantScope)
                );
              });
              break;
            case 'ScopeAndAbove':
              filteredScopes = roleScopes.filter((roleScope) => tenantScopes.some((tenantScope) => tenantScope.startsWith(roleScope)));
              break;
            default:
              throw new Error('Invalid search direction');
          }
  
          if (filteredScopes.length === 0) {
            if (propertyName === 'claims') {
              delete inputClaims[tenant].claims.roles[roleName];
            } else if (propertyName === 'delegatedClaims') {
              delete inputClaims[tenant].delegatedClaims.roles[roleName];
            }
          } else {
            if (propertyName === 'claims') {
              inputClaims[tenant].claims.roles[roleName].Scopes = filteredScopes;
              if(roleObj.resourcePermissions){
                for (const permission of roleObj.resourcePermissions) {
                  const existingPermission = inputClaims[tenant].claims.resourcePermissions.find((existingPermission) => 
                      existingPermission.resource.toLowerCase() === permission.resource.toLowerCase());
                  if (existingPermission) {
                    existingPermission.permissions.push(...permission.permissions);
                    existingPermission.permissions = Array.from(
                      new Set(existingPermission.permissions)
                    );
                    continue;
                  }
                  inputClaims[tenant].claims.resourcePermissions.push(permission);
                }
              }
            } else if (propertyName === 'delegatedClaims') {
              inputClaims[tenant].delegatedClaims.roles[roleName].Scopes = filteredScopes;
              if(roleObj?.DefaultPermisssions){
                for (const permission of roleObj.DefaultPermisssions) {
                  const existingPermission =
                    inputClaims[tenant].delegatedClaims.resourcePermissions.find(
                      (existingPermission) =>
                        existingPermission.resource.toLowerCase() ===
                        permission.resource.toLowerCase()
                    );
    
                  if (existingPermission) {
                    existingPermission.permissions.push(...permission.permissions);
                    existingPermission.permissions = Array.from(
                      new Set(existingPermission.permissions)
                    );
                    continue;
                  }
                  inputClaims[tenant].delegatedClaims.resourcePermissions.push(permission);
                }
              }
            }
          }
        }
      }
    }
    return inputClaims;
  }

  formTenantScopeMapping(scopes, tenants) {
    let tenantScopesMap = {};
    (tenants ?? []).forEach((tenant) => {
      tenantScopesMap[tenant] = [];
    });

    if (scopes && scopes.length > 0) {
      (scopes ?? []).forEach((scope) => {
        let modifiedScope = scope.replace(/^\/+/, '');
        let tenantMatch = tenants.find((tenant) =>
          modifiedScope.toLowerCase().startsWith(tenant.toLowerCase())
        );
        if (tenantMatch) {
          let tenantTrimmedScope =
            modifiedScope.indexOf('/') >= 0
              ? modifiedScope.substring(modifiedScope.indexOf('/'))
              : null;
          if (tenantTrimmedScope) {
            tenantScopesMap[tenantMatch].push(tenantTrimmedScope);
          }
        }
      });
    }
    return tenantScopesMap;
  }
  filterScope(roleScope, headerScope) {
    const roleParts = roleScope.split('/');
    const headerParts = headerScope.split('/');
    if (headerParts.length > roleParts.length) {
      return false;
    }
    for (let i = 0; i < headerParts.length; i++) {
      if (headerParts[i].toLowerCase() !== roleParts[i].toLowerCase()) {
        return false;
      }
    }
    return true;
  }

  /**
   * Gets userclaims of the logged in user from the fxp context for an application.
   * @method Fxp.Services.UserProfileService.getUserClaims
   * @param {ApplcationId } appId application name for which the roles defined.
   * @example <caption> Example to invoke getUserClaims</caption>
   *  UserClaimsService.getUserClaims('oneProfile');
   */
  getUserTenantClaims(tenantId: string): any {
    return this.getUserTenatClaimsByObjectId(this.userInfoService.getCurrentUserOID(), tenantId);
  }

  /**
   * Gets userclaims of the user from the fxp context for an application.
   * @method Fxp.Services.UserProfileService.getUserClaims
   * @param {ApplcationId } appId application name for which the roles defined.
   * @example <caption> Example to invoke getUserClaims</caption>
   *  UserClaimsService.getUserClaims('oneProfile');
   */

  private getUserClaimsForAlias(appId: string, objectId: string): any {
    let userClaimsLocalStorage = this.getClaimsFromLocalStorage(objectId);
    let genericPropBag = this.getClaimsPropertyBag(objectId, appId, "AppId");
    const telemetry_source = `${this.sourceForTelemetry}.GetUserClaimsForAlias`;
    if (CommonUtils.isNullOrEmpty(userClaimsLocalStorage)) {
      this.fxplogger.logError(telemetry_source, this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessageTitle, ErrorCodes.AuthSerive_UserClaimsEmptyInLocalStorage, null, genericPropBag, null, null, ErrorSeverityLevel.High);
      throw new Error(this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessage);
    }
    let userClaims = JSON.parse(userClaimsLocalStorage);
    if (CommonUtils.isNullOrEmpty(appId))
      return userClaims.Applications;

    if (!userClaims.Applications || Object.keys(userClaims.Applications).indexOf(appId) < 0) {
      this.fxplogger.logError(telemetry_source, this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessageTitle, ErrorCodes.AuthSerive_MissingApplicationObjectInClaims, null, genericPropBag, null, null, ErrorSeverityLevel.High);
      throw new Error(this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessage);
    }

    let application = userClaims.Applications[appId];

    if (application.Roles === undefined || Object.keys(application.Roles).length === 0) {
      this.fxplogger.logError(telemetry_source, this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppRoleError.ErrorMessageTitle, ErrorCodes.AuthSerive_MissingRolesObjectInClaims, null, genericPropBag, null, null, ErrorSeverityLevel.High);
      throw new Error(this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppRoleError.ErrorMessage);
    }
    return application;
  }

  private getClaimsPropertyBag(alias: string, entityId: string, entityType: string): LogPropertyBag {
    let propertyBag = this.fxplogger.createPropertyBag();
    try {
      let localStorageValue = this.getClaimsFromLocalStorage(alias);
      propertyBag.addToBag('alias', alias);
      propertyBag.addToBag(`${entityType}`, entityId);

      if (!CommonUtils.isNullOrEmpty(localStorageValue)) {
        let claimsData = JSON.parse(localStorageValue);
        propertyBag.addToBag("localStorage-V1Claims", CommonUtils.objectToString(claimsData.Applications));
        propertyBag.addToBag("localStorage-V2Claims", CommonUtils.objectToString(claimsData.tenantClaims));
        propertyBag.addToBag("localStorage-ErrorInfor", CommonUtils.objectToString(claimsData.ErrorInfo));
      }
      else {
        propertyBag.addToBag("IsLocalStorageEmpty", 'True');
      }

    }
    catch (e) {
      //ingore if something is breaking. 
    }
    return propertyBag;
  }
  /**
   * Gets tenant claims of the user based on alias and tenantId
   * @method Fxp.Services.UserProfileService.getUserTenatClaimsByAlias
   * @param {string } alias user alias information
   * @param {string } tenantId tenant id
   * @example <caption> Example to invoke getUserTenatClaimsByAlias</caption>
   *  UserClaimsService.getUserTenatClaimsByAlias('oneProfile');
   */
  private getUserTenatClaimsByObjectId(objectId: string, tenantId?: string): any {
    let claimsLocalStorage = this.getClaimsFromLocalStorage(objectId);
    let genericPropBag = this.getClaimsPropertyBag(objectId, tenantId, "TenantId");
    const telemetry_source = `${this.sourceForTelemetry}.GetUserTenatClaimsByAlias`;

    if (CommonUtils.isNullOrEmpty(claimsLocalStorage)) {
      this.fxplogger.logError(telemetry_source, this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessageTitle, ErrorCodes.AuthSerive_TenantClaimsEmptyInLocalStorage, null, genericPropBag, null, null, ErrorSeverityLevel.High);
      throw new Error(this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessage);
    }
    let claimsData = JSON.parse(claimsLocalStorage);
    if (CommonUtils.isNullOrEmpty(tenantId))
      return claimsData.tenantClaims;

    if (Object.keys(claimsData.tenantClaims).indexOf(tenantId) < 0) {

      this.fxplogger.logError(telemetry_source, this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessageTitle, ErrorCodes.AuthSerive_TenantObjectMissingInClaims, null, genericPropBag, null, null, ErrorSeverityLevel.High);
      throw new Error(this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessage);
    }
    let tenant = claimsData.tenantClaims[tenantId];
    if (tenant.claims === undefined || Object.keys(tenant.claims).length === 0) {
      this.fxplogger.logError(telemetry_source, this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppRoleError.ErrorMessageTitle, ErrorCodes.AuthSerive_ClaimsMissingInTenantObject, null, genericPropBag, null, null, ErrorSeverityLevel.High);
      throw new Error(this.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppRoleError.ErrorMessage);
    }

    return tenant;
  }

  public isV2ClaimsTenantId(appId: string): boolean {
    let result: boolean = false;
    let alias = this.userInfoService.getCurrentUser();


    var claimsData = this.globalStoreService.GetPlatformState().LoggedInUser.Claims;
    if (!CommonUtils.isNullOrEmpty(appId) && !CommonUtils.isNullOrEmpty(claimsData) && claimsData.tenantClaims && (Object.keys(claimsData.tenantClaims).indexOf(appId) >= 0)) {
      {
        result = true;
      }
    }
    return result;
  }

  private createClaimsPropertyBag(claimId: string, claims: any, method: string): LogPropertyBag {
    let propBag = this.fxplogger.createPropertyBag();
    propBag.addToBag('Method', method);
    propBag.addToBag('ClaimId', claimId);
    propBag.addToBag('V1Claims', CommonUtils.objectToString(claims.Applications));
    propBag.addToBag('V2Claims', CommonUtils.objectToString(claims.tenantClaims));
    propBag.addToBag('ErrorInfo', CommonUtils.objectToString(claims.ErrorInfo));
    propBag.addToBag('ClaimsURL', this.tenantClaimsApiEndpoint);
    return propBag;
  }
  /**
   * this method used get get tenant claims
   * @method Fxp.Services.UserProfileService.getTenatClaimsSvc
   * @param {string} alias it contains user alias information
   * @example <caption> Example to invoke getTenatClaimsSvc</caption>
   * UserClaimsService.getTenatClaimsSvc(alias);
   */
  getTenatClaimsSvc(objectID, parentDeferred?: any) {
    let self = this;
    const telemetry_source = `${this.sourceForTelemetry}.GetTenatClaimsSvc`;
    let deferred = parentDeferred || self.q.defer();
    if (this.tenantClaimsApiEndpoint) {
      let url = this.tenantClaimsApiEndpoint + '/User/' + objectID + '/claims';
      if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
        self.iTenantReqCount++;
        if (self.iTenantReqCount === 5) {
          self.fxplogger.logError(telemetry_source, self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.AuthService_TokenAcquisitionInProgress, null, null, null, null, ErrorSeverityLevel.Medium);
          self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
          return deferred.promise;
        }
        self.timeout(function () {
          self.getTenatClaimsSvc(objectID, deferred);
        }, self.sleepInterval);
      }
      else {
        self.iTenantReqCount = 0;
        let areHeadersAvailable = window["tenantConfiguration"].AuthStore != null && window["tenantConfiguration"].AuthStore.TenantClaimsHeaders != null;
        let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.Authorization.FetchUserClaims';
        let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'Fetching User Claims data. ');

        self.fxplogger.logSystemEvent(telemetry_source, eventData, null);

        return areHeadersAvailable ?
          this.http.get(url, { headers: window["tenantConfiguration"].AuthStore.TenantClaimsHeaders }) : this.http.get(url);
      }
    } else {
      let defer = self.q.defer();
      defer.resolve({ data: { tenantClaims: {} } });
      return defer.promise;
    }
    return deferred.promise;
  }

  /**
   * Gets user claims by connecting to the service through the url
   * @method Fxp.Services.UserProfileService.getUserClaimsSvc
   * @example <caption> Example to invoke getUserClaimsSvc</caption>
   *  UserClaimsService.getUserClaimsSvc();
   */
  getUserClaimsSvc(alias: string, parentDeferred?: any): angular.IPromise<any> {
    let self = this;
    let deferred = parentDeferred || self.q.defer();
    let url: string;
    const telemetry_source = `${this.sourceForTelemetry}.GetUserClaimsSvc`;
    if (self.claimsApiEndpoint) {
      this.getObjectIdFromIdentifier(alias).then(function (result) {
        let OID = result;
        url = self.claimsApiEndpoint + "/User/" + OID + "/claims";
        this.fxplogger.startTrackPerformance(PerfMarkers.GetUserClaims);
        if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
          self.iReqCount++;
          if (self.iReqCount == 5) {
            self.fxplogger.logError(telemetry_source, self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.AuthService_TokenAcquisitionInProgress_TenantClaims, null, null, null, null, ErrorSeverityLevel.Medium);
            self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
            return deferred.promise;
          }
          self.timeout(function () {
            self.getUserClaimsSvc(alias);
          }, self.sleepInterval);
        }
        else {
          this.iReqCount = 0;
          return this.http.get(url);
        }
        self.fxplogger.stopTrackPerformance(PerfMarkers.GetUserClaims);
      });
    }
    else {
      let defer = self.q.defer();
      //returning empty claims if profile api is not configured
      defer.resolve({ data: { "Applications": {} } });
      return defer.promise;
    }
    return deferred.promise;
  }
  
  getObjectIdFromIdentifier(useridentifier: string): angular.IPromise<any> {
    var self = this;
    let deferred = this.q.defer();
    if (CommonUtils.isGuid(useridentifier))
      deferred.resolve(useridentifier);

    else if (useridentifier.toLowerCase() == self.userInfoService.getLoggedInUser().toLowerCase())
      deferred.resolve(self.userInfoService.getLoggedInUserOID());


    else if (useridentifier.toLowerCase() == self.userInfoService.getCurrentUser().toLowerCase())
      deferred.resolve(self.userInfoService.getCurrentUserOID());


    else {
      // get oid from one profile call
      self.userProfileService.getBasicProfileByAlias(useridentifier).then(function (loggedInUserInfo: any) {
        var userinfo = loggedInUserInfo;
        deferred.resolve(userinfo.aadObjectId);
      });
    }
    return deferred.promise;
  }

  /**
   * This is a temporary method used to get claims for the given alias.
   * This method is written to ensure that error cases are gracefully handled by partners
   * @param alias Alias of the user
   * @param tenants List of Tenants for which claims are needed. If not passed then the list is picked from tenant configuration
   */
  getClaimsForUserAlias(alias: string, tenants?: string[]): angular.IPromise<any> {
    let self = this;
    let deferred = this.q.defer();
    const telmetry_source = `${this.sourceForTelemetry}.GetClaimsForUserAlias`;

    self.getObjectIdFromIdentifier(alias).then(function (oid) {
      let objectID = oid;
      var parsedClaims = self.getClaimsFromGlobalStore(objectID);

      if (!CommonUtils.isNullOrEmpty(parsedClaims)) {
        deferred.resolve(parsedClaims);
        return deferred.promise;
      }
      let claimsEndpoint = self.tenantClaimsApiEndpoint + "/User/" + objectID + "/claims";
      let tenantheaders = tenants === undefined || tenants === null || tenants.length === 0
        ? window["tenantConfiguration"].AuthStore.TenantClaimsHeaders
        : { "X-Tenants": tenants.join(",") };

      self.http.get(claimsEndpoint, {
        headers: tenantheaders
      }).then(response => {
        let properties = self.fxplogger.createPropertyBag();
        properties.addToBag("Alias", alias);
        let systemEvent = new SystemEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ClaimsCallSuccessfull`, ComponentType.Web, `Claims received for alias: ${alias}`);
        self.fxplogger.logSystemEvent(self.sourceForTelemetry, systemEvent, properties, null, null);
        var responseData = JSON.stringify(response.data);
        self.dispatchClaimsAction(responseData, objectID);
        deferred.resolve(response.data);
      }, error => {
        let properties = self.fxplogger.createPropertyBag();
        properties.addToBag("UserAlias", alias);
        self.fxplogger.logException(telmetry_source,
          error,
          properties,
          null,
          null,
          ErrorSeverityLevel.Critical,
          ErrorCodes.ProfileServiceFailure);
        deferred.reject(error)
      });
    });
    return deferred.promise;
  }

  /**
   * This method is used to get user claims by Object ID
   * This method is written to ensure that error cases are gracefully handled by partners
   * @param objectId Object ID of the user
   * @param tenants List of Tenants for which claims are needed. If not passed then the list is picked from tenant configuration
   */
  getClaimsByObjectId(objectId: string, tenants?: string[]): angular.IPromise<any> {
    let self = this;
    let deferred = this.q.defer();
    const telmetry_source = `${this.sourceForTelemetry}.GetClaimsByObjectId`;
    var parsedClaims = self.getClaimsFromGlobalStore(objectId);

    if (!CommonUtils.isNullOrEmpty(parsedClaims)) {
      deferred.resolve(parsedClaims);
      return deferred.promise;
    }
    let claimsEndpoint = this.tenantClaimsApiEndpoint + "/User/" + objectId + "/claims";
    let tenantheaders = tenants === undefined || tenants === null || tenants.length === 0
      ? window["tenantConfiguration"].AuthStore.TenantClaimsHeaders
      : { "X-Tenants": tenants.join(",") };

    this.http.get(claimsEndpoint, {
      headers: tenantheaders
    }).then(response => {
      let properties = self.fxplogger.createPropertyBag();
      properties.addToBag("ObjectId", objectId);
      let systemEvent = new SystemEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ClaimsCallSuccessfull`, ComponentType.Web, `Claims received for Object ID: ${objectId}`);
      self.fxplogger.logSystemEvent(this.sourceForTelemetry, systemEvent, properties, null, null);
      var responseData = JSON.stringify(response.data);
      self.dispatchClaimsAction(responseData, objectId);
      deferred.resolve(response.data);
    }, error => {
      let properties = self.fxplogger.createPropertyBag();
      properties.addToBag("ObjectId", objectId);
      self.fxplogger.logException(telmetry_source,
        error,
        properties,
        null,
        null,
        ErrorSeverityLevel.Critical,
        ErrorCodes.ProfileServiceFailure);
      deferred.reject(error)
    });

    return deferred.promise;
  }

  /**
   * this method used get user claims and tenant cliams based on user alias
   * @method Fxp.Services.UserClaimsService.getCalimsSvc
   * @param {string} alias it contains user alias info
   * @example <caption> Example to invoke getCalimsSvc</caption>
   * UserClaimsService.getCalimsSvc(alias);
   */
  getCalimsSvc(userIdentifier?: string): angular.IPromise<any> {
    let self = this;
    let deferred = self.q.defer();
    let sendDate = new Date();
    const telemetry_source = `${this.sourceForTelemetry}.GetCalimsSvc`;
    self.getObjectIdFromIdentifier(userIdentifier).then(function (response) {
      let objectId = response;
      let customProperties = self.fxplogger.createPropertyBag();
      customProperties.addToBag("AADObjectId", objectId);

      var fxpAppLaunched = window.sessionStorage.getItem(ApplicationConstants.FxpApplaunchedKey);
      let isAppLaunched = !CommonUtils.isNullOrEmpty(fxpAppLaunched) && fxpAppLaunched === "true";

      let claimData;
      claimData = self.getClaimsFromGlobalStore(objectId);

      if (isAppLaunched && !CommonUtils.isNullOrEmpty(claimData)) {
        self.setUserInfo(claimData);
        self.timeout(function () {
          deferred.resolve(claimData);
        }, self.sleepInterval);
        return deferred.promise;
      } else {
        let appCliams = {};
        var localStorageClaims = self.getClaimsFromLocalStorage(objectId);
        if (localStorageClaims) {
          self.dispatchClaimsAction(localStorageClaims, objectId);
          let claims = JSON.parse(localStorageClaims);
          if (claims.tenantClaims) {
            self.timeout(function () {
              try {
                deferred.notify(JSON.parse(localStorageClaims));
                let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.Authorization.GetUserClaimsFromCache';
                let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'User Claims data from browser cache. ');
                self.fxplogger.logSystemEvent(telemetry_source, eventData, customProperties);

              } catch (exception) {
                self.fxplogger.logException(telemetry_source, exception, customProperties, null, null, ErrorSeverityLevel.Medium, ErrorCodes.AuthService_ErrorNotifyingCachedClaims);
              }
            }, self.sleepInterval);
          }
        }

        self.getTenatClaimsSvc(objectId)
          .then(function (tResponse) {
            if (tResponse.data) {
              appCliams = tResponse.data;
              self.setUserInfo(appCliams);
              self.logClaimsMetrics(sendDate, 'getTenatClaimsSvc() Start');

              if (appCliams["tenantClaims"] == undefined || Object.keys(appCliams["tenantClaims"]).length == 0) {
                let propBag = self.createClaimsPropertyBag('', appCliams, 'getCalimsSvc-getTenatClaimsSvc');
                self.fxplogger.logError(telemetry_source, self.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessageTitle, ErrorCodes.AuthService_TenantClaimsObjectMissingInClaims, null, propBag, null, null, ErrorSeverityLevel.High);
              }
              appCliams["defaultAppRole"] = self.getDefaultAppRole(appCliams["tenantClaims"]);

              self.logClaimsMetrics(sendDate, 'getUserAppRolesSvc() End');
            }
            self.saveClaimsData(objectId, appCliams);
            deferred.resolve(appCliams);
          }, function (err) {
            console.log("ClaimsFailed" + new Date());
            self.logClaimsException(err, sendDate);
            if (window["tenantConfiguration"].IsAuthorizationRequired || self.rootScope.isLoginRequired) {
              let error = {};
              if (err.data && err.data.ErrorInfo != null) {
                if (err.data.ErrorInfo["V1"] != null)
                  error = { statusText: "V1 Claims Call Failed", status: err.data.ErrorInfo["V1"].responseCode, config: err.config };
                if (err.data.ErrorInfo["V2"] != null)
                  error = { statusText: "V2 Claims Call Failed", status: err.data.ErrorInfo["V2"].responseCode, config: err.config };
                if (!err.data.ErrorInfo) {
                  error = { statusText: "Claims service call failed with unknown reasons - Error information not found" };
                }
              }
              else {
                error = { statusText: "Claims service call failed with unknown reasons - Error data not found" };
              }
              let genericPropBag = self.fxplogger.createPropertyBag();
              genericPropBag.addToBag(FxpConstants.metricConstants.IsLoginRequired, self.rootScope.isLoginRequired);
              genericPropBag.addToBag(FxpConstants.metricConstants.Error, JSON.stringify(error));
              self.fxplogger.logError(telemetry_source, self.rootScope.fxpUIConstants.UIMessages.GetUserClaimsSvcReturnsError.ErrorMessageTitle, ErrorCodes.AuthService_APICallFailedWithUnknownReason, null, genericPropBag, null, null, ErrorSeverityLevel.Critical);
              deferred.reject(error);
            }
            else {

              if (self.getClaimsFromLocalStorage(objectId)) {
                appCliams["defaultAppRole"] = self.getClaimsFromLocalStorage(objectId);
              }
              else {
                appCliams["defaultAppRole"] = self.getDefaultAppRole(null);
                self.saveClaimsData(objectId, appCliams);
              }
              deferred.resolve(appCliams);
            }
          });


      }
    }, function (err) {
      self.logClaimsException(err, sendDate);
      deferred.reject(err);
    });
    return deferred.promise;
  }

  private dispatchClaimsAction(localStorageClaims: string, objectId: string) {
    var self = this;
    if (objectId == self.userInfoService.getLoggedInUserOID()) {
      self.globalStoreService.DispatchGlobalAction("Platform", AddLoggedInUserClaims(JSON.parse(localStorageClaims)));
    }
    if (objectId == self.userInfoService.getCurrentUserOID()) {
      self.globalStoreService.DispatchGlobalAction("Platform", AddCurrentUserClaims(JSON.parse(localStorageClaims)));

    }
  }

  private getClaimsFromGlobalStore(objectId: string) {
    var self = this;
    var claimData;
    if (objectId == self.userInfoService.getLoggedInUserOID()) {
      claimData = self.globalStoreService.GetPlatformState().LoggedInUser.Claims;
    }
    else if (objectId == self.userInfoService.getCurrentUserOID()) {
      let currentClaims = self.globalStoreService.GetPlatformState().CurrentUser.Claims;
      if (!CommonUtils.isNullOrEmpty(currentClaims) && objectId == currentClaims.aadObjectId)
        claimData = currentClaims;
    }

    return claimData;
  }

  /**
   * this method is used to save claims data into session storage
   * @method Fxp.Services.UserClaimsService.setUserInfo
   * @param {string} alias user alias
   * @param {Object} claimsData it contains claims data
   * @example <caption> Example to invoke saveClaimsData</caption>
   *  UserClaimsService.saveClaimsData(alias, claimsData, sessionkey, contextkey);
   */
  saveClaimsData(objectId: string, claimsData): void {
    let strCalimsData = JSON.stringify(claimsData);
    var self = this;
    this.dispatchClaimsAction(strCalimsData, objectId);
    localStorage[FxpConstants.CONST.fxpUserClaimsSession + "_" + objectId] = strCalimsData;
    this.fxpcontext.saveContext(objectId + '-' + ApplicationConstants.UserClaims, strCalimsData);
    let alias = self.getAliasFromOID(objectId);
    if (!CommonUtils.isNullOrEmpty(alias)) {
      localStorage[FxpConstants.CONST.fxpUserClaimsSession + "_" + alias.toLowerCase()] = strCalimsData;
      this.fxpcontext.saveContext(alias.toLowerCase() + '-' + ApplicationConstants.UserClaims, strCalimsData);
    }
  }
  getAliasFromOID(objectId: string): string {
    return objectId == this.userInfoService.getLoggedInUserOID() ? this.userInfoService.getLoggedInUser() :
      objectId == this.userInfoService.getCurrentUserOID() ? this.userInfoService.getCurrentUser() : "";
  }
  /**
   * this method is used to log get user cliams api response metrics
   * @method Fxp.Services.UserClaimsService.logUserClaimsMetrics
   * @param {date} sendDate it contains user claims service intiate date and time
   * @example <caption> Example to invoke saveClaimsData</caption>
   * UserClaimsService.logUserClaimsMetrics(sendDate);
   */
  private logClaimsMetrics(sendDate, logInfo: string): void {
    let self = this,
      propbag = self.fxplogger.createPropertyBag(),
      receiveDate = new Date(),
      responseTime = receiveDate.getTime() - sendDate.getTime(); // in millisecs

    propbag.addToBag(FxpConstants.metricConstants.StartTime, sendDate.toUTCString());
    propbag.addToBag(FxpConstants.metricConstants.EndTime, receiveDate.toUTCString());
    propbag.addToBag(FxpConstants.metricConstants.ServiceName, FxpConstants.metricConstants.GetBasicProfileSvcName);

    self.fxplogger.logMetric(self.sourceForTelemetry, FxpConstants.metricConstants.UserClaimsResponseTime, responseTime, propbag);
    //self.fxplogger.logInformation(self.classNameUserClaimsService, logInfo);
  }

  /**
   * this method is used to log exception details of user cliams api =
   * @method Fxp.Services.UserClaimsService.logUserClaimsException
   * @param {object} exception it contains exception details
   * @param {date} sendDate it contains user claims service intiate date and time
   * @example <caption> Example to invoke logUserClaimsException</caption>
   * UserClaimsService.logUserClaimsException(exception,sendDate);
   */
  private logClaimsException(exception, sendDate): void {
    let self = this, propbag = self.fxplogger.createPropertyBag(),
      receiveDate = new Date(),
      responseTime = receiveDate.getTime() - sendDate.getTime();
    propbag.addToBag(FxpConstants.metricConstants.Status, exception.status);
    propbag.addToBag(FxpConstants.metricConstants.StatusText, exception.statusText);
    propbag.addToBag(FxpConstants.metricConstants.ErrorUrl, exception.config ? exception.config.url : '');
    propbag.addToBag(FxpConstants.metricConstants.ErrorText, exception.data ? JSON.stringify(exception.data) : '');
    self.fxplogger.logError(`${self.sourceForTelemetry}.LogClaimsException`, self.rootScope.fxpUIConstants.UIMessages.AuthServiceReturnsBlankAppError.ErrorMessageTitle, ErrorCodes.AuthService_UnknownException, "", propbag, null, null, ErrorSeverityLevel.High);
    propbag.addToBag(FxpConstants.metricConstants.StartTime, sendDate.toUTCString());
    propbag.addToBag(FxpConstants.metricConstants.EndTime, receiveDate.toUTCString());
    propbag.addToBag(FxpConstants.metricConstants.ServiceName, FxpConstants.metricConstants.GetUserClaimsSvcName);
    self.fxplogger.logMetric(self.sourceForTelemetry, FxpConstants.metricConstants.UserClaimsResponseTimeError, responseTime, propbag);
    if (window["tenantConfiguration"].IsAuthorizationRequired)
      self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.AuthzServiceReturnsError.ErrorMessage, FxpConstants.messageType.error);
  }

  /**
   * this method is used to get is Acting On Behalf Of is activate
   * @example <caption> Example to invoke logTenantClaimsException</caption>
   * UserClaimsService.isObo();
   */
  private isObo(): boolean {
    return this.userInfoService.isActingOnBehalfOf();
  }

  /**
   * this method is used to set the Upn and AAD Object ID for the Resepective users
   * @method Fxp.Services.UserClaimsService.setUserInfo
   * @param {claimsData } claimsData user cliams
   * @example <caption> Example to invoke setUserInfo</caption>
   *  UserClaimsService.setUserInfo(claimsData);
   */
  private setUserInfo(claimsData): void {
    this.userInfoService.setCurrentUserUpn(claimsData.upn);
  }

  /**
   * intializing current user alias
   * @method Fxp.Services.UserClaimsService.setCurrentUser
   * @param {string } alias it contains user alias
  * @example <caption> Example to invoke setCurrentUser</caption>
   * UserClaimsService.setCurrentUser(alias);
   */
  setCurrentUser(alias: string) {
    this.userAlias = alias;
    this.currentUserAlias = alias;
  }

  getUserRoles(alias?: string): angular.IPromise<any> {
    let self = this;
    let deferred = self.q.defer();
    let listOfRoles = [];
    let userIdentifier = alias || self.userInfoService.getCurrentUser();
    if(CommonUtils.isNullOrEmpty(userIdentifier))
       userIdentifier = self.userInfoService.getCurrentUserOID();
    try {
      self.getCalimsSvc(userIdentifier)
        .then(function (response) {
          let result = response;
          if (result.Applications) {
            _.each(result.Applications, function (app) {
              var appName = app.ApplicationName;
              _.each(app.Roles, function (role) {
                listOfRoles.push(appName + "." + role.RoleName);
              });
            });
          }
          if (result.tenantClaims) {
            for (let npd in result.tenantClaims) {
              _.each(result.tenantClaims[npd].claims.roles, function (role) {
                listOfRoles.push(role.roleName);
              });
            }
          }
          if (listOfRoles.length == 0) {
            //adding dummy app role if user dont have app roles
            listOfRoles.push(window["tenantConfiguration"].DefaultAppRole);
          }
          deferred.resolve(listOfRoles);
        });
    }
    catch (ex) {
      deferred.reject(ex);
      self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.SelectedProfileRoles.ErrorMessage, FxpConstants.messageType.error);
    }
    return deferred.promise;
  }

  getUserDelegatedRoles(alias?: string): angular.IPromise<any> {
    let self = this;
    let deferred = self.q.defer();
    let listOfRoles: string[] = [];
    let userIdentifier = alias || self.userInfoService.getCurrentUser();
    if(CommonUtils.isNullOrEmpty(userIdentifier))
       userIdentifier = self.userInfoService.getCurrentUserOID();
    try {
      self.getCalimsSvc(userIdentifier)
        .then(function (response) {
          let result = response;
          if (result.tenantClaims) {
            for (let npd in result.tenantClaims) {
              if (result.tenantClaims[npd].delegatedClaims !== undefined
                && result.tenantClaims[npd].delegatedClaims !== null
                && result.tenantClaims[npd].delegatedClaims.roles !== undefined
                && result.tenantClaims[npd].delegatedClaims.roles !== null) {
                _.each(result.tenantClaims[npd].delegatedClaims.roles, function (role) {
                  listOfRoles.push(role.roleName);
                });
              }
            }
          }
          deferred.resolve(listOfRoles);
        });
    }
    catch (ex) {
      deferred.reject(ex);
      self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.SelectedProfileRoles.ErrorMessage, FxpConstants.messageType.error);
    }
    return deferred.promise;
  }

  getUserConsolidatedRoles(alias?: string): angular.IPromise<any> {
    let self = this;
    let deferred = self.q.defer();
    let getUserRolesPromise = self.getUserRoles(alias);
    let getUserDelegatedRolesPromise = self.getUserDelegatedRoles(alias);

    let promiseList = [getUserRolesPromise, getUserDelegatedRolesPromise];
    self.q.all(promiseList)
      .then(function (result) {
        if (result === undefined || result === null || result.length === 0) {
          deferred.resolve([]);
        }
        let userRoles = result.length > 0 ? result[0] : [];
        let userDelegatedRoles = result.length > 1 ? result[1] : [];
        let consolidatedRoles = _.union(userRoles, userDelegatedRoles);
        deferred.resolve(consolidatedRoles);
      });
    return deferred.promise;
  }

  /**
   * userd to get user resources list
   * @method Fxp.Services.UserClaimsService.getUserResources
   * @param {string } alias it contains user alias
   * @example <caption> Example to invoke getUserResources</caption>
   * UserClaimsService.getUserResources(alias);
   *
   */
  getUserResources(objectId?: string) {
    let self = this;
    let listOfResources = [];
    objectId = objectId || self.userInfoService.getCurrentUserOID();
    try {
      let result = self.getUserTenatClaimsByObjectId(objectId);
      if (result && result[window["tenantConfiguration"].NPD]) {
        _.each(result[window["tenantConfiguration"].NPD].claims.resourcePermissions, function (item) {
          listOfResources.push(item.resource);
        });
      }
    }
    catch (ex) {
      self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.SelectedProfileRoles.ErrorMessage, FxpConstants.messageType.error);
    }
    return listOfResources;
  }

  /**
   * userd to get user resources list
   * @method Fxp.Services.UserClaimsService.getUserResources
   * @param {string } alias it contains user alias
   * @example <caption> Example to invoke getUserResources</caption>
   * UserClaimsService.getUserResources(alias);
   *
   */
  getUserAdminResources(objectId?: string) {
    let self = this;
    let listOfResources = [];
    objectId = objectId || self.userInfoService.getCurrentUserOID();
    try {
      let result = self.getUserTenatClaimsByObjectId(objectId);
      if (result) {
        _.each(result[self.fxpConfiguration.FxpAppSettings.FxpNpd].claims.resourcePermissions, function (item) {
          listOfResources.push(item.resource);
        });
      }
    }
    catch (ex) {
      self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.SelectedProfileRoles.ErrorMessage, FxpConstants.messageType.error);
    }
    return listOfResources;
  }

  getDefaultAppRole(lstClaims) {
    let appRole;
    if (lstClaims && lstClaims[window["tenantConfiguration"].NPD]) {
      var tenantClaims = lstClaims[window["tenantConfiguration"].NPD];
      var prefix = window["tenantConfiguration"].TenantId;
      _.each(tenantClaims.claims.roles, function (role) {
        if (role.isDefaultRole) {
          appRole = prefix + "." + role.roleName;
        }
      });
    }
    return (appRole) ? appRole : window["tenantConfiguration"].DefaultAppRole;
  }

  private getClaimsFromLocalStorage(userIdentifier) {
    if (!userIdentifier)
      return null;

    let claimsStorageKey = CommonUtils.getSessionStorageUserClaimsKey(userIdentifier);
    if (!claimsStorageKey) 
      return null;
    
      var localStorageProfile = localStorage[claimsStorageKey];
    return localStorageProfile;
  }
}
