export enum FxpBroadcastedEvents {
    OnUserContextChanged = "UserContextChanged",
    OnLeftNavHighlightByStateName = "HighlightLeftNavByStateName",
    OnLeftNavToggleExpandedState = "toggleLeftNavExpandedState",
    OnLeftNavTogglePinState = "toggleLeftNavPinState",
    OnFxpLoadCompleted = "OnFxpLoadedEvent",
    OnAppHeaderChanged = "AppHeaderChanged",
    OnPageTitleChanged = "PageTitleChanged",
    OnFeedbackContextChanged = "FeedbackContext",
    OnFeedbackConfigurationChanged = "FeedbackConfiguration",
    OnNewNotificationsRecieved = "newNotifications",
    OnShowHideInlineLoader = "showHideInlineLoader",
    OnBasicUserProfileRefresh = "GetLatestBasicUserProfile",
    OnLayoutChanged = "layoutChanged",
    OnPageLoadComplete = "OnPageLoadComplete",
    OnUserSessionTimeout = "FxPUserSessionTimeout",
    OnLaunchIrisBot = 'LaunchIrisBot',
	OnHideIrisBot = 'HideIrisBot',
    OnPageTourFlyoutOpened = 'PageTourFlyoutOpened',
    OnPageTourFlyoutClosed = 'PageTourFlyoutClosed',
    LaunchHelpSection = 'LaunchHelpSection',
    HidePageTour = 'HidePageTour',
    ShowPageTour = 'ShowPageTour',
    ActOnBehalfClosed = "ActOnBehalfClosed",
    ShowPopupOnPageDirty = "ShowPopupOnPageDirty"
}