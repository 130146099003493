import { Reducer } from 'redux';
import { IAction } from 'redux-micro-frontend';
import { OBOEntity } from './obo.model';
import { OBOActionTypes } from './obo.action.types';

const defaultOBOEntity: OBOEntity = {} as OBOEntity

export const OBOReducer: Reducer<OBOEntity, IAction<OBOEntity>> = (
    state: OBOEntity = defaultOBOEntity,
    action: IAction<OBOEntity>): OBOEntity => {
        switch (action.type) {
            case OBOActionTypes.ADD_OBO_ENTITY :{
                return  action.payload
                  
            }
            case OBOActionTypes.REMOVE_OBO_ENTITY:{
                return {} as OBOEntity
            }
        }
    return state;
}