import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { UiView } from '@fxp/reactrouter';
import { StateService } from '@uirouter/core';
import { FxpLazyLoader } from "../../../app/fxplazyloader";
import { FxpMessageService } from "../../banner/FxpMessageService";
import { FxpConstants } from "../../../js/common/ApplicationConstants";
import { PageLoaderService } from "../../loader/pageLoaderService";
import { FxpGlobalStoreService } from "../../../js/services/fxp.global.store.service";
import { PartnerStateService } from "../partnerContainer-component/partnerStateService";
import { Component, ViewChild, Inject, forwardRef, CompilerFactory, ElementRef, AfterViewInit, OnInit } from "@angular/core";
import { TelemetryConstants } from '../../../js/telemetry/TelemetryConst';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';
import { ErrorSeverityLevel } from '../../../js/telemetry/ErrorSeverityLevel';
import { ErrorCodes } from '../../../js/constants/errorCodes';

@Component({
    selector: 'fxp-app-partnerreactapp',
    templateUrl: "./reactpartner-component.html"
})
export class ReactPartnerContainerComponent implements AfterViewInit, OnInit {
    @ViewChild('container1', { read: ElementRef, static: false }) entry: ElementRef;
    componentRef: any;
    currentStateName: string;
    sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ReactPartnerContainerComponent`;
    stateChangeUnsubscribeCaller: () => void;

    constructor(
        @Inject(forwardRef(() => PageLoaderService)) private fxpLoaderService: PageLoaderService,
        @Inject(forwardRef(() => FxpLoggerService)) private fxpLoggerService: FxpLoggerService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => FxpGlobalStoreService)) private globalStore: FxpGlobalStoreService,
        @Inject(forwardRef(() => StateService)) private stateService: StateService,
        @Inject(PartnerStateService) private fxpPartnerStateService: PartnerStateService,
        @Inject(CompilerFactory) private compilerFactory: CompilerFactory
    ) {
        FxpLazyLoader.setCompilerFactory(this.compilerFactory);
    }
    ngOnInit(): void {
        window.addEventListener("beforeunload", function (e) {
            if(window["_showPageDirtyPopup"]){
                var confirmationMessage = "\o/";
                e.returnValue = confirmationMessage; 
                return confirmationMessage;
            }
        });
    }
    ngOnDestroy(): void {
        this.fxpPartnerStateService.remove(this.currentStateName);
        if (this.stateChangeUnsubscribeCaller) {
            this.stateChangeUnsubscribeCaller();
        }
        this.unloadComponent(this.entry.nativeElement);
    }

    ngAfterViewInit(): void {
        const self = this;
        this.currentStateName = this.stateService.$current.name;
        this.fxpPartnerStateService.addState(this.currentStateName);
        this.loadComponent(self.entry.nativeElement);
        this.fxpLoaderService.fnHidePageLoader();

        this.stateChangeUnsubscribeCaller =
            this.globalStore.SubscribeToPartnerState("Fxp.ReactPartnerContainerComponent", "PLATFORM_ROUTER", (routeState) => {
                self.loadComponent(self.entry.nativeElement);
            });
    }

    private loadComponent(nativeElement: any) {
        try {
            ReactDOM.render(
                React.createElement(UiView, null, null),
                nativeElement
            );
        } catch (error) {
            this.fxpLoggerService.logException(`${this.sourceForTelemetry}.LoadComponent`, error, null, null, null, ErrorSeverityLevel.Critical, ErrorCodes.ErrorLoadingReactComponent);
            this.fxpMessageService.addMessage("There was an error in loading the React Component", FxpConstants.messageType.error);
        }
    }

    private unloadComponent(nativeElement: any) {
        try {
            ReactDOM.unmountComponentAtNode(nativeElement);
        } catch (error) {
            this.fxpLoggerService.logException(`${this.sourceForTelemetry}.UnLoadComponent`, error, null, null, null, ErrorSeverityLevel.Critical, ErrorCodes.ErrorUnloadingReactComponent);
            this.fxpMessageService.addMessage("There was an error in removing the React Component", FxpConstants.messageType.warning);
        }
    }
}
