import { IAction } from 'redux-micro-frontend';
import { Reducer } from "redux";
import { LoaderActionTypes } from "./loader.actions.types";
import { ILoader } from "./loader.model";

const defaultState: Array<ILoader> = [];

export const LoaderReducer: Reducer<Array<ILoader>, IAction<Object> > = (
    state: Array<ILoader> = defaultState,
    action: IAction<ILoader>  
): Array<ILoader> => {
    switch (action.type) {
        case LoaderActionTypes.SHOW_LOADER : {
            return [...state, action.payload];
        }
        case LoaderActionTypes.HIDE_LOADER : {
            return defaultState;
        }
        case LoaderActionTypes.SHOW_SPECIFIC_LOADER : {
            return [...state, action.payload];
        }
        case LoaderActionTypes.HIDE_SPECIFIC_LOADER : {
            return action.payload.name ? state.filter(loader => loader.name != action.payload.name) : defaultState;
        }
    }

    return state;

};