import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChild, Inject, forwardRef, NgModuleFactory, CompilerFactory } from "@angular/core";
import { StateService } from "@uirouter/core";
import { FxpLazyLoader } from "../../../app/fxplazyloader"
import { PageLoaderService } from "../../loader/pageLoaderService";
import { PartnerStateService } from "./partnerStateService";
import { FxpMessageService } from "../../banner/FxpMessageService";
import { FxpConstants } from "../../../js/common/ApplicationConstants";
import { PartnerAppRegistrationService } from "../../../js/services/PartnerAppRegistrationService";
import { TelemetryConstants } from "../../../js/telemetry/TelemetryConst";
import { ErrorSeverityLevel } from "../../../js/telemetry/ErrorSeverityLevel";
import { ErrorCodes } from "../../../js/constants/errorCodes";
import { FxpLoggerService } from "../../../js/telemetry/fxpLogger";

@Component({
    selector: 'fxp-app-partnerapp',
    templateUrl: "./partnerContainer-component.html"
})
export class PartnerContainerComponent implements OnInit, OnDestroy {

    @ViewChild('container', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
    componentRef: any;

    currentStateName: string;
    sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PartnerContainerComponent`;

    constructor(
        @Inject(forwardRef(() => StateService)) private stateService: StateService,
        @Inject(forwardRef(() => PageLoaderService)) private fxpLoaderService: PageLoaderService,
        @Inject(forwardRef(() => FxpLoggerService)) private fxpLoggerService: FxpLoggerService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(PartnerStateService) private fxpPartnerStateService: PartnerStateService,
        @Inject(CompilerFactory) private compilerFactory: CompilerFactory
    ) {
        FxpLazyLoader.setCompilerFactory(this.compilerFactory);
    }

    ngOnDestroy(): void {
        if (this.componentRef) {
            this.entry.clear();
            this.componentRef = null;
        }
        this.fxpPartnerStateService.remove(this.currentStateName);
    }

    ngOnInit(): void {
        const currentState: any = this.stateService.$current;

        window.addEventListener("beforeunload", function (e) {
            if(window["_showPageDirtyPopup"]){
                var confirmationMessage = "\o/";
                e.returnValue = confirmationMessage; 
                return confirmationMessage;
            }
        });

        if (!currentState.ngModule || !currentState.childComponent){
            currentState.path.forEach(path => {
                if (path.name && path.data && path.data.partnerAppName && (!path.childComponent || !path.ngModule)) {
                    let routeInfo = PartnerAppRegistrationService.getRegisteredAppRoute(path.data.partnerAppName, path.name);
                    if (routeInfo) {
                        path.childComponent = routeInfo.component;
                        path.ngModule = routeInfo.ngModule;
    
                        if (routeInfo.sticky && routeInfo.views) {
                            let name = Object.keys(routeInfo.views)[0]; // sticky:true requires there to be a named view in the `views` attribute
                            path.childComponent = routeInfo.views[name].component;
                        }
                    }
                }
            });
        }
  

        let ngModule = currentState.ngModule;
        let component = currentState.childComponent;
        const self = this;

        if (!ngModule) {
            let message = "Reference to module is misisng in route configuration. Please set ngModule property of route config.";
            this.fxpMessageService.addMessage(message, FxpConstants.messageType.error, true);
            console.error(message);
            return;
        }
        const moduleName = (ngModule.moduleType && ngModule.moduleType.name)? ngModule.moduleType.name : ngModule.name;
        const timerLabel = "PartnerModule " + moduleName + " compilation: ";
        this.fxpLoaderService.fnShowPageLoader("Loading...");
        let currentStateHandle: any;
        currentStateHandle = currentState;

        while (currentStateHandle.parent && currentStateHandle.parent.childComponent && !this.fxpPartnerStateService.contains(currentStateHandle.parent.name)) {
            currentStateHandle = currentStateHandle.parent;
        }

        this.fxpPartnerStateService.addState(currentStateHandle.name);
        this.currentStateName = currentStateHandle.name;
        component = currentStateHandle.childComponent;
        ngModule = currentStateHandle.ngModule;

        setTimeout(function () {
            if (ngModule._ngModuleDefFactory) {
                //Module is AOT compiled
                let ngModuelRef = FxpLazyLoader.instantiateModule(ngModule);
                self.loadComponent(ngModuelRef, component);
                console.time(timerLabel);
                return;
            }

            FxpLazyLoader.compileAndLoadModule(ngModule).then(function (ngModuleRef) {
                console.timeEnd(timerLabel);
                self.loadComponent(ngModuleRef, component);
            }).catch((error) => {
                let message = "An error occured while compiling angular module: " + ngModule.name + ". Please see the browser console for more details. ";
                let properties = self.fxpLoggerService.createPropertyBag();
                properties.addToBag("ngModuleName", ngModule.name);
                self.fxpLoggerService.logException(`${self.sourceForTelemetry}.NgOnInit`, error, properties, null, null, ErrorSeverityLevel.Critical, ErrorCodes.ModuleCompilationError);
                self.fxpMessageService.addMessage(message, FxpConstants.messageType.error, true);
                console.error(message);
                console.error(error);
                self.fxpLoaderService.fnHidePageLoader();
            });
        }, 0);
    }

    loadComponent(moduleRef: any, component: any): void {
        const self = this;
        try {
            const label = "Loading partner component: " + component.name;
            console.time(label);
            let componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(component);
            const currentState: any = this.stateService.$current
            if (currentState.includeRootModule) {
                this.componentRef = this.entry.createComponent(componentFactory, 0, moduleRef.injector);
            }
            else {
                this.componentRef = this.entry.createComponent(componentFactory, undefined, undefined, undefined, moduleRef);
            }

            this.fxpLoaderService.fnHidePageLoader();
            console.timeEnd(label);
        }
        catch (error) {
            let message = "An error occured while instantiating the component: " + component.name + " of Module: " + moduleRef.instance.constructor.name + ". Please see the browser console for more details. ";
            let properties = self.fxpLoggerService.createPropertyBag();
                properties.addToBag("ComponentName", component.name);
            self.fxpLoggerService.logException(`${self.sourceForTelemetry}.LoadComponent`, error, properties, null, null, ErrorSeverityLevel.Critical, ErrorCodes.ErrorInstantiatingComponent);
            self.fxpMessageService.addMessage(message, FxpConstants.messageType.error, true);
            console.error(message);
            console.error(error);
            self.fxpLoaderService.fnHidePageLoader();
        }
    }
}
