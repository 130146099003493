/// <reference path="../../typings/angularjs/angular-resource.d.ts" />
/// <reference path="../../typings/adal-angular/adal.d.ts" />
/// <reference path="../../typings/adal-angular/adal-angular.d.ts" />

import { AuthenticationResult } from "@azure/msal-browser";
import { IFxPService } from "../interfaces/IFxpService";
import { IServiceEndPoints } from "../interfaces/IServiceEndPoints";
import { MsalAuthenticationService } from "./MsalAuthenticationService";

declare global {
	interface Window { _msalEndpoints: any; }
}
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Utils.Services
 */

/**
   * A service keeping the generic methods used in the fxp application and for multiple adal requests  
   * @class Fxp.Services.AdalLoginHelperService
   * @classdesc A service used for generic methods in the fxp application and adal simultaneous cors requests
   * @example <caption> Example to create an instance of AdalLoginHelperService</caption>
   *  //Initializing AdalLoginHelperService
   *  angular.module('FxPApp').controller('AppController', ['AdalLoginHelperService', AppController]);
   *  function AppController(AdalLoginHelperService){ AdalLoginHelperService.getJsonData(path, callbackfunc); }
   */
export class AdalLoginHelperService implements IFxPService {
	private serviceEndpoints: Array<IServiceEndPoints> = [];
	private $q;
	private $resource;

	constructor($q: angular.IQService, $resource: angular.resource.IResourceService, private msalAuthenticationService: MsalAuthenticationService) {
	}

	/**
   * Checks for whether the adal service request is in progress or not and returns boolean 
   * @method Fxp.Utils.Services.AdalLoginHelperService.registerEndPoint
   * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
   * @param {ResourceId} ResourceId is the api endpoint for the resource or the api to which adal authorizes.
   * @example <caption> Example to invoke accessTokenRequestInProgress</caption>
   *  AdalLoginHelperService.registerEndPoint('http://oneprofiledevapi.azurewebsites.net', 'asdasd-asdasd-asdasda-asdasd');
   */
	registerEndPoint(endpoint: string, applicationId: string) {
		this.msalAuthenticationService.registerEndPoint(endpoint, applicationId);
	}

	/**
   * Checks for whether the adal service request is in progress or not and returns boolean 
   * @method Fxp.Utils.Services.AdalLoginHelperService.accessTokenRequestInProgress
   * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
   * @example <caption> Example to invoke accessTokenRequestInProgress</caption>
   *  AdalLoginHelperService.accessTokenRequestInProgress('http://oneprofiledevapi.azurewebsites.net');
   */
	accessTokenRequestInProgress(endpoint): boolean {
		return false;
	}

	/**
   * Returns cached token for a given endpoint 
   * @method Fxp.Utils.Services.AdalLoginHelperService.getCachedToken
   * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
   * @example <caption> Example to invoke getCachedToken</caption>
   *  AdalLoginHelperService.getCachedToken('http://oneprofiledevapi.azurewebsites.net');
   */
	getCachedToken(endpoint) {
		// var resource = this.adal.getResourceForEndpoint(endpoint);
		// return this.adal.getCachedToken(resource);
		return this.msalAuthenticationService.getCachedToken(endpoint);
	}

	/**
  * To kick off token acquisition manually on special cases 
  * @method Fxp.Utils.Services.AdalLoginHelperService.acquireToken
  * @param {EndPoint} endpoint is the api endpoint for the resource or the api to which adal authorizes.
  * @example <caption> Example to invoke acquireToken</caption>
  *  AdalLoginHelperService.acquireToken('http://oneprofiledevapi.azurewebsites.net');
  */
	acquireToken(endpoint, callback) {
		this.msalAuthenticationService.acquireTokenAsPromise(endpoint).then((authResult: AuthenticationResult) => {
			if (authResult && authResult.accessToken) {
				callback(null, authResult.accessToken, null);
			}
			else {
				console.log("Token not acquired");
				callback('User login is required', null, 'login required');
			}
		}).catch((error: any) => callback('User login is required', null, 'login required'));
	}

	acquireTokenAsPromise(endpoint) {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.msalAuthenticationService.acquireTokenAsPromise(endpoint)
				.then((result: AuthenticationResult) => resolve(result.accessToken))
				.catch((error: any) => reject(error))
		});
	}

	/**
   * Checks for whether the token retrieval is started for the endpoint provided  
   * @method Fxp.Utils.Services.AdalLoginHelperService.isTokenRetrievalStarted
   * @param {Resource} resource an endpoint which is passed to check for it in the logs entry.
   * @example <caption> Example to invoke isTokenRetrievalStarted</caption>
   *  AdalLoginHelperService.isTokenRetrievalStarted('https://microsoft.onmicrosoft.com/FXPCouchBaseAPI');
   */
	isTokenRetrievalStarted(resource): boolean {
		var isTokenRetrievalStarted = false;

		// console.log(this.adal.CONSTANTS.LOG_ENTRY + resource);
		// var log = this.adal._getItem(this.adal.CONSTANTS.LOG_ENTRY + resource);
		// if (log) {
		// 	var logEntries = log.split(';');
		// 	var lastEntry = logEntries[logEntries.length - 2];
		// 	var entryTime = new Date(lastEntry.substr(0, lastEntry.indexOf('GMT:') + 3));
		// 	var now = new Date();
		// 	isTokenRetrievalStarted = now.getTime() < entryTime.getTime() + 10000;
		// }

		return isTokenRetrievalStarted;
	}

}

