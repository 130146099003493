import { IFxpHttpInterceptorHook } from './../interfaces/IFxpHttpInterceptorHook'; 
import { IFxPService } from '../interfaces/IFxpService';
export class FxpPartnerHttpInterceptorHooks implements IFxPService {
  
	protected _interceptorHooks : Array<IFxpHttpInterceptorHook> = [];
	
	RegisterInterceptorHook = function(httpInterceptorHookInstance: IFxpHttpInterceptorHook):void {
		if (this._interceptorHooks.indexOf(httpInterceptorHookInstance)>=0){
			return;
		}
		this._interceptorHooks.push(httpInterceptorHookInstance); 
	}
	UnRegisterInterceptorHook =function(httpInterceptorHookInstance: IFxpHttpInterceptorHook):void {
		const index = this._interceptorHooks.indexOf(httpInterceptorHookInstance);
		this._interceptorHooks.splice(index, 1); 
	}

	getRegisteredInterceptorHooks=function(): Array<IFxpHttpInterceptorHook>{
		return this._interceptorHooks;
	}
}

