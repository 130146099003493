import { IAction } from 'redux-micro-frontend';
import { Reducer } from "redux";
import { FlightingActions } from "./featureFlags.action.types";

const defaultState: Record<string, boolean> = {};

export const FeatureFlagReducer: Reducer<Record<string, boolean>, IAction<Record<string, boolean>>> = (
    state: Record<string, boolean> = defaultState,
    action: IAction<Record<string, boolean>>
): Record<string, boolean> => {

    switch(action.type) {
        case FlightingActions.UPSERT_FEATURE_FLAG : {       
            return {...state, ...action.payload};
        }
    }

    return state;
}