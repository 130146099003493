import { $injector } from "@uirouter/core";
import { IFxPService } from "../interfaces/IFxpService";

export class LazyLoaderService implements IFxPService{

    constructor(private $ocLazyLoad){
    }
    
    public getInstance(): ILazyLoaderService{
        return this.$ocLazyLoad; 
    }
}

export interface ILazyLoaderService{
     load(bundles:Array<string>, options: Object); 
}
