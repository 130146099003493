import { Reducer } from 'redux'
import { IAction } from 'redux-micro-frontend';
import { Banner } from "./banner.model"
import { BannerActionTypes } from './banner.action.types';
const defaultState: Array<Banner> = [];


export const BannerReducer: Reducer<Array<Banner>, IAction<any>> = (
    state: Array<Banner> = defaultState,
    action: IAction<any>
): Array<Banner> => {
    switch (action.type) {
        case BannerActionTypes.ADD_BANNER_MESSAGE: {
            return [...state, action.payload];
        }
        case BannerActionTypes.CLOSE_BANNER_MESSAGE: {
            var index = state.indexOf(action.payload);
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }
    }

    return state;
}