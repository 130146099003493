<div class="help-wrap">
    <button
        type="button"
        class="icon-container withBorder icon-bot"
        title="Iris Copilot"
        aria-label="Iris Copilot"
        (click)="showVirtualAssistant();">
        <i class="icon-irisbot"></i>
    </button>
</div>
<template #container >
</template>