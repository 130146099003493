import { ILogger } from "../interfaces/ILogger";
import { UserInfoService } from "./UserInfoService";
import { IOCVSource } from "../common/enum/Feedback.enum";
import { IOCVFeedbackPayload } from "../interfaces/IOCVFeedbackPayload";
import { CommonUtils } from "../utils/CommonUtils";
import { FxpHttpService } from "./FxpHttpService";
import { IFxpFeedbackMessage } from "../interfaces/IFxpFeedbackMessage";
import { FxpConfigurationService } from "./FxpConfiguration";
import { FxpGlobalStoreService } from "./fxp.global.store.service";

export class FxpOCVFeedbackService {

    private fxplogger: ILogger;
    private fxpHttpService: FxpHttpService;
    private fxpConfigService: FxpConfigurationService;

    constructor(loggerService: ILogger, private globalStore: FxpGlobalStoreService, fxpHttpService: FxpHttpService, fxpConfigurationService: FxpConfigurationService) {
		this.fxplogger = loggerService;
        this.fxpHttpService = fxpHttpService;
        this.fxpConfigService = fxpConfigurationService;
    }
    
    public SubmitFeedback(feedbackMessage: IFxpFeedbackMessage) : Promise<any> {
        let feedbackPayload: IOCVFeedbackPayload = {
            manifestType: feedbackMessage.feedbackManifestType,
            appId: this.fxpConfigService.FxpAppSettings.OCVAppId,
            submitTime: new Date().toISOString(),
            source: IOCVSource.Client,
            type: feedbackMessage.feedbackType,
            rating: feedbackMessage.rating,
            clientFeedbackId: CommonUtils.getNewGuId(),
            comment: feedbackMessage.feedbackMessage,
            email: feedbackMessage.email,
            application: {
                appData: feedbackMessage.appData
            },
            telemetry: {
                loggableUserId: this.globalStore.GetPlatformState().CurrentUser.Claims.aadObjectId,
                featureArea: feedbackMessage.partnerName,
                officeBuild: this.fxpConfigService.FxpAppSettings.BuildNumber,
                tenantId: this.fxpConfigService.FxpAppSettings.TenantId
            },
            survey: {
                surveyId: feedbackMessage.surveyId
            },
            web: {
                browser: navigator["browserDetails"] ? navigator["browserDetails"].name : "",
                browserVersion: navigator["browserDetails"] ? navigator["browserDetails"].version : "",
                sourcePageName: document.getElementsByTagName("title")[0].innerHTML,
                sourcePageURI: window.location.href
            }
        };
        return this.sendFeedback(feedbackPayload);
    }

    private sendFeedback(feedbackPayload: IOCVFeedbackPayload) : Promise<any>
    {   
        let url = this.fxpConfigService.FxpAppSettings.FeedbackOCVEndpoint;
        let requestHeaders = {};
        requestHeaders["Content-Type"] = "application/json";
        return this.fxpHttpService.post(url, feedbackPayload, requestHeaders);
    }
}