import { IAdminTileGroup } from './adminTiles.model';
import { IAction } from 'redux-micro-frontend';
import { Actions } from './adminTile.action.types';

export const setAdminTiles = (payload: Array<IAdminTileGroup>): IAction<Array<IAdminTileGroup>> => {
    return {
        type: Actions.SET_ADMIN_TILES,
        payload: payload
    }
}

export const removeOboContext = (): IAction<Array<IAdminTileGroup>> => {
    return {
        type: Actions.REMOVE_OBO_CONTEXT,
        payload: []
    }
}