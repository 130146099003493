import {
  Component,
  ElementRef,
  forwardRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as pbi from "powerbi-client";

import { StateService } from "@uirouter/angular";
import { CommonUtils } from "../../../js/utils/CommonUtils";
import { FxpMessageService } from "../../banner/FxpMessageService";
import { FxpConstants } from "../../../js/common/ApplicationConstants";
import { PageLoaderService } from "../../loader/pageLoaderService";
import { ErrorCodes } from "../../../js/constants/errorCodes";
import { ErrorSeverityLevel } from "../../../js/telemetry/ErrorSeverityLevel";
import { FxpLoggerService } from "../../../js/telemetry/fxpLogger";
import { TelemetryConstants } from "../../../js/telemetry/TelemetryConst";
import { FeatureUsageEvent } from "../../../js/telemetry/FeatureUsageEvent";
import {
  ActionStatus,
  ActionType,
  ComponentType,
  EventName,
} from "@microsoftit/telemetry-extensions-npm";
import { FxpConfigurationService } from "../../../js/services/FxpConfiguration";
import { UserInfoService } from "../../../js/services/UserInfoService";
import { LogPropertyBag } from "../../../js/telemetry/LogPropertyBag";
import { MsalAuthenticationService } from "../../../js/services/MsalAuthenticationService";

const PowerBiFeatureName = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxPPowerBiIntegration`;
@Component({
  selector: "fxp-app-powerbi-container",
  templateUrl: "./powerbi-container-component.html",
})
export class PowerBIReportContainerComponent implements OnInit, OnDestroy {
  private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PowerBIReportContainerComponent`;

  @ViewChild("reportContainer", {
    static: false,
  })
  reportContainer: ElementRef;

  constructor(
    @Inject(forwardRef(() => MsalAuthenticationService))
    private msalAuthenticationService: MsalAuthenticationService,
    @Inject(forwardRef(() => FxpLoggerService))
    private fxpLoggerService: FxpLoggerService,
    @Inject(forwardRef(() => PageLoaderService))
    private pageLoaderService: PageLoaderService,
    @Inject(forwardRef(() => FxpMessageService))
    private fxpMessageService: FxpMessageService,
    @Inject(forwardRef(() => FxpConfigurationService))
    private fxpConfigurationService: FxpConfigurationService,
    @Inject(StateService) public stateService: StateService,
    @Inject(forwardRef(() => UserInfoService))
    private userInfoService: UserInfoService
  ) {}

  ngOnInit(): void {
    let self = this;
    self.preReportRender();

    const embededReportId = self.stateService.params.reportId
      ? self.stateService.params.reportId
      : "";
    const embededDashboardId = self.stateService.params.dashboardId
      ? self.stateService.params.dashboardId
      : "";
    let powerBiBaseUrl =
      self.fxpConfigurationService.FxpAppSettings.PowerBiReportBaseUrl;

    const powerBiService = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    const properties = self.fxpLoggerService.createPropertyBag();

    if (CommonUtils.isNullOrEmpty(powerBiBaseUrl)) {
      powerBiBaseUrl = "https://app.powerbi.com";
    }

    if (!CommonUtils.isNullOrEmpty(embededReportId)) {
      self.showReport(
        embededReportId,
        powerBiBaseUrl,
        powerBiService,
        properties
      );
    } else if (!CommonUtils.isNullOrEmpty(embededDashboardId)) {
      self.showDashBoard(
        embededReportId,
        powerBiBaseUrl,
        powerBiService,
        properties
      );
    } else {
      self.showError(properties);
    }
  }

  preReportRender() {
    let self = this;
    self.pageLoaderService.fnShowPageLoader("Loading...");
    self.fxpLoggerService.renewCorrelationId();
  }

  showReport(
    embededReportId: string,
    powerBiBaseURL: string,
    powerBiService: pbi.service.Service,
    properties: LogPropertyBag
  ) {
    let self = this;
    const powerBiFeatureUsageEvent = new FeatureUsageEvent(
      `${PowerBiFeatureName}`,
      ActionType.User,
      "PowerBIReportLoad",
      EventName.PageLoad,
      ComponentType.Web
    );
    self.fxpLoggerService.startFeatureUsageEvent(powerBiFeatureUsageEvent);

    const source = `${self.sourceForTelemetry}.ShowReport`;
    const embededUrl = `${powerBiBaseURL}/reportEmbed?reportId=${embededReportId}`;
    const settings = self.getReportSettings();

    const config: pbi.IEmbedConfiguration = {
      type: "report",
      id: embededReportId,
      embedUrl: embededUrl,
      filters: [],
      settings: settings,
    };

    properties.addToBag("embededReportId", embededReportId);

    self
      .getAccessToken()
      .then((token) => {
        config.accessToken = token;
        const reportContainer = this.reportContainer.nativeElement;
        powerBiService.reset(reportContainer);
        const powerBiReport = <pbi.Report>(
          powerBiService.embed(reportContainer, config)
        );
        self.attachEventHandlers(
          powerBiReport,
          source,
          powerBiFeatureUsageEvent,
          properties
        );
      })
      .finally(() => {
        setTimeout(function () {
          self.pageLoaderService.fnHidePageLoader();
        }, 0);
      });
  }

  showDashBoard(
    embededDashBoardId: string,
    powerBiBaseURL: string,
    powerBiService: pbi.service.Service,
    properties: LogPropertyBag
  ) {
    let self = this;
    const powerBiFeatureUsageEvent = new FeatureUsageEvent(
      `${PowerBiFeatureName}`,
      ActionType.User,
      "PowerBIDashBoardLoad",
      EventName.PageLoad,
      ComponentType.Web
    );
    self.fxpLoggerService.startFeatureUsageEvent(powerBiFeatureUsageEvent);

    const source = `${self.sourceForTelemetry}.ShowDashBoard`;
    const embededUrl = `${powerBiBaseURL}/dashboardEmbed?${embededDashBoardId}`;

    let config: pbi.IEmbedConfiguration = {
      type: "dashboard",
      id: embededDashBoardId,
      embedUrl: embededUrl,
      filters: [],
    };
    properties.addToBag("embededDashBoardId", embededDashBoardId);
    self
      .getAccessToken()
      .then((token) => {
        config.accessToken = token;
        const dashBoardContainer = this.reportContainer.nativeElement;
        powerBiService.reset(dashBoardContainer);
        const powerBiDashBoard = <pbi.Dashboard>(
          powerBiService.embed(dashBoardContainer, config)
        );
        self.attachEventHandlers(
          powerBiDashBoard,
          source,
          powerBiFeatureUsageEvent,
          properties
        );
      })
      .finally(() => {
        setTimeout(function () {
          self.pageLoaderService.fnHidePageLoader();
        }, 0);
      });
  }

  showError(properties: LogPropertyBag) {
    let self = this;
    const source = `${self.sourceForTelemetry}.ShowReport`;
    self.fxpLoggerService.logError(
      source,
      "Invalid configuration for the report. Missing DashboardId and ReportId",
      ErrorCodes.PowerBI_Invalid_Configuration,
      null,
      properties,
      null,
      null,
      ErrorSeverityLevel.High
    );
    self.fxpMessageService.addMessage(
      "System Error has occurred. If the problem persists, please contact IT support.",
      FxpConstants.messageType.error
    );
    return;
  }

  attachEventHandlers(
    powerBiComponent: pbi.Report | pbi.Dashboard,
    source: string,
    featureUsageEvent: FeatureUsageEvent,
    properties: LogPropertyBag
  ) {
    if (powerBiComponent instanceof pbi.Report) {
      this.handlePowerBiLoaded(powerBiComponent, source, properties);
    }
    this.handlePowerBiRendered(
      powerBiComponent,
      source,
      featureUsageEvent,
      properties
    );
    this.handlePowerBiError(
      powerBiComponent,
      source,
      featureUsageEvent,
      properties
    );
  }

  handlePowerBiLoaded(
    powerBiReport: pbi.Report,
    source: string,
    properties: LogPropertyBag
  ) {
    let self = this;
    powerBiReport.off("loaded");
    powerBiReport.on("loaded", (event) => {
      self.fxpLoggerService.logEvent(
        source,
        "FxpPlatform.PowerBIModule.ReportLoaded",
        properties
      );
      powerBiReport.getFilters().then((filters) => {
        const updatedFilters = self.getUpdatedFilters(filters);
        powerBiReport.setFilters(updatedFilters).then(() => {
          self.fxpLoggerService.logEvent(
            source,
            "FxpPlatform.PowerBIModule.ReportFiltersUpdated",
            properties
          );
        });
      });
    });
  }

  handlePowerBiRendered(
    powerBiComponent: pbi.Report | pbi.Dashboard,
    source: string,
    featureUsageEvent: FeatureUsageEvent,
    properties: LogPropertyBag
  ) {
    let self = this;
    powerBiComponent.off("rendered");
    powerBiComponent.on("rendered", (event) => {
      let propForEvents = CommonUtils.clone(properties);
      let propForFeature = CommonUtils.clone(properties);
      self.fxpLoggerService.logEvent(
        source,
        "FxpPlatform.PowerBIModule.PowerBiRendered",
        propForEvents
      );
      featureUsageEvent.ActionStatus = ActionStatus.Succeeded;
      self.fxpLoggerService.endFeatureUsageEvent(
        source,
        featureUsageEvent,
        propForFeature
      );
    });
  }

  handlePowerBiError(
    powerBiComponent: pbi.Report | pbi.Dashboard,
    source: string,
    featureUsageEvent: FeatureUsageEvent,
    properties: LogPropertyBag
  ) {
    let self = this;
    const accessRequestUrl = this.stateService.params.accessRequestUrl
      ? this.stateService.params.accessRequestUrl
      : "";

    powerBiComponent.off("error");
    powerBiComponent.on("error", (event) => {
      properties.addToBag("errorDetails", JSON.stringify(event.detail));
      let errorMessage = "";
      if (event.detail && event.detail["message"])
        errorMessage = event.detail["message"];
      self.fxpLoggerService.logError(
        source,
        "Error rendering powerbi component: " + errorMessage,
        ErrorCodes.PowerBI_OnError,
        null,
        properties,
        null,
        null,
        ErrorSeverityLevel.Medium
      );
      featureUsageEvent.ActionStatus = ActionStatus.Failed;
      self.fxpLoggerService.endFeatureUsageEvent(
        source,
        featureUsageEvent,
        properties
      );
      if (accessRequestUrl && accessRequestUrl !== "")
        self.fxpMessageService.addMessage(
          `Seems you have insufficient access. Please visit [PSDL Portal](${accessRequestUrl}) to raise access`,
          FxpConstants.messageType.error
        );
      else
        self.fxpMessageService.addMessage(
          "Error rendering powerbi component",
          FxpConstants.messageType.error
        );
      this.pageLoaderService.fnHidePageLoader();
    });
  }

  getReportSettings(): pbi.IEmbedSettings {
    const settings: pbi.IEmbedSettings = {
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
    };
    return settings;
  }

  getUpdatedFilters(
    existingFilters: pbi.models.IFilter[]
  ): pbi.models.IFilter[] {
    const newFilters = this.getNewFilters();

    for (const newFilter of newFilters) {
      let existingFilterIndex = existingFilters.findIndex((existingFilter) => {
        const target: any = existingFilter.target;
        return (
          newFilter.target.table == target.table &&
          newFilter.target.column == target.column
        );
      });

      if (existingFilterIndex == -1) existingFilters.push(newFilter);
      else existingFilters[existingFilterIndex] = newFilter;
    }
    return existingFilters;
  }

  getNewFilters(): any {
    const profile = this.userInfoService.getCurrentUserProfile();
    let filters = this.stateService.params.filters;
    let newFilters = [];

    for (const filter of filters) {
      if (
        filter.operator &&
        filter.values &&
        filter.target &&
        filter.displaySettings
      ) {
        let values = [];

        for (const value of filter.values) {
          values.push(this.getValueForFilter(value, profile));
        }

        newFilters.push({
          $schema:
            this.fxpConfigurationService.FxpAppSettings.PowerBiFilterSchemaUrl,
          displaySettings: {
            displayName: filter.displaySettings.displayName,
          },
          target: {
            table: filter.target.table,
            column: filter.target.column,
          },
          operator: filter.operator,
          values: values,
          filterType: pbi.models.FilterType.Basic,
        });
      }
    }
    return newFilters;
  }

  getValueForFilter(value: string, profile): string {
    const filterValueRegExp = new RegExp(
      this.fxpConfigurationService.FxpAppSettings.PowerBiFilterValueRegularExpression
    );
    if (filterValueRegExp.test(value)) {
      let result = filterValueRegExp.exec(value);
      return profile[result[1]];
    }
    return value;
  }

  getAccessToken(): Promise<string> {
    let powerBITokenURL =
      this.fxpConfigurationService.FxpAppSettings.PowerBiEndpoint;
    if (CommonUtils.isNullOrEmpty(powerBITokenURL)) {
      powerBITokenURL = "https://api.powerbi.com";
    }

    const source = `${this.sourceForTelemetry}.GetAccessToken`;
    const self = this;
    return new Promise((resolve, reject) => {
      self.msalAuthenticationService
        .acquireTokenAsPromise(powerBITokenURL)
        .then((token) => {
          resolve(token.accessToken);
        })
        .catch((error) => {
          reject(error);
          const properties = self.fxpLoggerService.createPropertyBag();
          properties.addToBag("API", powerBITokenURL);
          self.fxpLoggerService.logError(
            source,
            `Failed to acquire access token for ${powerBITokenURL}`,
            ErrorCodes.PowerBI_Token_Failure,
            null,
            properties,
            null,
            null,
            ErrorSeverityLevel.High
          );
          self.fxpMessageService.addMessage(
            "System Error has occurred. Please try again. If the problem persists, please contact IT support.",
            FxpConstants.messageType.error
          );
        });
    });
  }

  ngOnDestroy(): void {}
}
