import { Reducer } from 'redux'
import { IAction } from 'redux-micro-frontend';
import { Notification, NotificationDTO } from "./notifications.model"
import { NotificationActionTypes } from './notifications.action.type';
const defaultState: NotificationDTO = {UserNotifications:[],UnreadNotificationCount:0};


export const NotificationReducer: Reducer<NotificationDTO, IAction<any>> = (
    state: NotificationDTO = defaultState,
    action: IAction<any>
): NotificationDTO => {
    switch (action.type) {
        case NotificationActionTypes.Push_Notification: {
            return {UserNotifications:[action.payload,...state.UserNotifications],UnreadNotificationCount:state.UnreadNotificationCount+1};
        }
        case NotificationActionTypes.Dismiss_Notification: {
            var index = state.UserNotifications.indexOf(action.payload);
            var UserNotifications=[...state.UserNotifications.slice(0, index), ...state.UserNotifications.slice(index + 1)];
            if(action.payload.status.name=="Read")
               return {UserNotifications:UserNotifications,UnreadNotificationCount:state.UnreadNotificationCount}
            return {UserNotifications:UserNotifications,UnreadNotificationCount:state.UnreadNotificationCount-1}
                  
        }
        case NotificationActionTypes.Mark_Notification: {
            var index = state.UserNotifications.indexOf(action.payload);
            var notifications= [...state.UserNotifications.slice(0, index),action.payload, ...state.UserNotifications.slice(index + 1)];
            return {UserNotifications:notifications,UnreadNotificationCount:state.UnreadNotificationCount-1};
        }
        case NotificationActionTypes.Reset_Notification_Collection: {
            return action.payload;
        }
    }

    return state;
}